import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import FoundersForm from './FoundersForm'

export default function TeamStructureForm ({ data, onInputChange, allowEdit, history, collectionToUse }) {
  if (!allowEdit) {
    return <ReadOnlyFrom data={ data } />
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        <TextField
          label='Number of Founders'
          fullWidth
          margin='dense'
          type='number'
          name='number_of_founders'
          defaultValue={ data.number_of_founders }
          onChange={ onInputChange }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <FoundersForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ data } history={ history } collectionToUse={ collectionToUse } />
      </Grid>
    </Grid>
  )
}

function ReadOnlyFrom ({ data }) {
  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Number of Founders: </b> { data.number_of_founders }
        </Typography>
      </Grid>
    </Grid>
  )
}
