import React from 'react'
import { TableCell, TableRow, IconButton, Checkbox, Chip } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import HoverPopover from '../../../UI/HoverPopover'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import * as companyListActions from '../../../../store/actions/companyList'
import * as companyActions from '../../../../store/actions/company'

const useStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: '#5c6bc0',
    color: 'white',
    margin: theme.spacing(0.3),
  },
}))

const TableData = ({ importTable, tableData, setOpenModal, selectCompany, fetchCompany, collectionToUse }) => {
  const classes = useStyles()

  const handleOpenModal = id => {
    fetchCompany(id, importTable, collectionToUse)
    setOpenModal(true)
  }

  return tableData.map(startup => (
    <TableRow key={ startup._id }>
      { importTable && (
        <TableCell align='center'>
          <Checkbox
            name='id'
            checked={ startup.checked ? startup.checked : false }
            value={ startup._id }
            color='primary'
            onChange={ e => selectCompany(e.target.value, e.target.checked) }
          />
        </TableCell>
      ) }

      <TableCell align='center' component='th' scope='object'>
        <HoverPopover label={ startup.sectors } title='Description' content={ startup.description } name={ startup.name } website={ startup.website } />
      </TableCell>

      <TableCell align='center'>
        { startup.sectors && startup.sectors.length
          ? startup.sectors.map((el, key) => <Chip size='small' className={ classes.chip } label={ el } key={ key } />) : 'None' }
      </TableCell>

      <TableCell align='center'>{ startup.hq_country ? startup.hq_country : 'None' }</TableCell>
      <TableCell align='center'>{ startup.updatedAt ? dayjs(startup.updatedAt).format('DD-MM-YYYY HH:mm:ss') : 'None' }</TableCell>
      <TableCell align='center'>{ startup.priority || startup.priority === 0 ? startup.priority : 'None' }</TableCell>
      <TableCell align='center'>{ startup.founded_date ? startup.founded_date : 'None' }</TableCell>
      { importTable && (<TableCell align='center'>{ startup.updated_by }</TableCell>) }

      <TableCell align='center'>
        <IconButton aria-label='edit' onClick={ () => handleOpenModal(startup._id) }>
          <MoreVertIcon color='primary' />
        </IconButton>
      </TableCell>
    </TableRow>
  ))
}

const mapStateToProps = state => ({
  tableData: state.companyList.data,
  collectionToUse: state.collectionToUse.collectionToUse,
})

const mapDispatchToProps = {
  selectCompany: companyListActions.selectCompany,
  fetchCompany: companyActions.fetchCompany,
  setOpenModal: companyActions.setOpenCompanyModal,
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TableData))
