import {
  FETCH_COMPANIES_CREATED_UPDATED_START,
  FETCH_COMPANIES_CREATED_UPDATED_SUCCESS,
  FETCH_COMPANIES_CREATED_UPDATED_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  companiesCreatedAndUpdated: null,
  isFetching: true,
  errorMessage: '',
}

const companiesCreatedUpdatedReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_CREATED_UPDATED_START:
      return {
        ...state,
        isFetching: true,
        companiesCreatedAndUpdated: null,
        errorMessage: '',
      }
    case FETCH_COMPANIES_CREATED_UPDATED_SUCCESS:
      return {
        ...state,
        companiesCreatedAndUpdated: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_COMPANIES_CREATED_UPDATED_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default companiesCreatedUpdatedReducer
