import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Container,
  Collapse,
  Paper,
  Button,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core/'
import CircularSpinner from '../../components/UI/Progress/CircularSpinner'
import CenterBox from '../../components/UI/CenterBox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import BreadCrumb from '../../components/Navigation/BreadCrumbs/BreadCrumbs'
import { fetchLogs } from '../../store/actions/logActions'
import dayjs from 'dayjs'
import PaginationWidget from '../../components/Widgets/Pagination/PaginationWidget'
import * as paginationActions from '../../store/actions/pagination'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { DateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Anchorme } from 'react-anchorme'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    paddingBottom: theme.spacing(4),
  },
  card: {
    marginBottom: 20,
  },
  paper: {
    margin: theme.spacing(2, 0, 2, 0),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper2: {
    height: 600,
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    marginLeft: 50,
    minWidth: 150,
  },
  form: {
    display: 'inline',
    width: 150,
  },
  searchTextField: {
    marginRight: 50,
    marginTop: 20,
    marginBottom: 20,
  },
  tooltip: {
    fontSize: '1.1em',
    maxWidth: 600,
  },
}))

const LogsPage = ({ resetPagination, currentPage, pageSize }) => {
  const [open, setOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [eventMessage, setEventMessage] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedStatusCode, setSelectedStatusCode] = useState('')
  const [selectedStack, setSelectedStack] = useState('')
  const [endpoint, setEndpoint] = useState('')
  const [searchedEndpoint, setSearchedEndpoint] = useState('')
  const [logMessage, setLogMessage] = useState('')
  const [searchedLogMessage, setSearchedLogMessage] = useState('')
  const [stackTrace, setStackTrace] = useState('')
  const [searchedStackTrace, setSearchedStackTrace] = useState('')
  const [selectedDate, handleDateChange] = useState('')
  const [createdFrom, setCreatedFrom] = useState('')
  const [createdTo, setCreatedTo] = useState('')

  const classes = useStyles()
  const dispatch = useDispatch()

  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const allLogs = useSelector(state => state.logData)
  const { logData, isFetching, errorMessage } = allLogs
  const { logs, statusCodes, statuses, stacks, uniqueEndpoints, logCreationDates } = logData

  useEffect(() => {
    return () => {
      resetPagination()
    }
  }, [resetPagination])

  useEffect(() => {
    dispatch(fetchLogs(searchedEndpoint, selectedStatus, selectedStatusCode, selectedStack, searchedLogMessage, searchedStackTrace, createdFrom, createdTo))
  }, [dispatch, currentPage, pageSize, searchedEndpoint, selectedStatus, selectedStatusCode, selectedStack, searchedLogMessage, searchedStackTrace, createdFrom, createdTo])

  const handleOpen = logMessage => {
    setEventMessage(logMessage)
    setOpen(true)
  }

  const handleClose = () => setOpen(false)
  const handleCloseModal = () => setOpen(false)

  const handleSearchEndpoint = (event, newValue) => {
    setEndpoint(newValue)
    setSearchedEndpoint(newValue)
    resetPagination()
  }

  const handleSelectStatus = value => {
    resetPagination()
    setSelectedStatus(value)
  }
  const handleSelectStatusCode = value => {
    resetPagination()
    setSelectedStatusCode(value)
  }

  const handleSelectStack = value => {
    setSelectedStack(value)
  }

  const handleCreatedFromChange = selectedDate => {
    resetPagination()
    setCreatedFrom(selectedDate)
  }

  const handleCreatedToChange = selectedDate => {
    resetPagination()
    setCreatedTo(selectedDate)
  }

  const handleLogMessageChange = value => setLogMessage(value)
  const handleLogMessageSubmit = e => {
    e.preventDefault()
    setSearchedLogMessage(logMessage)
  }

  const handleStackTraceChange = value => setStackTrace(value)
  const handleStackTraceSubmit = e => {
    e.preventDefault()
    setSearchedStackTrace(stackTrace)
  }

  const handleRefreshClick = () => {
    resetPagination()
    dispatch(fetchLogs(searchedEndpoint, selectedStatus, selectedStatusCode, selectedStack, searchedLogMessage, searchedStackTrace, createdFrom, createdTo))
  }

  const handleResetClick = () => {
    resetPagination()
    setEndpoint('')
    setSearchedEndpoint('')
    setSelectedStatus('')
    setSelectedStatusCode('')
    setSelectedStack('')
    setSearchedLogMessage('')
    setSearchedStackTrace('')
  }

  return (
    <Container className={ classes.root }>
      <BreadCrumb />

      {
        isFetching ?
          <Container fixed>
            <CenterBox height='40vh'>
              <CircularSpinner size={ 150 } />
            </CenterBox>
          </Container>
          :
          errorMessage ?
            <>
              <h2>{ errorMessage }</h2>
              <Button variant='contained' color='secondary' onClick={ handleResetClick }>Reset</Button>
            </>
            :
            <>
              <div style={ { textAlign: 'right' } }>
                <Button variant='contained' color='secondary' onClick={ handleRefreshClick }>Refresh logs</Button>

                <IconButton onClick={ () => setCollapseOpen(!collapseOpen) }>
                  <Tooltip classes={ { tooltip: classes.tooltip } } title='Toggle search bar' placement='top'>
                    <SearchIcon fontSize='large' />
                  </Tooltip>
                </IconButton>
              </div>

              <Collapse in={ collapseOpen }>
                <Autocomplete
                  id='combo-box-demo'
                  options={ uniqueEndpoints }
                  // getOptionLabel={ option => option }
                  style={ { display: 'inline-block', width: 300 } }
                  renderInput={ params => <TextField { ...params } label='Search endpoints' variant='standard' /> }
                  value={ endpoint }
                  onChange={ (event, newValue) => handleSearchEndpoint(endpoint, newValue) }
                />

                <FormControl className={ classes.formControl }>
                  <InputLabel id='select-helper-label'>Status</InputLabel>
                  <Select
                    labelId='select-helper-label'
                    id='select-helper'
                    value={ selectedStatus }
                    onChange={ e => handleSelectStatus(e.target.value) }
                  >
                    <MenuItem value=''><em>reset</em></MenuItem>
                    { statuses.map((status, index) => <MenuItem key={ index } value={ status }>{ status }</MenuItem>) }
                  </Select>
                </FormControl>

                <FormControl className={ classes.formControl }>
                  <InputLabel id='select-helper-label'>Status Code</InputLabel>
                  <Select
                    labelId='select-helper-label'
                    id='select-helper'
                    value={ selectedStatusCode }
                    onChange={ e => handleSelectStatusCode(e.target.value) }
                  >
                    <MenuItem value=''><em>reset</em></MenuItem>
                    { statusCodes.map((statusCode, index) => <MenuItem key={ index } value={ statusCode }>{ statusCode }</MenuItem>) }
                  </Select>
                </FormControl>

                <FormControl className={ classes.formControl }>
                  <InputLabel id='select-helper-label'>Stack</InputLabel>
                  <Select
                    labelId='select-helper-label'
                    id='select-helper'
                    value={ selectedStack }
                    onChange={ e => handleSelectStack(e.target.value) }
                  >
                    <MenuItem value=''><em>reset</em></MenuItem>
                    { stacks.map((stack, index) => <MenuItem key={ index } value={ stack }>{ stack }</MenuItem>) }
                  </Select>
                </FormControl>

                <br />

                <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                  <DateTimePicker
                    variant='inline'
                    label={ 'Created From' }
                    value={ createdFrom ? createdFrom : logCreationDates[0] }
                    onChange={ handleDateChange }
                    onClose={ () => handleCreatedFromChange(selectedDate) }
                    style={ { marginTop: 20 } }
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                  <DateTimePicker
                    variant='inline'
                    label={ 'Created To' }
                    value={ createdTo ? createdTo : logCreationDates[logCreationDates.length - 1] }
                    onChange={ handleDateChange }
                    onClose={ () => handleCreatedToChange(selectedDate) }
                    style={ { marginRight: 50, marginLeft: 50, marginTop: 20 } }
                  />
                </MuiPickersUtilsProvider>

                <form className={ classes.form } onSubmit={ e => handleLogMessageSubmit(e) } >
                  <TextField
                    id='outlined-search'
                    className={ classes.searchTextField }
                    label='Search log messages'
                    type='search'
                    variant='standard'
                    autoComplete='off'
                    value={ logMessage }
                    onChange={ e => handleLogMessageChange(e.target.value) }
                  />
                </form>

                <form className={ classes.form } onSubmit={ e => handleStackTraceSubmit(e) } >
                  <TextField
                    id='outlined-search-stack-traces'
                    className={ classes.searchTextField }
                    label='Search stack traces'
                    type='search'
                    variant='standard'
                    autoComplete='off'
                    value={ stackTrace }
                    onChange={ e => handleStackTraceChange(e.target.value) }
                  />
                </form>
              </Collapse>

              <Paper className={ classes.paper } elevation={ 1 }>
                <Table size='small' aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Endpoint</TableCell>
                      <TableCell align='center'>Status</TableCell>
                      <TableCell align='center'>Status Code</TableCell>
                      <TableCell align='center'>Stack</TableCell>
                      <TableCell align='center'>Created</TableCell>
                      <TableCell align='center'>Log</TableCell>
                      <TableCell align='center'>Stack Trace</TableCell>
                      <TableCell align='center'>Query</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    { logs.map((log, index) => (
                      <TableRow key={ index }>
                        <TableCell>
                          <Tooltip classes={ { tooltip: classes.tooltip } } title={ log.endpoint } placement='right'>
                            <Typography display='inline' color='secondary'>{ log.endpoint.length > 25 ? `${ log.endpoint.substr(0, 25) }...` : log.endpoint }</Typography>
                          </Tooltip>
                        </TableCell>

                        <TableCell align='center'>
                          <Typography
                            style={ {
                              color: log.event_status === 'success' || log.event_status === 'processing' || log.event_status === 'started'
                                ?
                                'green'
                                :
                                log.event_status === 'idle' || log.event_status === 'ongoing' || log.event_status === 'conflict'
                                  ?
                                  '#FAA61A' : 'red',
                            } }
                          >
                            { log.event_status }
                          </Typography>
                        </TableCell>

                        <TableCell align='center'>
                          <Typography
                            style={ { color: log.status_code >= 100 && log.status_code < 300 ? 'green' : 'red' } }
                          >
                            { log.status_code }
                          </Typography>
                        </TableCell>

                        <TableCell align='center'>{ log.stack }</TableCell>
                        <TableCell align='center'>{ dayjs(log.createdAt).format('DD/MM/YYYY HH:mm:ss') }</TableCell>

                        <Tooltip
                          title={ <span style={ { whiteSpace: 'pre-line' } }>{ log.event_log && log.event_log }</span> }
                          placement='left'
                          classes={ { tooltip: classes.tooltip } }
                        >
                          <TableCell align='center'>{ log.event_log ?
                            <Button color='secondary' onClick={ () => handleOpen(log.event_log) }>Open</Button> : 'None' }
                          </TableCell>
                        </Tooltip>

                        <Tooltip
                          title={ <span style={ { whiteSpace: 'pre-line' } }>{ log.stack_trace && log.stack_trace }</span> }
                          placement='left'
                          classes={ { tooltip: classes.tooltip } }
                        >
                          <TableCell align='center'>{ log.stack_trace ? <Button color='secondary' onClick={ () => handleOpen(log.stack_trace) }>Open</Button> : 'None' }</TableCell>
                        </Tooltip>

                        <Tooltip
                          title={ <span style={ { whiteSpace: 'pre-line' } }></span> }
                          placement='left'
                          classes={ { tooltip: classes.tooltip } }
                        >
                          <TableCell align='center'>{ log.query ?
                            <Button color='secondary' onClick={ () => handleOpen(log.query) }>Open</Button> : 'None' }
                          </TableCell>
                        </Tooltip>
                      </TableRow>
                    )) }
                  </TableBody>
                </Table>

                <Modal
                  aria-labelledby='transition-modal-title'
                  aria-describedby='transition-modal-description'
                  className={ classes.modal }
                  open={ open }
                  onClose={ handleClose }
                  closeAfterTransition
                  BackdropComponent={ Backdrop }
                  BackdropProps={ {
                    timeout: 100,
                  } }
                  style={ { color: isDarkMode && isDarkMode ? 'white' : 'black' } }
                >
                  <Fade in={ open }>
                    <div className={ classes.paper2 } style={ { overflowY: eventMessage.length > 500 && 'scroll', whiteSpace: 'pre-wrap' } }>
                      <p id='transition-modal-description' style={ { fontSize: '1.1rem', minHeight: 485 } }>
                        <Anchorme
                          target='_blank'
                          rel='noreferrer noopener'
                          style={ { color: isDarkMode ? '#fff' : '#000' } }
                        >
                          { eventMessage }
                        </Anchorme>
                      </p>

                      <div style={ { textAlign: 'center' } }>
                        <Button
                          variant='contained'
                          color='secondary'
                          size='medium'
                          onClick={ handleCloseModal }
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </Fade>
                </Modal>
              </Paper>

              <PaginationWidget />
            </>
      }
    </Container>
  )
}

const mapStateToProps = state => ({
  currentPage: state.pagination.currentPage,
  pageSize: state.pagination.pageSize,
})

const mapDispatchToProps = {
  resetPagination: paginationActions.resetPagination,
}

export default connect(mapStateToProps, mapDispatchToProps)(LogsPage)
