import {
  FETCH_MONTHLY_DATA_POINTS_START,
  FETCH_MONTHLY_DATA_POINTS_SUCCESS,
  FETCH_MONTHLY_DATA_POINTS_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchMonthlyDataPointsStart = () => ({
  type: FETCH_MONTHLY_DATA_POINTS_START,
})

const fetchMonthlyDataPointsSuccess = monthlyDataPoints => ({
  type: FETCH_MONTHLY_DATA_POINTS_SUCCESS,
  payload: monthlyDataPoints,
})

const fetchMonthlyDataPointsFail = errorMessage => ({
  type: FETCH_MONTHLY_DATA_POINTS_FAIL,
  payload: errorMessage,
})

export const fetchMonthlyDataPoints = () => {
  return async dispatch => {
    try {
      dispatch(fetchMonthlyDataPointsStart())

      const { data } = await axios.get('/api/statistics/monthly-data-points')

      dispatch(fetchMonthlyDataPointsSuccess(data))
    } catch (error) {
      dispatch(fetchMonthlyDataPointsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
