import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Tooltip, Chip, Box } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { addRecipientToNotification, removeRecipientFromNotification } from '../../store/actions/eventNotification'

const useStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: '#5c6bc0',
    color: 'white',
    margin: theme.spacing(0.3),
  },
  tooltip: {
    fontSize: '1.1em',
    maxWidth: 600,
  },
  circle: {
    border: '2px solid red',
    backgroundColor: '#FFFFFF',
    height: '100px',
    borderRadius: '50%',
    width: '10px',
  },
  removeIconButton: {
    height: '25px',
  },
}))

const RecipientList = ({ recipients, currentUser, notificationID, recipientGroup }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleRemoveRecipientClick = () => dispatch(removeRecipientFromNotification(notificationID, currentUser.email, recipientGroup))
  const handleAddRecipientClick = () => dispatch(addRecipientToNotification(notificationID, currentUser.email, recipientGroup))

  let shortenedRecipients = []
  const isCurrentUserInTheList = recipients.includes(currentUser.email)

  if (isCurrentUserInTheList) {
    shortenedRecipients.push(currentUser.email)
  } else if (recipients.length > 0 && !isCurrentUserInTheList) {
    shortenedRecipients.push(recipients[0])
  }

  if (recipients.length > 1) {
    shortenedRecipients.push('...')
  }

  return (
    <>
      <Box display='flex' alignItems='center' >
        <Tooltip classes={ { tooltip: classes.tooltip } } title={ recipients.toString() } placement='top'>
          <Box>
            { shortenedRecipients && shortenedRecipients.length > 0 ?
              shortenedRecipients.map((recipient, key) => <Chip size='small' label={ recipient } key={ key } className={ classes.chip }></Chip>)
              :
              <Chip size='small' label={ 'Empty' }></Chip> }
          </Box>
        </Tooltip>
        { recipients.includes(currentUser.email)
          ?
          <Tooltip classes={ { tooltip: classes.tooltip } } title='Unsubscribe from endpoint' placement='top'>
            <IconButton onClick={ () => handleRemoveRecipientClick() }>
              <RemoveIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          :
          <Tooltip classes={ { tooltip: classes.tooltip } } title='Subscribe to endpoint' placement='top'>
            <IconButton onClick={ () => handleAddRecipientClick() }>
              <AddIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        }
      </Box>
    </>
  )
}

export default RecipientList
