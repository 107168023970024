import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth0 } from '../../hoc/Auth0/react-auth0-spa'
import { rules } from './PriorityRules'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority
  const allowedPaths = rules[userPriority].allowed_paths

  useEffect(() => {
    if (loading || isAuthenticated) return

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      })
    }

    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = props =>
    isAuthenticated ? <Component { ...props } /> : null

  if (allowedPaths.includes(path)) {
    return <Route path={ path } render={ render } { ...rest } />
  } else {
    return <Redirect to={ { pathname: '/dashboard' } } />
  }
}

export default PrivateRoute
