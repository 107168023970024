import React, { Fragment, useState } from 'react'
import { FaFileCsv } from 'react-icons/fa'
import AlertDialog from '../../UI/Dialogs/AlertDialog'
import axios from '../../../axios'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'
import { rules } from '../../Auth0/PriorityRules'
import IconButton from '@material-ui/core/IconButton'
import { CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'

const action = () => (
  <Fragment>
    <CircularProgress color='inherit' size={ 30 } />
  </Fragment>
)

function ExportCSV ({ queryParams }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [openAlertDialog, setOpenAlertDialog] = useState(false)
  const { user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority

  function exportCSVHandler (queryParams) {
    const key = enqueueSnackbar('Exporting in progress...', { persist: true, variant: 'info', action })

    axios
      .get('/api/company/export-csv', {
        params: { ...queryParams },
        responseType: 'blob',
      })
      .then(response => {
        createExportLink(response.data, key)
        enqueueSnackbar('Startups exported!', { variant: 'success' })
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('Internal Server Error cannot export data from database!', { variant: 'error' })
      })
  }

  function createExportLink (data, key) {
    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = downloadUrl
    link.setAttribute('download', `download-${ dayjs().format('DD-MM-YYYY') }.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
    closeSnackbar(key)
  }

  //  export csv end
  return (
    <>
      { !rules[userPriority].forbidden_actions.includes('export-csv') && (
        <IconButton aria-label='Download CSV' onClick={ () => setOpenAlertDialog(true) } color='primary'>
          <FaFileCsv />
        </IconButton>
      ) }
      <AlertDialog
        open={ openAlertDialog }
        setOpen={ setOpenAlertDialog }
        title='Are you sure you want to export as CSV?'
        description='All available pages will be exported'
        btnTitle='Export CSV'
        onSubmit={ () => {
          exportCSVHandler(queryParams)
          setOpenAlertDialog(false)
        } }
      />
    </>
  )
}

const mapStateToProps = state => ({
  queryParams: state.pagination.queryParams,
})

export default connect(mapStateToProps, null)(ExportCSV)
