import {
  FETCH_SCORES_STATS_HISTOGRAMS_START,
  FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS,
  FETCH_SCORES_STATS_HISTOGRAMS_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  scoresStatsHistograms: null,
  isFetching: true,
  errorMessage: '',
}

const scoresStatsHistogramsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SCORES_STATS_HISTOGRAMS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS:
      return {
        ...state,
        scoresStatsHistograms: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_SCORES_STATS_HISTOGRAMS_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default scoresStatsHistogramsReducer
