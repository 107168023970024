import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Font
import 'typeface-roboto'

// Material UI
import CssBaseline from '@material-ui/core/CssBaseline'

// Auth
import { Auth0Provider } from './hoc/Auth0/react-auth0-spa'

// Config
import history from './utils/history'

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname)
}

ReactDOM.render(
  <Provider store={ store }>
    <Auth0Provider
      domain={ window._env_.REACT_APP_DOMAIN }
      client_id={ window._env_.REACT_APP_CLIENT_ID }
      redirect_uri={ window.location.origin }
      onRedirectCallback={ onRedirectCallback }
      audience={ window._env_.REACT_APP_AUDIENCE }
    >
      <CssBaseline />
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
