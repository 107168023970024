import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardContent, Grid, Typography } from '@material-ui/core'
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip as ReTooltip } from 'recharts'

const HistoricChart = ({ title, data, xDataKey, yDataKey, lines }) => {

  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  return (
    <Grid item>
      <Card style={ { marginBottom: 40, width: 580 } }>
        <CardContent>
          <Typography
            variant='h5'
            style={ { marginBottom: 20 } }
          >
            { title }
          </Typography>

          <LineChart width={ 550 } height={ 250 } data={ data }
            margin={ { top: 5, right: 30, left: -10, bottom: 5 } }>
            <CartesianGrid strokeDasharray='2 2' opacity={ isDarkMode ? 0.7 : 1 } />

            <XAxis dataKey={ xDataKey } stroke={ isDarkMode ? '#bdbdbd' : '#686868' } />
            <YAxis dataKey={ yDataKey } stroke={ isDarkMode ? '#bdbdbd' : '#686868' } />

            <ReTooltip
              contentStyle={ {
                backgroundColor: isDarkMode ? '#424242' : '#fff',
                color: isDarkMode ? '#fff' : '#000',
              } }
              itemStyle={ { color: isDarkMode ? '#fff' : '#000' } }
            />

            <Legend />

            {
              lines.map((line, index) => <Line key={ index } type='monotone' dataKey={ line.dataKey } stroke={ line.stroke } strokeWidth={ line.strokeWidth } name={ line.name } />)
            }

          </LineChart>
        </CardContent>
      </Card>
    </Grid>)
}

export default HistoricChart
