import {
  REMOVE_RECIPIENT_FROM_NOTIFICATION_START,
  REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS,
  REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  removeEventNotification: [],
  removeEventNotificationFetching: true,
  removeEventNoticationErrorMessage: '',
}

const removeRecipientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REMOVE_RECIPIENT_FROM_NOTIFICATION_START:
      return {
        ...state,
        removeEventNotificationFetching: true,
        removeEventNoticationErrorMessage: '',
      }
    case REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS:
      return {
        ...state,
        removeEventNotification: action.payload,
        removeEventNotificationFetching: false,
        removeEventNoticationErrorMessage: '',
      }
    case REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL:
      return {
        ...state,
        removeEventNotificationFetching: false,
        removeEventNoticationErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default removeRecipientReducer
