import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumb from '../../components/Navigation/BreadCrumbs/BreadCrumbs'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { fetchMonthlyEntries } from '../../store/actions/monthlyEntries'
import { fetchMonthlyDataPoints } from '../../store/actions/monthlyDataPoints'
import { fetchCompaniesCreatedUpdated } from '../../store/actions/companiesCreatedUpdated'
import LineChartStyled from '../../components/UI/Charts/LineChart'
import Numeral from 'numeral'
import dayjs from 'dayjs'
import { fetchDescriptionsCounts } from '../../store/actions/descriptionsCounts'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
}))

const KPIs = () => {
  const [selectedStartDate, setSelectedStartDate] = useState('')
  const [selectedEndDate, setSelectedEndDate] = useState('')
  const [startDate, setStartDate] = useState(new Date(dayjs().subtract(6, 'days').format('YYYY-MM-DD')))
  const [endDate, setEndDate] = useState(new Date(dayjs().endOf('day').format('YYYY-MM-DD h:mm:ss')))

  const dispatch = useDispatch()

  const classes = useStyles()

  const monthlyEntries = useSelector(state => state.monthlyEntries)
  const { entries, errorMessage: errorMessageEntries } = monthlyEntries

  const monthlyDataPoints = useSelector(state => state.monthlyDataPoints)
  const { dataPoints, errorMessage: errorMessageDataPoints } = monthlyDataPoints

  const companiesCreatedUpdated = useSelector(state => state.companiesCreatedUpdated)
  const { companiesCreatedAndUpdated, errorMessage: errorMessageCompaniesCreatedUpdated } = companiesCreatedUpdated

  const descriptionsCounts = useSelector(state => state.descriptionsCounts)
  const { descriptionsCountsData, errorMessage: errorMessageDescriptionsCounts } = descriptionsCounts

  const handleStartDateChange = selectedDate => setStartDate(selectedDate)
  const handleEndDateChange = selectedDate => setEndDate(new Date(dayjs(selectedDate).endOf('day').format('YYYY-MM-DD h:mm:ss')))

  useEffect(() => {
    dispatch(fetchMonthlyEntries())
    dispatch(fetchMonthlyDataPoints())
    dispatch(fetchDescriptionsCounts())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchCompaniesCreatedUpdated(startDate, endDate))
  }, [dispatch, startDate, endDate])

  return (
    <Fragment>
      <BreadCrumb />

      <Container className={ classes.root }>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <LineChartStyled
              title='Monthly Entries'
              data={ entries }
              error={ errorMessageEntries }
              xDataKey='date'
              dataKey='totalEntries'
              width='100%'
              height={ 300 }
              lineOneName='entries'
              YAxisTickCount={ 6 }
              tickFormatter={ v => Numeral(v).format('0.[00]a') }
              tooltipFormatter={ v => Numeral(v).format('0,0') }
              strokeWidth={ 3 }
              customDomain={ [0, 1_250_000] }
            />
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <LineChartStyled
              title='Monthly Data Points'
              data={ dataPoints }
              error={ errorMessageDataPoints }
              xDataKey='date'
              dataKey='dataPoints'
              width='100%'
              height={ 300 }
              lineOneName='data points'
              YAxisTickCount={ 6 }
              tickFormatter={ v => Numeral(v).format('0a') }
              tooltipFormatter={ v => Numeral(v).format('0,0') }
              strokeWidth={ 3 }
              customDomain={ [0, 35_000_000] }
            />
          </Grid>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <LineChartStyled
              title='Monthly Description and Description Processed Counts'
              data={ descriptionsCountsData }
              error={ errorMessageDescriptionsCounts }
              xDataKey='date'
              dataKey='descriptionCountsAvg'
              dataKeyTwo='descriptionProcessedCountsAvg'
              width='100%'
              height={ 300 }
              lineOneName='avg description counts'
              lineTwoName='avg description processed counts'
              YAxisTickCount={ 6 }
              strokeWidth={ 3 }
            />
          </Grid>

          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <DatePicker
              variant='inline'
              label={ 'Start Date' }
              value={ startDate }
              format='dd/MM/yyyy'
              onChange={ setSelectedStartDate }
              onClose={ () => handleStartDateChange(selectedStartDate ? selectedStartDate : startDate) }
              style={ { width: 88, marginLeft: 50 } }
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <DatePicker
              variant='inline'
              label={ 'End Date' }
              value={ endDate }
              format='dd/MM/yyyy'
              onChange={ setSelectedEndDate }
              onClose={ () => handleEndDateChange(selectedEndDate ? selectedEndDate : endDate) }
              style={ { width: 88, marginLeft: 50 } }
            />
          </MuiPickersUtilsProvider>

          <Grid item xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
            <LineChartStyled
              title='Companies Created And Updated'
              data={ companiesCreatedAndUpdated }
              error={ errorMessageCompaniesCreatedUpdated }
              xDataKey='date'
              dataKey='totalCreatedAt'
              dataKeyTwo='totalUpdatedAt'
              dataKeyThree='topUpdatedBy'
              width='100%'
              height={ 300 }
              lineOneName='created'
              lineTwoName='updated'
              lineThreeName='top updated_by'
              tickFormatter={ v => Numeral(v).format('0a') }
              tooltipFormatter={ v => typeof v === 'number' ? Numeral(v).format('0,0') : v }
              angle={ companiesCreatedAndUpdated && companiesCreatedAndUpdated.length > 17 ? 340 : undefined }
              textAnchor={ companiesCreatedAndUpdated && companiesCreatedAndUpdated.length > 17 ? 'end' : undefined }
              YAxisTickCount={ 6 }
              legendPaddingTop={ companiesCreatedAndUpdated && companiesCreatedAndUpdated.length > 17 && 10 }
              strokeWidth={ 3 }
            />
          </Grid>
        </Grid>
      </Container >
    </Fragment >
  )
}

export default KPIs
