import {
  FETCH_SCORES_STATS_HISTOGRAMS_START,
  FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS,
  FETCH_SCORES_STATS_HISTOGRAMS_FAIL,
} from './actionTypes.js'

import axios from '../../axios'

export const fetchScoresStatsHistogramsStart = () => ({
  type: FETCH_SCORES_STATS_HISTOGRAMS_START,
})

export const fetchScoresStatsHistogramsSuccess = scoresStatsHistograms => ({
  type: FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS,
  payload: scoresStatsHistograms,
})

export const fetchScoresStatsHistogramsFail = errorMessage => ({
  type: FETCH_SCORES_STATS_HISTOGRAMS_FAIL,
  payload: errorMessage,
})

export const fetchScoresStatsHistograms = () => {
  return async dispatch => {
    try {
      dispatch(fetchScoresStatsHistogramsStart())

      const { data } = await axios.get('/api/statistics/score-statistics-for-histograms')

      dispatch(fetchScoresStatsHistogramsSuccess(data))
    } catch (error) {
      dispatch(fetchScoresStatsHistogramsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
