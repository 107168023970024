import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  currentPage: 1,
  pageSize: 25,
  queryParams: null,
  selectedFilter: '',
  filters: [],
  totalCount: 0,
}

const setSelectedFilter = (state, action) => {
  return updateObject(state, { selectedFilter: action.selectedFilter })
}

const setFilters = (state, action) => {
  return updateObject(state, { filters: action.filters })
}

const setPage = (state, action) => {
  return updateObject(state, { currentPage: action.page })
}

const setPageSize = (state, action) => {
  return updateObject(state, { pageSize: action.pageSize })
}

const setQueryParams = (state, action) => {
  return updateObject(state, { queryParams: action.queryParams, currentPage: 1, totalCount: 0 })
}

const setTotalCount = (state, action) => {
  const totalPages = Math.ceil(action.totalCount / state.pageSize)
  let currentPage = state.currentPage

  if (currentPage > totalPages && totalPages !== 0) {
    currentPage = totalPages
  }
  return updateObject(state, { totalCount: action.totalCount, currentPage: currentPage })
}

const resetPagination = () => {
  return initialState
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_FILTER:
      return setSelectedFilter(state, action)
    case actionTypes.SET_FILTERS:
      return setFilters(state, action)
    case actionTypes.RESET_PAGINATION:
      return resetPagination(state, action)
    case actionTypes.SET_PAGINATION_PAGE:
      return setPage(state, action)
    case actionTypes.SET_PAGINATION_PAGE_SIZE:
      return setPageSize(state, action)
    case actionTypes.SET_PAGINATION_QUERY_PARAMS:
      return setQueryParams(state, action)
    case actionTypes.SET_PAGINATION_TOTAL_COUNT:
      return setTotalCount(state, action)
    default:
      return state
  }
}

export default reducer
