import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table, TableHead, TableBody } from '@material-ui/core'
import { TableCell, TableRow, Paper, Checkbox, Tooltip, Button, Backdrop, Fade, Modal as MaterialModal } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ImportExportIcon from '@material-ui/icons/ImportExport'

import { connect } from 'react-redux'
import CircularSpinner from '../../UI/Progress/CircularSpinner'
import CenterBox from '../../UI/CenterBox'
import NoDataFoundMessage from '../../Messages/NoDataMessage'
import ErrorMessage from '../../Messages/ErrorMessage'
import Modal from '../../UI/Modal'
import EditCompany from '../Widget/EditCompany'
import AliasConflict from '../../../pages/AliasConflict'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'
import { rules } from '../../Auth0/PriorityRules'
import * as companyListActions from '../../../store/actions/companyList'
import * as companyActions from '../../../store/actions/company'
import TableData from './TableData/TableData'
import { fetchSearchResults } from '../../../store/actions/companyList'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    height: 900,
    width: 1300,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    color: theme.isDarkMode ? '#fff' : '#000',
  },
  paper: {
    margin: theme.spacing(2, 0, 2, 0),
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}))

const StartupTable = ({ tableData, loading, error, importTable, selectAllCompanies, openModal, openConflictModal, setOpenModal, setConflictModal }) => {
  const dispatch = useDispatch()

  const [updatedAtSort, setUpdatedAtSort] = useState('desc')
  const [companyModified, setCompanyModified] = useState(false)

  const classes = useStyles()
  const { user } = useAuth0()

  const userPriority = user['https://api.valuer.ml/'].role_priority
  const forbiddenActions = rules[userPriority].forbidden_actions

  const handleClick = () => {
    setUpdatedAtSort(updatedAtSort === 'desc' ? 'asc' : 'desc')

    dispatch(fetchSearchResults('companies', updatedAtSort))
  }

  if (error) {
    return (
      <Paper className={ classes.paper } elevation={ 1 }>
        <ErrorMessage errorMessage={ error } />
      </Paper>
    )
  }

  if (tableData.length === 0 && !loading) {
    return (
      <Paper className={ classes.paper } elevation={ 1 }>
        <NoDataFoundMessage />
      </Paper>
    )
  }

  if (loading) {
    return (
      <Paper className={ classes.paper } elevation={ 1 }>
        <CenterBox height='60vh'>
          <CircularSpinner size={ 150 } />
        </CenterBox>
      </Paper>
    )
  }

  return (
    <Paper className={ classes.paper } elevation={ 1 }>
      <Table size='small' aria-label='simple table'>
        <TableHead>
          <TableRow>
            { importTable && (
              <TableCell align='center'>
                Approve
                <br />
                <Checkbox color='primary' onChange={ e => selectAllCompanies(e.target.checked) } />
              </TableCell>
            ) }
            <TableCell align='center'>Organisation</TableCell>
            <TableCell align='center'>Sectors</TableCell>
            <TableCell align='center'>HQ Country</TableCell>
            <Tooltip title='Sort by updatedAt' placement='top' arrow style={ { cursor: 'pointer' } }>
              <TableCell align='center' onClick={ handleClick }>Updated At<ImportExportIcon color='primary' style={ { verticalAlign: 'bottom' } } /></TableCell>
            </Tooltip>
            <TableCell align='center'>Priority</TableCell>
            <TableCell align='center'>Founded year</TableCell>
            { importTable && (<TableCell align='center'>Updated By</TableCell>) }
            { !forbiddenActions.includes('edit') ? <TableCell align='center'>Edit</TableCell> : <TableCell align='center'>View</TableCell> }
          </TableRow>
        </TableHead>

        <TableBody>
          <TableData importTable={ importTable } />
        </TableBody>
      </Table>

      { openModal && (
        <Modal openModal={ openModal } setOpenModal={ setOpenModal } title={ !forbiddenActions.includes('edit') ? 'Edit Company' : 'View Company' } allowAlert={ companyModified }>
          <EditCompany importTable={ importTable } setAlert={ data => setCompanyModified(data) } />
        </Modal>
      ) }

      { openConflictModal &&
        <MaterialModal
          aria-labelledby='modal-title'
          aria-describedby='modal-description'
          className={ classes.modal }
          open={ openConflictModal }
          onClose={ () => {
            setConflictModal(false)
            dispatch(companyListActions.fetchLastUpdatedCompanies())
          } }
          closeAfterTransition
          BackdropComponent={ Backdrop }
          BackdropProps={ {
            timeout: 200,
          } }
        >
          <Fade in={ open }>
            <div className={ classes.paperModal }>
              <div style={ { textAlign: 'center' } }>
                <h2 id='modal-title'>Conflict</h2>
              </div>

              <AliasConflict />

              <div style={ { textAlign: 'center' } }>
                <Button
                  variant='contained'
                  color='primary'
                  size='medium'
                  onClick={ () => setConflictModal(false) }
                >
                  Close
                </Button>
              </div>
            </div>
          </Fade>
        </MaterialModal> }
    </Paper>
  )
}

const mapStateToProps = state => ({
  tableData: state.companyList.data,
  loading: state.companyList.loading,
  error: state.companyList.error,
  openModal: state.company.openModal,
  openConflictModal: state.company.openConflictModal,
})

const mapDispatchToProps = {
  selectAllCompanies: companyListActions.selectAllCompanies,
  setOpenModal: companyActions.setOpenCompanyModal,
  setConflictModal: companyActions.setOpenConflictModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartupTable)
