export const SectorColors = {
  'Accommodation & Food': '#FF0000',
  'Administrative Services': '#FF7F50',
  'Agriculture & Farming': '#CD5C5C',
  'Biotechnology': '#FA8072',
  'Business Consultancy': '#F4A460',
  'Consumer Goods': '#FF8C00',
  'Data & Analytics': '#7B68EE',
  'Education': '#FFD700',
  'Energy': '#7CFC00',
  'Financial Services': '#006400',
  'Hardware': '#00FF00',
  'Health Care': '#00FA9A',
  'Information Technology': '#008000',
  'Manufacturing': '#00FFFF',
  'Media & Entertainment': '#7FFFD4',
  'Military & Defence': '#00BFFF',
  'Natural Resources': '#0000FF',
  'Privacy & Security': '#8A2BE2',
  'Real Estate': '#483D8B',
  'Retail & Marketing': '#9400D3',
  'Science & Engineering': '#FF00FF',
  'Software': '#FF1493',
  'Sustainability': '#FFC0CB',
  'Transportation': '#40E0D0',
}

// Source of color codes -> https://cloford.com/resources/colours/500col.htm
export const Colors = [
  '##B0171F',
  '#DC143C',
  '#FFB6C1',
  '#DB7093',
  '#EE799F',
  '#FF3E96',
  '#CD1076',
  '#FF34B3',
  '#FF83FA',
  '#CD69C9',
  '#FF00FF',
  '#9400D3',
  '#8A2BE2',
  '#912CEE',
  '#7A67EE',
  '#0000FF',
  '#1874CD',
  '#63B8FF',
  '#98F5FF',
  '#00F5FF',
  '#00CED1',
  '#00CDCD',
  '#48D1CC',
  '#40E0D0',
  '#00C78C',
  '#00FA9A',
  '#008B45',
  '#00EE00',
  '#7CFC00',
  '#B3EE3A',
  '#FFFF00',
  '#FFD700',
  '#FF9912',
  '#FF7F00',
  '#FF4500',
]
