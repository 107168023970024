import {
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  data: [],
  unsubscribeAllEventNotificationsFetching: true,
  unsubscribeAllEventNotificationsErrorMessage: '',
}

const unsubscribeAllEventNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_START:
      return {
        ...state,
        unsubscribeAllEventNotificationsFetching: true,
        unsubscribeAllEventNotificationsErrorMessage: '',
      }
    case UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        unsubscribeAllEventNotificationsFetching: false,
        unsubscribeAllEventNotificationsErrorMessage: '',
      }
    case UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL:
      return {
        ...state,
        unsubscribeAllEventNotificationsFetching: false,
        unsubscribeAllEventNotificationsErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default unsubscribeAllEventNotificationReducer
