import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Link, Select, MenuItem, Checkbox, Tooltip, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BetaIcon from '../../../utils/Icons/BetaIcon'

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '.9em',
    maxWidth: 500,
  },
}))

const AdditionalInformationForm = ({ data, onInputChange, userPriority }) => {
  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const classes = useStyles()

  const useStateWithCallback = initialValue => {
    const callbackRef = useRef(null)

    const [value, setValue] = useState(initialValue)

    useEffect(() => {
      if (callbackRef.current) {
        callbackRef.current(value)

        callbackRef.current = null
      }
    }, [value])

    const setValueWithCallback = (newValue, callback) => {
      callbackRef.current = callback

      return setValue(newValue)
    }

    return [value, setValueWithCallback]
  }

  const [active, setActive] = useStateWithCallback(true)

  useEffect(() => {
    setActive(data.active)
  }, [])

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        <Typography><b>ID: </b>{ data._id }</Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <b>Active: </b>

        <Checkbox
          name='active'
          color='primary'
          checked={ Boolean(+active) }
          value={ active }
          onChange={ e => {
            e.persist()

            setActive(!active, () => {
              onInputChange(e)
            })
          } }
          style={ { color: isDarkMode ? '#fff' : '#000', padding: 0 } }
        />

        <BetaIcon viewBox='-30 -6 178.411 60' verticalAlign='bottom' />
      </Grid>

      <Grid item xs={ 12 }>
        <Typography>
          <b>Created:</b> { data.createdAt ? new Date(data.createdAt).toLocaleString() : 'null' }
        </Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <Typography>
          <b>Last Update:</b> { data.updatedAt ? new Date(data.updatedAt).toLocaleString() : 'null' }
        </Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <Typography>
          <b>Last Update By:</b> { data.updated_by ? data.updated_by : 'null' }
        </Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <Typography>
          <b>Source URL: </b>
          { data.source_url && (
            <Link href={ data.source_url } target='_blank' rel='noopener noreferrer'>
              { data.source_url }
            </Link>
          ) }
        </Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Description Vectorized is required. It will be automatically generated based on Description' placement='top-start' classes={ { tooltip: classes.tooltip } }>

          <Typography>
            <b>Description Vectorized <Box component='span' style={ { color: 'red' } }>*</Box> : </b>

            <Checkbox
              color='primary'
              checked={ data.description_hashed }
              disabled
              style={ { color: isDarkMode ? 'rgba(255, 255, 255, 1)' : '#000', padding: 0 } }
            />
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Description Processed is required. It will be automatically generated based on Description' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography>
            <b>Description Processed <Box component='span' style={ { color: 'red' } }>*</Box> :</b> { data.description_processed }
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Projection is required. It will be automatically generated based on Description' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography>
            <b>Projection <Box component='span' style={ { color: 'red' } }>*</Box> :</b> { data.projection ? data.projection.length > 0 ? `[${ data.projection.join(', ') }]` : '[ ]' : null }
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item xs={ 12 }>
        <b>Frontend Ready: </b>

        <Checkbox
          color='primary'
          checked={ data.frontend_ready }
          disabled
          style={ { color: isDarkMode ? 'rgba(255, 255, 255, 1)' : '#000', padding: 0 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Typography display='inline'><b>Priority: </b></Typography>

        <Select
          labelId='select-helper-label'
          id='select-helper'
          name='priority'
          style={ { width: 50, textAlign: 'center' } }
          value={ data.priority ?? '' }
          onChange={ onInputChange }
        >
          { [0, 1, 2, 3].map((priority, index) => <MenuItem key={ index } value={ priority } disabled={ userPriority < 3 }>{ priority }</MenuItem>) }
          <MenuItem key={ 4 } value={ 4 } disabled>{ 4 }</MenuItem>
        </Select>
      </Grid>
    </Grid>
  )
}

export default AdditionalInformationForm
