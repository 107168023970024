import {
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  data: [],
  subscribeAllEventNotificationsFetching: true,
  subscribeAllEventNotificationsErrorMessage: '',
}

const subscribeAllEventNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUBSCRIBE_ALL_EVENT_NOTIFICATION_START:
      return {
        ...state,
        subscribeAllEventNotificationsFetching: true,
        subscribeAllEventNotificationsErrorMessage: '',
      }
    case SUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        subscribeAllEventNotificationsFetching: false,
        subscribeAllEventNotificationsErrorMessage: '',
      }
    case SUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL:
      return {
        ...state,
        subscribeAllEventNotificationsFetching: false,
        subscribeAllEventNotificationsErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default subscribeAllEventNotificationReducer
