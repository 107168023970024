import React, { useEffect } from 'react'
import { Switch, Route, Redirect, Router } from 'react-router-dom'
import { useAuth0 } from './hoc/Auth0/react-auth0-spa'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import Loading from './pages/Loading/Loading'
import Login from './pages/Login/Login'
import FrontendReady from './pages/FrontendReady'
import ProtectedRoutes from './ProtectedRoutes'
import history from './utils/history'
import { connect, useDispatch } from 'react-redux'
import * as themeActions from './store/actions/theme'

function App ({ theme, toggleDarkMode }) {
  const dispatch = useDispatch()

  const themeConfig = createTheme(theme)
  const { isAuthenticated, loading } = useAuth0()

  useEffect(() => {
    localStorage.getItem('darkMode') === 'true' && dispatch(toggleDarkMode)
  }, [dispatch, toggleDarkMode])

  if (loading) {
    return <Loading />
  }

  return (
    <Router history={ history }>
      <ThemeProvider theme={ themeConfig }>
        <Switch>
          <Route path='/frontend-ready' exact><FrontendReady /></Route>
          <Route path='/login' render={ props => <Login { ...props } /> } />
          <Route path='/'>{ isAuthenticated ? <ProtectedRoutes toggleDarkMode={ toggleDarkMode } /> : <Redirect to='/login' /> }</Route>
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

const mapStateToProps = state => ({
  theme: state.theme,
})

const mapDispatchToProps = {
  toggleDarkMode: themeActions.toggleDarkMode,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
