import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core/'
import { FaPowerOff, FaBug, FaUserCircle } from 'react-icons/fa'

const useStyles = makeStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})

const AccountMenu = ({ anchorEl, handleClose, handleLogout, handleProfileButtonClick }) => {
  const classes = useStyles()

  return (
    <Menu
      className={ classes.paper }
      getContentAnchorEl={ null }
      anchorOrigin={ { vertical: 'bottom', horizontal: 'center' } }
      anchorEl={ anchorEl }
      open={ Boolean(anchorEl) }
      onClose={ handleClose }
    >

      <MenuItem onClick={ handleProfileButtonClick }>
        <ListItemIcon>
          <Icon color='primary'>
            <FaUserCircle />
          </Icon>
        </ListItemIcon>
        <ListItemText primary='My Profile' />
      </MenuItem>

      <MenuItem>
        <ListItemIcon>
          <Icon color='primary'>
            <FaBug />
          </Icon>
        </ListItemIcon>
        <ListItemText primary='Report a bug' />
      </MenuItem>

      <MenuItem onClick={ handleLogout }>
        <ListItemIcon>
          <Icon color='primary'>
            <FaPowerOff />
          </Icon>
        </ListItemIcon>
        <ListItemText primary='Logout' />
      </MenuItem>
    </Menu>
  )
}

export default AccountMenu
