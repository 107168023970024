import * as actionTypes from './actionTypes'
import axios from '../../axios'
import * as pagination from './pagination'

export const selectCompany = (id, checked) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_COMPANY,
      id: id,
      checked: checked,
    })
  }
}

export const selectAllCompanies = checked => {
  return dispatch => {
    dispatch({
      type: actionTypes.SELECT_ALL_COMPANIES,
      checked: checked,
    })
  }
}

const fetchCompanyListFail = error => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_LIST_FAIL,
      error: error,
    })
  }
}

const fetchCompanyListStart = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_LIST_START,
    })
  }
}

const fetchCompanyListSuccess = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_LIST_SUCCESS,
      data: data,
    })
  }
}

export const deleteCompanyListSuccess = id => {
  return dispatch => {
    dispatch({
      type: actionTypes.DELETE_COMPANY_LIST_SUCCESS,
      id: id,
    })
  }
}

export const updateCompanyListSuccess = updatedCompany => {
  if (updatedCompany.sectors && !Array.isArray(updatedCompany.sectors)) {
    updatedCompany.sectors = updatedCompany.sectors.split(',')
  }

  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_COMPANY_LIST_SUCCESS,
      updatedCompany: updatedCompany,
    })
  }
}

export const fetchLastUpdatedCompanies = () => {
  return dispatch => {
    dispatch(fetchCompanyListStart())
    axios
      .get('/api/dashboard/latest-changes')
      .then(response => {
        dispatch(fetchCompanyListSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchCompanyListFail(err.message))
      })
  }
}

export const fetchSearchResults = (collectionToUse = 'companies', updatedAt) => {
  return (dispatch, getState) => {
    const { currentPage, pageSize, queryParams } = getState().pagination
    dispatch(fetchCompanyListStart())
    axios
      .get('/api/company', {
        params: {
          ...queryParams,
          page: currentPage,
          limit: pageSize,
          collectionToUse,
          updatedAt,
        },
      })
      .then(response => {
        dispatch(fetchCompanyListSuccess(response.data.companies))
        dispatch(pagination.setTotalCount(response.data.count))
      })
      .catch(err => {
        dispatch(fetchCompanyListFail(err.message))
      })
  }
}
