import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'
import { Fab, Typography, Box } from '@material-ui/core/'
import { AppBar, Toolbar, IconButton, FormControlLabel, Switch, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import AccountMenu from '../Menus/AccountMenu'
import Avatar from '@material-ui/core/Avatar'
import { drawerWidth } from '../../../hoc/Layout/Layout'
import axios from '../../../axios'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  userName: {
    marginRight: 10,
  },
  appBar: {
    position: 'absolute',
    background: 'none',
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${ drawerWidth }px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  avatar: {
    margin: 10,
    width: 40,
    height: 40,
  },
  databaseStatusTooltip: {
    marginRight: 20,
  },
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
}))

const Topbar = props => {
  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const { user, logout } = useAuth0()

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [databaseStatus, setDatabaseStatus] = useState('unlocked')
  const history = useHistory()

  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/api/get-database-status-frontend')
      setDatabaseStatus(data.databaseStatus)
    })()
  }, [])

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleLogout = () => {
    setAnchorEl(null)
    logout()
  }

  const handleProfileButtonClick = () => {
    history.push('/profile')
  }

  return (
    <AppBar color='default' className={ classes.appBar }>
      <Toolbar className={ classes.toolbar }>
        <IconButton color='inherit' aria-label='open drawer' edge='start' onClick={ props.handleDrawerToggle } className={ classes.menuButton }>
          <MenuIcon />
        </IconButton>

        <Box width='100%' display='flex' justifyContent='flex-end'>
          <Box alignItems='center' display='flex'>
            <Tooltip
              title={ databaseStatus === 'unlocked' ?
                'Database status: Live'
                :
                'Database status: Under Maintenance. Changes in the database will be saved and processed later. Please postpone any non-critical operations'
              }
              placement='bottom'
              arrow
              classes={ { tooltip: classes.tooltip } }
              className={ classes.databaseStatusTooltip }
            >
              <Box fontSize='1rem'>
                { databaseStatus === 'unlocked' ?
                  <Box>Live <FiberManualRecordIcon style={ { color: 'green', verticalAlign: 'bottom' } } /></Box>
                  :
                  <Box>Maintenance <FiberManualRecordIcon style={ { color: 'orange', verticalAlign: 'bottom' } } /></Box> }
              </Box>
            </Tooltip>

            <Tooltip title='Toggle dark mode' placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
              <FormControlLabel
                control={
                  <Box>
                    <Brightness4Icon style={ { verticalAlign: 'bottom' } } />
                    <Switch checked={ isDarkMode } color='primary' size='small' onClick={ props.toggleDarkMode } />
                  </Box> }
              />
            </Tooltip>

            <Typography variant='body2' className={ classes.userName }>
              Hi, { user ? user.given_name : null }
            </Typography>

            <Avatar alt='Account menu' component={ Fab } src={ user.picture } onClick={ handleClick } className={ classes.avatar } />

            <AccountMenu anchorEl={ anchorEl } setAnchorEl={ setAnchorEl } handleClose={ handleClose } handleLogout={ handleLogout } handleProfileButtonClick={ handleProfileButtonClick } />
          </Box>
        </Box>
      </Toolbar >
    </AppBar >
  )
}

export default Topbar
