import {
  FETCH_HISTORIC_SCORES_STATS_START,
  FETCH_HISTORIC_SCORES_STATS_SUCCESS,
  FETCH_HISTORIC_SCORES_STATS_FAIL,
} from './actionTypes.js'

import axios from '../../axios'

export const fetchHistoricScoresStatsStart = () => ({
  type: FETCH_HISTORIC_SCORES_STATS_START,
})

export const fetchHistoricScoresStatsSuccess = historicScoresStats => ({
  type: FETCH_HISTORIC_SCORES_STATS_SUCCESS,
  payload: historicScoresStats,
})

export const fetchHistoricScoresStatsFail = errorMessage => ({
  type: FETCH_HISTORIC_SCORES_STATS_FAIL,
  payload: errorMessage,
})

export const fetchHistoricScoresStats = () => {
  return async dispatch => {
    try {
      dispatch(fetchHistoricScoresStatsStart())

      const { data } = await axios.get('/api/statistics/historic-scores')

      dispatch(fetchHistoricScoresStatsSuccess(data))
    } catch (error) {
      dispatch(fetchHistoricScoresStatsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
