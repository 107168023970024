export const rules = {
  0: {
    forbidden_actions: [
      'export-csv',
      'edit',
      'add',
    ],
    allowed_paths: [
      '/dashboard',
      '/database',
      '/database/search',
      '/profile',
    ],
  },
  1: {
    forbidden_actions: [],
    allowed_paths: [
      '/dashboard',
      '/database',
      '/database/search',
      '/database/add-company',
      '/statistics',
      '/statistics/overview-internal',
      '/statistics/overview-external',
      '/statistics/scores-overview',
      '/statistics/kpis',
      '/statistics/clusters',
      '/profile',
    ],
  },
  2: {
    forbidden_actions: [],
    allowed_paths: [
      '/dashboard',
      '/database',
      '/database/search',
      '/database/add-company',
      '/statistics',
      '/statistics/overview-internal',
      '/statistics/overview-external',
      '/statistics/scores-overview',
      '/statistics/kpis',
      '/statistics/clusters',
      '/profile',
    ],
  },
  3: {
    forbidden_actions: [],
    allowed_paths: [
      '/dashboard',
      '/database',
      '/database/search',
      '/database/add-company',
      '/database/conflicts',
      '/statistics',
      '/statistics/overview-internal',
      '/statistics/overview-external',
      '/statistics/scores-overview',
      '/statistics/kpis',
      '/statistics/clusters',
      '/profile',
    ],
  },
  4: {
    forbidden_actions: [],
    allowed_paths: [
      '/dashboard',
      '/database',
      '/database/search',
      '/database/add-company',
      '/database/conflicts',
      '/database/logs',
      '/database/logs/notifications',
      '/statistics',
      '/statistics/overview-internal',
      '/statistics/overview-external',
      '/statistics/scores-overview',
      '/statistics/kpis',
      '/statistics/clusters',
      '/profile',
    ],
  },
}
