import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  data: {},
  error: null,
  loading: false,
  openModal: false,
  openConflictModal: false,
}

const setOpenCompanyModal = (state, action) => {
  return updateObject(state, { openModal: action.openModal })
}

const setOpenConflictModal = (state, action) => {
  return updateObject(state, { openConflictModal: action.openConflictModal })
}

// eslint-disable-next-line no-unused-vars
const fetchCompanyStart = (state, action) => {
  return updateObject(state, { loading: true, error: null })
}

const fetchCompanySuccess = (state, action) => {
  return updateObject(state, { data: action.data, loading: false })
}

const fetchCompanyFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPEN_COMPANY_MODAL:
      return setOpenCompanyModal(state, action)
    case actionTypes.SET_OPEN_CONFLICT_MODAL:
      return setOpenConflictModal(state, action)
    case actionTypes.FETCH_COMPANY_START:
      return fetchCompanyStart(state, action)
    case actionTypes.FETCH_COMPANY_SUCCESS:
      return fetchCompanySuccess(state, action)
    case actionTypes.FETCH_COMPANY_FAIL:
      return fetchCompanyFail(state, action)
    default:
      return state
  }
}

export default reducer
