import React from 'react'
import Typography from '@material-ui/core/Typography'
import CircularSpinner from '../../components/UI/Progress/CircularSpinner'
import logo from './assets/valuer-logo.png'
import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  Loading: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
  },
  Logo: {
    height: '150px',
  },
}))

const Loading = () => {
  const classes = useStyles()

  return (
    <Typography component='div' className={ classes.Loading }>
      <img src={ logo } className={ classes.Logo } alt='logo' />

      <CircularSpinner />
    </Typography>
  )
}

export default Loading
