import {
  FETCH_DESCRIPTIONS_COUNTS_START,
  FETCH_DESCRIPTIONS_COUNTS_SUCCESS,
  FETCH_DESCRIPTIONS_COUNTS_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchDescriptionsCountsStart = () => ({
  type: FETCH_DESCRIPTIONS_COUNTS_START,
})

const fetchDescriptionsCountsSuccess = descriptionsCounts => ({
  type: FETCH_DESCRIPTIONS_COUNTS_SUCCESS,
  payload: descriptionsCounts,
})

const fetchDescriptionsCountsFail = errorMessage => ({
  type: FETCH_DESCRIPTIONS_COUNTS_FAIL,
  payload: errorMessage,
})

export const fetchDescriptionsCounts = () => {
  return async dispatch => {
    try {
      dispatch(fetchDescriptionsCountsStart())

      const { data } = await axios.get('/api/statistics/monthly-description-and-description-processed-counts')

      dispatch(fetchDescriptionsCountsSuccess(data))
    } catch (error) {
      dispatch(fetchDescriptionsCountsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
