import {
  FETCH_MONTHLY_FUNDING_COUNTS_START,
  FETCH_MONTHLY_FUNDING_COUNTS_SUCCESS,
  FETCH_MONTHLY_FUNDING_COUNTS_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchMonthlyFundingCountsStart = () => ({
  type: FETCH_MONTHLY_FUNDING_COUNTS_START,
})

const fetchMonthlyFundingCountsSuccess = monthlyFundingCounts => ({
  type: FETCH_MONTHLY_FUNDING_COUNTS_SUCCESS,
  payload: monthlyFundingCounts,
})

const fetchMonthlyFundingCountsFail = errorMessage => ({
  type: FETCH_MONTHLY_FUNDING_COUNTS_FAIL,
  payload: errorMessage,
})

export const fetchMonthlyFundingCounts = () => {
  return async dispatch => {
    try {
      dispatch(fetchMonthlyFundingCountsStart())

      const { data } = await axios.get('/api/statistics/monthly-funding')

      dispatch(fetchMonthlyFundingCountsSuccess(data))
    } catch (error) {
      dispatch(fetchMonthlyFundingCountsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
