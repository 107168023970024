import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Container, Slider, Typography, TextField, InputLabel, Select, MenuItem, FormControl, Tooltip, Button, IconButton, Collapse } from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'
import SearchIcon from '@material-ui/icons/Search'
import MultiSelectAutocomplete from '../../../components/UI/Forms/MultiSelectAutocomplete'
import SubSectorsAutocomplete from '../../../components/Startup/Forms/Inputs/SubSectorsAutocomplete'
import SectorsList from '../../../Constants/Sectors'
import CountriesList from '../../../Constants/Countries'
import BreadCrumb from '../../../components/Navigation/BreadCrumbs/BreadCrumbs'
import ScatterPlot from './ScatterPlot'
import CircularSpinner from '../../../components/UI/Progress/CircularSpinner'
import CenterBox from '../../../components/UI/CenterBox'
import { fetchClusterData } from '../../../store/actions/clusterData'
import { fetchCompany } from '../../../store/actions/company'
import useEventListener from '../../../utils/eventListenerHook'
import SelectedCompanyBox from './SelectedCompanyBox'
import SelectedCompanyModal from './SelectedCompanyModal'
import { Colors } from '../../../Constants/SectorColors'
import Numeral from 'numeral'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(8),
  },
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
}))

const ClustersPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    fetchScatterPlotData()
  }, [])

  const keydownEventHandler = event => {
    if (event.keyCode === 13) fetchScatterPlotData()
  }

  useEventListener('keydown', keydownEventHandler)

  const randomTwoDefaultSectors = SectorsList.sort(() => 0.5 - Math.random()).slice(0, 2)
  const { clusterData, isFetching, errorMessage } = useSelector(state => state.clusterData)
  const { data, loading, error } = useSelector(state => state.company)
  const [basicFiltersCollapse, setBasicFiltersCollapse] = useState(true)
  const [advancedFiltersCollapse, setAdvancedFiltersCollapse] = useState(false)
  const [foundedYearScale, setfoundedYearScale] = useState([22, 80])
  const [maxDataPointsScale, setMaxDataPointsSclae] = useState(10000)
  const [filters, setFilters] = useState({ sectors: randomTwoDefaultSectors, colors: SectorsList.map(o => ({ option: o, color: Colors[Math.floor(Math.random() * Colors.length)] })) })
  const [filtersToggleCounter, setFiltersToggleCounter] = useState(2)
  const [filtersToggleText, setFiltersToggleText] = useState('Open advanced filters')
  const [selectedMarkerData, setSelectedMarkerData] = useState({})
  const [showSelectedMarkerBox, setShowSelectedMarkerBox] = useState(false)
  const [showSelectedMarkerModal, setShowSelectedMarkerModal] = useState(false)

  const fetchScatterPlotData = () => {
    dispatch(fetchClusterData(filters.sectors, maxDataPointsScale))
  }

  const onInputChange = event => {
    const name = event.target.name
    let value = null

    if (event.target.type === 'number') {
      value = +event.target.value
    } else {
      value = event.target.value
    }

    setFilters(prevState => ({ ...prevState, [name]: value }))
  }

  const onAutocompleteChange = (name, value) => {
    const event = {
      target: {
        name: name,
        value: value,
      },
    }

    onInputChange(event)
  }

  const handleFoundationYearScaleChange = (event, newValue) => {
    setfoundedYearScale(newValue)
  }

  const handleMaxDataPointsScale = (event, newValue) => {
    setMaxDataPointsSclae(newValue)
  }

  const handleSearchButtonClick = () => {
    fetchScatterPlotData()
  }

  const handleFiltersCollapes = clickCounter => {
    if (clickCounter === 1) {
      setBasicFiltersCollapse(true)
      setFiltersToggleText('Open advanced filters')
    }

    if (clickCounter === 2) {
      setAdvancedFiltersCollapse(true)
      setFiltersToggleText('Hide advanced filters')
    }

    if (clickCounter === 3) {
      setAdvancedFiltersCollapse(false)
      setFiltersToggleText('Hide basic filters')
    }

    if (clickCounter === 4) {
      setBasicFiltersCollapse(false)
      setFiltersToggleCounter(0)
      setFiltersToggleText('Open basic filters')
    }

    setFiltersToggleCounter(prev => prev + 1)
  }

  const handleOnColorChange = newColors => setFilters(prev => ({ ...prev, colors: newColors }))

  const handleOnMarkerHover = data => {
    const newSelection = clusterData.find(c => c.name === data.points[0].text)
    setSelectedMarkerData(newSelection)
    setShowSelectedMarkerBox(true)
  }

  const handleMarkerUnhover = () => setShowSelectedMarkerBox(false)

  const handleOnMarkerClicked = data => {
    const newSelection = clusterData.find(c => c.name === data.points[0].text)
    dispatch(fetchCompany(newSelection._id, null, 'companies'))
    setShowSelectedMarkerModal(true)
  }

  const handleSetOpenModal = () => {
    setShowSelectedMarkerBox(false)
    setShowSelectedMarkerModal(false)
  }

  return (
    <Container className={ classes.root }>
      <BreadCrumb />

      <Box display='flex' justifyContent='flex-end'>
        <Tooltip title={ filtersToggleText } placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
          <IconButton aria-label='Filters' onClick={ () => handleFiltersCollapes(filtersToggleCounter) }>
            <TuneIcon color='primary' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Search' placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
          <Button
            style={ { height: '2.3rem', marginTop: '6px' } }
            variant='contained'
            color='secondary'
            size='medium'
            endIcon={ <SearchIcon color='primary' /> }
            onClick={ handleSearchButtonClick }
          >
            Search
          </Button>
        </Tooltip>
      </Box>

      <Collapse in={ basicFiltersCollapse }>
        <Box paddingRight={ 5 } paddingLeft={ 5 } paddingBottom={ 1 }>
          <Grid className={ classes.formWrapper } container spacing={ 1 }>
            <Grid item xs={ 12 } md={ 4 }>
              <MultiSelectAutocomplete
                disabled={ false }
                colorFeature={ true }
                valuesWithColors={ filters.colors }
                onColorChange={ newColors => handleOnColorChange(newColors) }
                multiple={ true }
                options={ SectorsList }
                value={ filters.sectors }
                onChange={ sectors => onAutocompleteChange('sectors', sectors) }
                label='Industries'
                margin='dense'
              />
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <SubSectorsAutocomplete disabled={ true } />
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <FormControl style={ { marginTop: 5 } } fullWidth>
                <InputLabel id='select-helper-label'>Priority</InputLabel>

                <Select
                  disabled
                  labelId='select-helper-label'
                  id='select-helper'
                  name='priority'
                >
                  <MenuItem value=''>Reset Priority</MenuItem>

                  { [0, 1, 2, 3, 4].map((priority, index) => <MenuItem key={ index } value={ priority }>{ priority }</MenuItem>) }
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <MultiSelectAutocomplete
                disabled={ true }
                multiple={ true }
                options={ CountriesList }
                label='HQ Countries'
                margin='dense'
              />
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <TextField
                disabled
                label='Continent'
                name='hq_continent'
                fullWidth
                margin='dense'
                autoComplete='off'
                inputProps={ {
                  autoComplete: 'new-password',
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <Box mt={ 2 }>
                <Typography id='country_limit_slider' >Max data points</Typography>

                <Slider
                  min={ 1000 }
                  max={ 100000 }
                  step={ 500 }
                  defaultValue={ 10000 }
                  value={ maxDataPointsScale }
                  valueLabelDisplay='auto'
                  valueLabelFormat={ number => Numeral(number).format('0a') }
                  onChange={ handleMaxDataPointsScale }
                  aria-labelledby='country_limit_slider'
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      <Collapse in={ advancedFiltersCollapse }>
        <Box paddingRight={ 5 } paddingLeft={ 5 } paddingBottom={ 1 }>
          <Grid className={ classes.formWrapper } container spacing={ 1 }>
            <Grid item xs={ 12 } md={ 4 }>
              <Box mt={ 2 }>
                <Typography id='founded-year-slider' gutterBottom>Founded year scale</Typography>

                <Slider
                  disabled
                  getAriaLabel={ () => 'Temperature range' }
                  valueLabelDisplay='auto'
                  value={ foundedYearScale }
                  onChange={ handleFoundationYearScaleChange }
                  aria-labelledby='founded-year-slider'
                />
              </Box>
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <Box mt={ 2 }>
                <Typography id='total-funding-slider' gutterBottom>
                  Total funding
                </Typography>
                <Slider
                  disabled
                  getAriaLabel={ () => 'Temperature range' }
                  valueLabelDisplay='auto'
                  value={ foundedYearScale }
                  onChange={ handleFoundationYearScaleChange }
                  aria-labelledby='total-funding-slider'
                />
              </Box>
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <Box mt={ 2 }>
                <Typography id='evaluation-slider' gutterBottom>
                  Evaluation
                </Typography>
                <Slider
                  disabled
                  getAriaLabel={ () => 'Temperature range' }
                  valueLabelDisplay='auto'
                  value={ foundedYearScale }
                  onChange={ handleFoundationYearScaleChange }
                  aria-labelledby='evaluation-slider'
                />
              </Box>
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <FormControl style={ { marginTop: 5 } } fullWidth>
                <InputLabel id='select-helper-label'>Number of founders</InputLabel>

                <Select
                  disabled
                  labelId='select-helper-label'
                  id='select-helper'
                  name='number-of-founders'
                >
                  <MenuItem value=''>Reset</MenuItem>

                  { [0, 1, 2, 3, 4].map((noOfFounders, index) => <MenuItem key={ index } value={ noOfFounders }>{ noOfFounders }</MenuItem>) }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
      { isFetching ?
        <Container fixed>
          <CenterBox height='40vh'>
            <CircularSpinner size={ 150 } />
          </CenterBox>
        </Container>
        :
        errorMessage ?
          <Container fixed>
            <CenterBox height='40vh'>
              <div>{ errorMessage }</div>
            </CenterBox>
          </Container>
          :
          clusterData.length < 0 ?
            <Container fixed>
              <CenterBox height='40vh'>
                <div>No data found. Please revisit the filters.</div>
              </CenterBox>
            </Container>
            :
            <div>
              <SelectedCompanyBox companyData={ selectedMarkerData } visible={ showSelectedMarkerBox }></SelectedCompanyBox>
              <ScatterPlot clusters={ clusterData } filters={ filters } onMarkerHover={ data => handleOnMarkerHover(data) } onMarkerUnhover={ () => handleMarkerUnhover() } onMarkerClicked={ e => handleOnMarkerClicked(e) } />
              <SelectedCompanyModal data={ data } error={ error } loading={ loading } showDialog={ showSelectedMarkerModal } setOpenModal={ () => handleSetOpenModal() } ></SelectedCompanyModal>
            </div>
      }
    </Container>
  )
}

export default ClustersPage
