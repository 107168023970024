import React from 'react'
import { Box } from '@material-ui/core'

const CenterBox = props => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection={props.flexDirection}
      width={props.width}
      height={props.height}
      className={props.className}
    >
      {props.children}
    </Box>
  )
}

export default CenterBox
