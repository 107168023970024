import React from 'react'
import { useSelector } from 'react-redux'
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip as ReTooltip, ResponsiveContainer } from 'recharts'
import ErrorMessage from '../../Messages/ErrorMessage'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import CircularSpinner from '../Progress/CircularSpinner'

const LineChartStyled = ({
  title,
  data,
  error,
  xDataKey,
  dataKey,
  dataKeyTwo,
  dataKeyThree,
  lineOneName,
  lineTwoName,
  lineThreeName,
  width,
  height,
  YAxisTickCount,
  tickFormatter,
  tooltipFormatter,
  angle,
  textAnchor = 'middle',
  XAxisFontSize,
  legendPaddingTop,
  strokeDasharray = '2 2',
  strokeWidth,
  customDomain,
}) => {
  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  if (error) return <ErrorMessage errorMessage={ error } />

  return (
    <Box>
      <Card style={ { marginBottom: 40 } }>
        <CardContent>
          <Typography
            variant='h5'
            style={ { marginBottom: 20 } }
          >
            { title }
          </Typography>

          { data ?
            <ResponsiveContainer width={ width } height={ height }>
              <LineChart data={ data }
                margin={ { top: 5, right: 30, bottom: 5 } }>
                <CartesianGrid strokeDasharray={ strokeDasharray } opacity={ isDarkMode ? 0.7 : 1 } />

                <XAxis
                  dataKey={ xDataKey }
                  stroke={ isDarkMode ? '#bdbdbd' : '#686868' }
                  angle={ angle }
                  textAnchor={ textAnchor }
                  fontSize={ XAxisFontSize }
                  interval={ 0 }
                />

                <YAxis
                  domain={ customDomain }
                  tickCount={ YAxisTickCount }
                  tickFormatter={ tickFormatter }
                  stroke={ isDarkMode ? '#bdbdbd' : '#686868' }
                />

                <ReTooltip
                  formatter={ tooltipFormatter }
                  contentStyle={ {
                    backgroundColor: isDarkMode ? '#424242' : '#fff',
                    color: isDarkMode ? '#fff' : '#000',
                  } }
                  itemStyle={ { color: isDarkMode ? '#fff' : '#000' } }
                />

                <Legend
                  wrapperStyle={ {
                    paddingTop: legendPaddingTop,
                  } }
                />

                <Line name={ lineOneName } type='monotone' dataKey={ dataKey } stroke='#5c6bc0' strokeWidth={ strokeWidth } />
                { dataKeyTwo && <Line name={ lineTwoName } type='monotone' dataKey={ dataKeyTwo } stroke='#82ca9d' strokeWidth={ strokeWidth } /> }
                { dataKeyThree && <Line name={ lineThreeName } dataKey={ dataKeyThree } legendType='none' /> }
              </LineChart>
            </ResponsiveContainer>
            :
            <Box height={ height } width='100%' display='flex' alignItems='center' justifyContent='center'>
              <CircularSpinner size={ 150 } />
            </Box> }
        </CardContent>
      </Card>
    </Box>
  )
}

export default LineChartStyled
