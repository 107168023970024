import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import axios from '../../axios'

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [error, setError] = useState()

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        const token = await auth0FromHook.getTokenSilently()
        sessionStorage.setItem('token', token)
        axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`
        setUser(user)
      }

      setIsAuthenticated(isAuthenticated)
      setLoading(false)
    }

    initAuth0()
  }, [])

  const loginWithPopup = async () => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup()
    } catch (error) {
      setError(error.error_description)
    } finally {
      setPopupOpen(false)
    }

    const user = await auth0Client.getUser()
    const token = await auth0Client.getTokenSilently()
    sessionStorage.setItem('token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`

    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    const token = await auth0Client.getTokenSilently()
    axios.defaults.headers.common['Authorization'] = `Bearer ${ token }`
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }
  return (
    <Auth0Context.Provider
      value={ {
        isAuthenticated,
        user,
        error,
        setError,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      } }
    >
      { children }
    </Auth0Context.Provider>
  )
}
