import React from 'react'
import { Redirect } from 'react-router-dom'
// Material Ui
import { Grid, Box, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '../../hoc/Auth0/react-auth0-spa'
// Custom Ui
import BackgroundImage from './assets/background1.jpg'
import ValuerLogo from './assets/valuer-logo.png'
import GoogleLogo from './assets/google-logo.svg'
import axios from '../../axios'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${ BackgroundImage })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  errrorMessage: {
    marginTop: theme.spacing(3),
  },
  googleLogo: {
    marginRight: theme.spacing(1),
  },
}))

const Login = () => {
  const classes = useStyles()
  const { isAuthenticated, loginWithPopup, error, setError } = useAuth0()

  if (isAuthenticated) {
    return <Redirect to='/' />
  }

  const requestAccess = () => {
    axios
      .post('/request-access', {
        email: error.email,
      })
      .then(function (response) {
        setError({
          ...error,
          text: response.data.message,
        })
      })
      .catch(error => {
        setError({
          ...error,
          text: 'Too many requests, please try again later.',
        })
      })
  }

  return (
    <Grid container className={ classes.root }>
      <Grid item xs={ 12 } sm={ 8 } md={ 5 } className={ classes.loginSection }>
        <Box display='flex' flexDirection='column' height='100%' alignItems='center' justifyContent='center'>
          <img src={ ValuerLogo } height='150px' alt='Valuer logo' />

          <Typography className={ classes.marginBottom } variant='h5'>
            Welcome!
          </Typography>

          <Typography className={ classes.marginBottom } variant='body1'>
            Login to access Valuer's Database
          </Typography>

          { error && error.text === 'Account has no access.' ? (
            <Button color='secondary' variant='contained' onClick={ requestAccess }>
              Send request for access
            </Button>
          ) : (
            <Button color='secondary' variant='contained' onClick={ () => loginWithPopup({ prompt: 'select_account' }) }>
              <img height='22' width='22' src={ GoogleLogo } className={ classes.googleLogo } alt='Google logo' />
              Login With Google
            </Button>
          ) }

          <Typography className={ classes.errrorMessage } variant='h6' color='error'>
            { error ? error.text : null }
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={ 12 } sm={ 4 } md={ 7 } className={ classes.image } />
    </Grid>
  )
}

export default Login
