import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import { Colors } from '../../../Constants/SectorColors'

const useStyles = makeStyles(() => ({
  chip: {
    '& .MuiChip-root': {
      height: '20px',
    },
    '$ .MuiChip-deleteIcon': {
      margin: 0,
    },
  },
}))

const MultiSelectAutocomplete = props => {
  const classes = useStyles()

  const handleChipClick = (event, backwards = false) => {
    if (props.colorFeature) {
      event.preventDefault()

      if (!event.target.parentElement.classList.contains('MuiChip-root') || event.target.classList.contains('MuiSvgIcon-root')) return

      let currentOption = props.valuesWithColors.find(({ option }) => option === event.target.textContent)
      let colorIndex = Colors.indexOf(currentOption.color)
      let nextColorIndex = -1

      nextColorIndex = backwards ? colorIndex - 1 : colorIndex + 1

      if (nextColorIndex > Colors.length) nextColorIndex = 0
      if (backwards && nextColorIndex < 0) nextColorIndex = Colors.length - 1

      event.target.parentElement.style.backgroundColor = Colors[nextColorIndex]

      const prev = props.valuesWithColors

      const objToUpdate = prev.find(({ option }) => option === event.target.textContent)
      const indexOfUpdateObject = prev.indexOf(objToUpdate)
      const update = { ...prev[indexOfUpdateObject], color: Colors[nextColorIndex] }
      prev[indexOfUpdateObject] = update

      props.onColorChange(prev)
    }
  }

  return (
    <Autocomplete
      disabled={ props.disabled }
      multiple={ props.multiple }
      freeSolo={ props.freeSolo }
      clearOnBlur={ props.clearOnBlur }
      className={ classes.chip }
      options={ props.options }
      defaultValue={ props.defaultValue }
      value={ props.value }
      onChange={ (event, newValue) => {
        props.onChange(newValue)
      } }
      renderTags={ (tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            style={ props.colorFeature ? { backgroundColor: props.valuesWithColors.find(opt => opt.option === option).color } : {} }
            key={ index }
            label={ option }
            { ...getTagProps({ index }) }
            onClick={ handleChipClick }
            onContextMenu={ (e => handleChipClick(e, true)) }
          />
        ))
      }
      renderInput={ params =>
        <TextField
          { ...params }
          className={ classes.chip }
          variant='standard'
          label={ props.label }
          margin={ props.margin }
          fullWidth
          inputProps={ {
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          } }
        />
      }
      style={ props.style }
    />
  )
}

export default MultiSelectAutocomplete
