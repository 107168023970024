import React from 'react'
import { Box, Tooltip, makeStyles } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
  iconBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
}))

const TooltipLabel = ({ tooltipText, labelText }) => {
  const classes = useStyles()

  return (
    <Box>
      <Tooltip title={ tooltipText } placement='top-start' classes={ { tooltip: classes.tooltip } }>
        <Box component='span' className={ classes.iconBox }>
          { labelText }
          <HelpOutline style={ { paddingLeft: '5px' } } />
        </Box>
      </Tooltip>
    </Box >
  )
}

export default TooltipLabel
