import React from 'react'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import Pagination from 'rc-pagination'
import './Pagination.css'
import Select from 'rc-select'
import './Rc-select.css'
import CenterBox from '../../UI/CenterBox'
import { connect } from 'react-redux'
import * as paginationListActions from '../../../store/actions/pagination'

const PaginationWidget = ({ currentPage, pageSize, totalCount, setPage, setPageSize }) => {
  return (
    <CenterBox>
      <Pagination
        selectComponentClass={Select}
        current={currentPage}
        defaultPageSize={pageSize}
        total={totalCount}
        onChange={setPage}
        showQuickJumper
        showSizeChanger
        locale={localeInfo}
        pageSizeOptions={['25', '50', '100', '200']}
        onShowSizeChange={(current, next) => setPageSize(next)}
      />
    </CenterBox>
  )
}

const mapStateToProps = state => ({
  currentPage: state.pagination.currentPage,
  pageSize: state.pagination.pageSize,
  totalCount: state.pagination.totalCount,
})

const mapDispatchToProps = {
  setPage: paginationListActions.setPage,
  setPageSize: paginationListActions.setPageSize,
}

export default connect(mapStateToProps, mapDispatchToProps)(PaginationWidget)
