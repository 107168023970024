import {
  FETCH_CUSTOMER_START,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  CUSTOMER_RESET,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  customer: null,
  isFetching: false,
  errorMessage: '',
}

const customerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_CUSTOMER_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    case CUSTOMER_RESET:
      return {
        ...state,
        customer: null,
        isFetching: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default customerReducer
