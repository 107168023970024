import React from 'react'
import { Typography, Box } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const ErrorMessage = props =>
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    height='20rem'
    width='100%'
  >
    <Box>
      <ErrorOutlineIcon style={ { fontSize: '150px' } } />
    </Box>

    <Typography variant='h5'>{ props.errorMessage }</Typography>
  </Box>

export default ErrorMessage
