import {
  FETCH_CONFLICT_COMPANIES_START,
  FETCH_CONFLICT_COMPANIES_SUCCESS,
  FETCH_CONFLICT_COMPANIES_FAIL,
} from './actionTypes'

import axios from '../../axios'

export const fetchConflictCompaniesStart = () => ({
  type: FETCH_CONFLICT_COMPANIES_START,
})

export const fetchConflictCompaniesSuccess = conflictCompanies => ({
  type: FETCH_CONFLICT_COMPANIES_SUCCESS,
  payload: conflictCompanies,
})

export const fetchConflictCompaniesFail = errorMessage => ({
  type: FETCH_CONFLICT_COMPANIES_FAIL,
  payload: errorMessage,
})

export const fetchConflictCompanies = companyName => {
  return async dispatch => {
    try {
      dispatch(fetchConflictCompaniesStart())

      const { data } = await axios.get('/api/conflicts', { params: { companyName } })

      dispatch(fetchConflictCompaniesSuccess(data))
    } catch (error) {
      dispatch(fetchConflictCompaniesFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
