import React from 'react'
import { FormControl, MenuItem, Select, InputLabel } from '@material-ui/core'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'

const YearPicker = props => {
  const { user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority

  const currentYear = new Date().getFullYear()
  const allowedDateLength = userPriority >= 3 ? currentYear - new Date(1949) : 21

  let menuItems = [
    <MenuItem value={ '' } key={ 'Select Year' }>
      Select Year
    </MenuItem>,
  ]

  for (let index = new Date().getFullYear(); index > currentYear - allowedDateLength; index--) {
    menuItems.push(
      <MenuItem value={ index } key={ index }>
        { index }
      </MenuItem>,
    )
  }

  return (
    <FormControl margin='dense' fullWidth>
      <InputLabel>Founded Year</InputLabel>

      <Select name='founded_date' defaultValue={ props.year ? props.year : '' } value={ props.value } onChange={ props.onChange }>
        { menuItems }
      </Select>
    </FormControl>
  )
}

export default YearPicker
