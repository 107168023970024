import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import axios from '../../../../axios'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { VariableSizeList } from 'react-window'
import { useMediaQuery, ListSubheader, Typography } from '@material-ui/core/'

const LISTBOX_PADDING = 8 // px

function renderRow (props) {
  const { data, index, style } = props
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  })
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ ref } { ...props } { ...outerProps } />
})

const ListboxComponent = React.forwardRef(function ListboxComponent (props, ref) {
  const { children, ...other } = props
  const itemData = React.Children.toArray(children)
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = child => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  return (
    <div ref={ ref }>
      <OuterElementContext.Provider value={ other }>
        <VariableSizeList
          itemData={ itemData }
          height={ getHeight() + 2 * LISTBOX_PADDING }
          width='100%'
          key={ itemCount }
          outerElementType={ OuterElementType }
          innerElementType='ul'
          itemSize={ index => getChildSize(itemData[index]) }
          overscanCount={ 5 }
          itemCount={ itemCount }
        >
          { renderRow }
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  chip: {
    '& .MuiChip-root': {
      height: '20px',
    },
    '$ .MuiChip-deleteIcon': {
      margin: 0,
      backgroundColor: 'red',
    },
  },
}))

export default function SubSectorsAutocomplete (props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const loading = open && options.length === 0

  React.useEffect(() => {
    if (!loading) {
      return undefined
    }
    (async () => {
      const response = await axios.get('api/company/subsectors')
      const data = await response.data
      const subSectors = data.subsectors
      setOptions(subSectors)
    })()
  }, [loading])

  return (
    <Autocomplete
      disabled={ props.disabled }
      freeSolo
      multiple
      className={ classes.chip }
      open={ open }
      onOpen={ () => {
        setOpen(true)
      } }
      onClose={ () => {
        setOpen(false)
      } }
      defaultValue={ props.defaultValue }
      value={ props.value }
      getOptionLabel={ option => option }
      options={ options }
      loading={ loading }
      onChange={ (event, newValue) => {
        props.onChange(newValue)
      } }
      ListboxComponent={ ListboxComponent }
      renderOption={ option => <Typography noWrap>{ option }</Typography> }
      renderInput={ params => <TextField { ...params } label='Sub Industries' fullWidth variant='standard' margin='dense' /> }
    />
  )
}
