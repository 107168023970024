//  COMPANY ACTION TYPES
export const FETCH_COMPANY_START = 'FETCH_COMPANY_START'
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS'
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL'
export const SET_OPEN_COMPANY_MODAL = 'SET_OPEN_COMPANY_MODAL'
export const SET_OPEN_CONFLICT_MODAL = 'SET_OPEN_CONFLICT_MODAL'

//  COMPANY LIST ACTION TYPES
export const FETCH_COMPANY_LIST_START = 'FETCH_COMPANY_LIST_START'
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS'
export const FETCH_COMPANY_LIST_FAIL = 'FETCH_COMPANY_LIST_FAIL'
export const DELETE_COMPANY_LIST_SUCCESS = 'DELETE_COMPANY_LIST_SUCCESS'
export const UPDATE_COMPANY_LIST_SUCCESS = 'UPDATE_COMPANY_LIST_SUCCESS'
export const SELECT_COMPANY = 'SELECT_COMPANY'
export const SELECT_ALL_COMPANIES = 'SELECT_ALL_COMPANIES'

// COMPANIES BY COUNTRY ACTION TYPES
export const FETCH_COMPANIES_BY_COUNTRY_START = 'FETCH_COMPANIES_BY_COUNTRY_START'
export const FETCH_COMPANIES_BY_COUNTRY_SUCCESS = 'FETCH_COMPANIES_BY_COUNTRY_SUCCESS'
export const FETCH_COMPANIES_BY_COUNTRY_FAIL = 'FETCH_COMPANIES_BY_COUNTRY_FAIL'

//  DASHBOARD STATS ACTION TYPES
export const FETCH_DASHBOARD_STATS_START = 'FETCH_DASHBOARD_STATS_START'
export const FETCH_DASHBOARD_STATS_SUCCESS = 'FETCH_DASHBOARD_STATS_SUCCESS'
export const FETCH_DASHBOARD_STATS_FAIL = 'FETCH_DASHBOARD_STATS_FAIL'

//  SCORES_STATS ACTION TYPES
export const FETCH_SCORES_STATS_START = 'FETCH_SCORES_STATS_START'
export const FETCH_SCORES_STATS_SUCCESS = 'FETCH_SCORES_STATS_SUCCESS'
export const FETCH_SCORES_STATS_FAIL = 'FETCH_SCORES_STATS_FAIL'

//  SCORES STATS FOR HISTOGRAMS ACTION TYPES
export const FETCH_SCORES_STATS_HISTOGRAMS_START = 'FETCH_SCORES_STATS_HISTOGRAMS_START'
export const FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS = 'FETCH_SCORES_STATS_HISTOGRAMS_SUCCESS'
export const FETCH_SCORES_STATS_HISTOGRAMS_FAIL = 'FETCH_SCORES_STATS_HISTOGRAMS_FAIL'

//  SCORES_STATS ACTION TYPES
export const FETCH_HISTORIC_SCORES_STATS_START = 'FETCH_HISTORIC_SCORES_STATS_START'
export const FETCH_HISTORIC_SCORES_STATS_SUCCESS = 'FETCH_HISTORIC_SCORES_STATS_SUCCESS'
export const FETCH_HISTORIC_SCORES_STATS_FAIL = 'FETCH_HISTORIC_SCORES_STATS_FAIL'

//  PAGINATION ACTION TYPES
export const SET_SELECTED_FILTER = 'SET_SELECTED_FILTER'
export const SET_FILTERS = 'SET_FILTERS'
export const RESET_PAGINATION = 'RESET_PAGINATION'
export const SET_PAGINATION_PAGE = 'SET_PAGINATION_PAGE'
export const SET_PAGINATION_PAGE_SIZE = 'SET_PAGINATION_PAGE_SIZE'
export const SET_PAGINATION_QUERY_PARAMS = 'SET_PAGINATION_QUERY_PARAMS'
export const SET_PAGINATION_TOTAL_COUNT = 'SET_PAGINATION_TOTAL_COUNT'

// THEME ACTION TYPES
export const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE'

// COLLECTION ACTION TYPES
export const FETCH_COLLECTION_NAMES_START = 'FETCH_COLLECTION_NAMES_START'
export const FETCH_COLLECTION_NAMES_SUCCESS = 'FETCH_COLLECTION_NAMES_SUCCESS'
export const FETCH_COLLECTION_NAMES_FAIL = 'FETCH_COLLECTION_NAMES_FAIL'

// SOURCES ACTION TYPES
export const FETCH_SOURCES_START = 'FETCH_SOURCES_START'
export const FETCH_SOURCES_SUCCESS = 'FETCH_SOURCES_SUCCESS'
export const FETCH_SOURCES_FAIL = 'FETCH_SOURCES_FAIL'

export const SET_COLLECTION_TO_USE = 'SET_COLLECTION_TO_USE'

// CONFLICTS ACTION TYPES
export const FETCH_CONFLICT_COMPANIES_START = 'FETCH_CONFLICT_COMPANIES_START'
export const FETCH_CONFLICT_COMPANIES_SUCCESS = 'FETCH_CONFLICT_COMPANIES_SUCCESS'
export const FETCH_CONFLICT_COMPANIES_FAIL = 'FETCH_CONFLICT_COMPANIES_FAIL'

// LOGS ACTION TYPES
export const FETCH_LOGS_START = 'FETCH_LOGS_START'
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS'
export const FETCH_LOGS_FAIL = 'FETCH_LOGS_FAIL'

export const FETCH_UNIQUE_ENDPOINTS_START = 'FETCH_UNIQUE_ENDPOINTS_START'
export const FETCH_UNIQUE_ENDPOINTS_SUCCESS = 'FETCH_UNIQUE_ENDPOINTS_SUCCESS'
export const FETCH_UNIQUE_ENDPOINTS_FAIL = 'FETCH_UNIQUE_ENDPOINTS_FAIL'

// EVENT (LOGS) NOTIFCATION ACTION TYPES
export const FETCH_EVENT_NOTIFICATIONS_START = 'FETCH_EVENT_NOTIFICATION_START'
export const FETCH_EVENT_NOTIFICATIONS_SUCCESS = 'FETCH_EVENT_NOTIFICATION_SUCCESS'
export const FETCH_EVENT_NOTIFICATIONS_FAIL = 'FETCH_EVENT_NOTIFICATION_FAIL'

export const ADD_RECIPIENT_TO_NOTIFICATION_START = 'ADD_RECIPIENT_TO_NOTIFICATION_START'
export const ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS = 'ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS'
export const ADD_RECIPIENT_TO_NOTIFICATION_FAIL = 'ADD_RECIPIENT_TO_NOTIFICATION_FAIL'

export const REMOVE_RECIPIENT_FROM_NOTIFICATION_START = 'REMOVE_RECIPIENT_FROM_NOTIFICATION_START'
export const REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS = 'REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS'
export const REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL = 'REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL'

export const REGISTER_EVENT_NOTIFICATION_START = 'REGISTER_EVENT_NOTIFICATION_START'
export const REGISTER_EVENT_NOTIFICATION_SUCCESS = 'REGISTER_EVENT_NOTIFICATION_SUCCESS'
export const REGISTER_EVENT_NOTIFICATION_FAIL = 'REGISTER_EVENT_NOTIFICATION_FAIL'

export const REMOVE_EVENT_NOTIFICATION_START = 'REMOVE_EVENT_NOTIFICATION_START'
export const REMOVE_EVENT_NOTIFICATION_SUCCESS = 'REMOVE_EVENT_NOTIFICATION_SUCCESS'
export const REMOVE_EVENT_NOTIFICATION_FAIL = 'REMOVE_EVENT_NOTIFICATION_FAIL'

export const SUBSCRIBE_ALL_EVENT_NOTIFICATION_START = 'SUBSCRIBE_ALL_NOTIFICATION_START'
export const SUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS = 'SUBSCRIBE_ALL_NOTIFICATION_SUCCESS'
export const SUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL = 'SUBSCRIBE_ALL_NOTIFICATION_FAIL'

export const UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_START = 'UNSUBSCRIBE_ALL_NOTIFICATION_START'
export const UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS = 'UNSUBSCRIBE_ALL_NOTIFICATION_SUCCESS'
export const UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL = 'UNSUBSCRIBE_ALL_NOTIFICATION_FAIL'

// CUSTOMER ACTION TYPES
export const FETCH_CUSTOMER_START = 'FETCH_CUSTOMER_START'
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL'
export const CUSTOMER_RESET = 'CUSTOMER_RESET'

// FOUNDER ACTION TYPES
export const FETCH_FOUNDER_START = 'FETCH_FOUNDER_START'
export const FETCH_FOUNDER_SUCCESS = 'FETCH_FOUNDER_SUCCESS'
export const FETCH_FOUNDER_FAIL = 'FETCH_FOUNDER_FAIL'
export const FOUNDER_RESET = 'FOUNDER_RESET'

// ALIAS CONFLICT ACTION TYPES
export const FETCH_ALIAS_CONFLICT_START = 'FETCH_ALIAS_CONFLICT_START'
export const FETCH_ALIAS_CONFLICT_SUCCESS = 'FETCH_ALIAS_CONFLICT_SUCCESS'
export const FETCH_ALIAS_CONFLICT_FAIL = 'FETCH_ALIAS_CONFLICT_FAIL'

// CLUSTER DATA ACTION TYPES
export const FETCH_CLUSTER_DATA_START = 'FETCH_CLUSTER_DATA_START'
export const FETCH_CLUSTER_DATA_SUCCESS = 'FETCH_CLUSTER_DATA_SUCCESS'
export const FETCH_CLUSTER_DATA_FAIL = 'FETCH_CLUSTER_DATA_FAIL'

// MONTHLY ENTRIES ACTION TYPES
export const FETCH_MONTHLY_ENTRIES_START = 'FETCH_MONTHLY_ENTRIES_START'
export const FETCH_MONTHLY_ENTRIES_SUCCESS = 'FETCH_MONTHLY_ENTRIES_SUCCESS'
export const FETCH_MONTHLY_ENTRIES_FAIL = 'FETCH_MONTHLY_ENTRIESFAILT'

// MONTHLY DATA POINTS ACTION TYPES
export const FETCH_MONTHLY_DATA_POINTS_START = 'FETCH_MONTHLY_DATA_POINTS_START'
export const FETCH_MONTHLY_DATA_POINTS_SUCCESS = 'FETCH_MONTHLY_DATA_POINTS_SUCCESS'
export const FETCH_MONTHLY_DATA_POINTS_FAIL = 'FETCH_MONTHLY_DATA_POINTSFAILT'

// ACTION TYPES FOR GETTING THE NUMBER COMPANIES CREATED AND UPDATED DAILY
export const FETCH_COMPANIES_CREATED_UPDATED_START = 'FETCH_COMPANIES_CREATED_UPDATED_START'
export const FETCH_COMPANIES_CREATED_UPDATED_SUCCESS = 'FETCH_COMPANIES_CREATED_UPDATED_SUCCESS'
export const FETCH_COMPANIES_CREATED_UPDATED_FAIL = 'FETCH_COMPANIES_CREATED_UPDATEDFAILT'

// ACTION TYPES FOR GETTING THE DESCRIPTION AND DESCRIPTION PROCESSED COUNTS HISTORIC
export const FETCH_DESCRIPTIONS_COUNTS_START = 'FETCH_DESCRIPTIONS_COUNTS_START'
export const FETCH_DESCRIPTIONS_COUNTS_SUCCESS = 'FETCH_DESCRIPTIONS_COUNTS_SUCCESS'
export const FETCH_DESCRIPTIONS_COUNTS_FAIL = 'FETCH_DESCRIPTIONS_COUNTSFAILT'

// ACTION TYPES FOR GETTING THE MONTHLY FUNDING COUNTS
export const FETCH_MONTHLY_FUNDING_COUNTS_START = 'FETCH_MONTHLY_FUNDING_COUNTS_START'
export const FETCH_MONTHLY_FUNDING_COUNTS_SUCCESS = 'FETCH_MONTHLY_FUNDING_COUNTS_SUCCESS'
export const FETCH_MONTHLY_FUNDING_COUNTS_FAIL = 'FETCH_MONTHLY_FUNDING_COUNTS_FAIL'
