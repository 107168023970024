import React, { useState } from 'react'
import { Box, Tabs, Tab, Typography, Container } from '@material-ui/core'
import CenterBox from '../../../components/UI/CenterBox'
import CircularSpinner from '../../../components/UI/Progress/CircularSpinner'
import Modal from '../../../components/UI/Modal'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={ value !== index }
      id={ `full-width-tabpanel-${ index }` }
      aria-labelledby={ `full-width-tab-${ index }` }
      { ...other }
    >
      { value === index && (
        <Box sx={ { p: 3 } }>
          { children }
        </Box>
      ) }
    </div>
  )
}

const SelectedCompanyModal = ({ data, loading, error, showDialog, setOpenModal }) => {

  const [tab, setTab] = useState(0)

  return (
    <Modal title={ data.name } openModal={ showDialog } setOpenModal={ setOpenModal }>
      <Box display='flex' justifyContent='center'>
        <Tabs orientation='horizontal' variant='scrollable' value={ tab } onChange={ (e, tab) => setTab(tab) } aria-label='simple tabs example'>
          <Tab label='About' />
          <Tab label='Contact Details' />
          <Tab label='Funding' />
        </Tabs>
      </Box>
      <Box marginTop={ 4 }>
        { loading ?
          <Container fixed>
            <CenterBox height='40vh'>
              <CircularSpinner size={ 150 } />
            </CenterBox>
          </Container>
          :
          error ?
            <Container fixed>
              <CenterBox height='40vh'>
                <div>{ error }</div>
              </CenterBox>
            </Container>
            :
            <div>
              <TabPanel value={ tab } index={ 0 }>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Sectors: </b> { data.sectors ? data.sectors.join() : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Subsectors: </b> { data.subsectors ? data.subsectors.join() : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Contact email: </b> { data.contact_email ? data.contact_email : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Website: </b> { data.website } </Typography>
                </Box>
                <Box display='flex' alignContent='center' alignItems='center' justifyContent='start' textAlign='center' padding={ 1 }>
                  <Typography variant='body1' > <b>HQ Area: </b> { data.hq_area ? data.hq_area : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>HQ City: </b> { data.hq_city ? data.hq_city : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Number of employees: </b> { data.number_of_employees ? data.number_of_employees : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Description: </b> { data.description ? data.description : '-' } </Typography>
                </Box>
              </TabPanel>
              <TabPanel value={ tab } index={ 1 }>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Contact email: </b> { data.contact_email ? data.contact_email : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Contact phone number: </b> { data.contact_phone_number ? data.contact_phone_number : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Twitter: </b> { data.twitter ? data.twitter : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Facebook: </b> { data.facebook ? data.facebook : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>LinkedIn: </b> { data.likedin ? data.likedin : '-' } </Typography>
                </Box>
              </TabPanel>
              <TabPanel value={ tab } index={ 2 }>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Founded year: </b> { data.founded_date ? data.founded_date : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Investment stage: </b> { data.investment_stage ? data.investment_stage : '-' } </Typography>
                </Box>
                <Box display='flex' justifyContent='start' padding={ 1 }>
                  <Typography variant='body1' > <b>Founder names: </b> { data.founder_names ? data.founder_names : '-' } </Typography>
                </Box>
              </TabPanel>
            </div> }
      </Box>
    </Modal>
  )
}

export default SelectedCompanyModal
