import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumb from '../../../components/Navigation/BreadCrumbs/BreadCrumbs'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid } from '@material-ui/core'
import CircularSpinner from '../../../components/UI/Progress/CircularSpinner'
import CenterBox from '../../../components/UI/CenterBox'
import { useSnackbar } from 'notistack'
import { fetchScoresStats } from '../../../store/actions/scoresStats'
import { fetchScoresStatsHistograms } from '../../../store/actions/scoresStatsHistograms'
import { fetchHistoricScoresStats } from '../../../store/actions/historicScoresStats'
import { fetchMonthlyFundingCounts } from '../../../store/actions/monthlyFundingCounts'
import ErrorMessage from '../../../components/Messages/ErrorMessage'
import ScorePanel from './scorePanel'
import HistogramChart from './histogramChart'
import HistoricChart from './historicStatChart'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  scoresGrid: {
    maxWidth: 800,
    margin: '20px auto',
  },
  arrow: {
    color: '#4d4d4d',
  },
  tooltip: {
    fontSize: '1em',
    backgroundColor: '#4d4d4d',
  },
}))

const ScoresOverview = () => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const scoresStatistics = useSelector(state => state.scoresStatistics)
  const { scoresStats, isFetching, errorMessage } = scoresStatistics

  const scoresStatisticsHistograms = useSelector(state => state.scoresStatisticsHistograms)
  const { scoresStatsHistograms, isFetching: isFetchingHistograms, errorMessage: errorMessageHistograms } = scoresStatisticsHistograms

  const historicScoresStatistics = useSelector(state => state.historicScoresStatistics)
  const { historicScoresStats, isFetching: isFetchingHistoric, errorMessage: errorMessageHistoric } = historicScoresStatistics

  const monthlyFundingCounts = useSelector(state => state.monthlyFundingCounts)
  const { data: monthlyFundingData, isFetching: isFetchingFunding, errorMessage: errorMessageFunding } = monthlyFundingCounts

  const getMin = () => monthlyFundingData.reduce((prev, curr) => prev.fundedCompaniesPercentage < curr.fundedCompaniesPercentage ? prev : curr).fundedCompaniesPercentage
  const getMax = () => monthlyFundingData.reduce((prev, curr) => prev.fundedCompaniesPercentage < curr.fundedCompaniesPercentage ? curr : prev).fundedCompaniesPercentage
  const getCurrent = () => monthlyFundingData[monthlyFundingData.length - 1].frontendReadyFundedCompaniesPercentage

  useEffect(() => {
    dispatch(fetchScoresStats())
    dispatch(fetchHistoricScoresStats())
    dispatch(fetchScoresStatsHistograms())
    dispatch(fetchMonthlyFundingCounts())
  }, [enqueueSnackbar, dispatch])

  return (
    <Fragment>
      <BreadCrumb />

      <Container className={ classes.root }>
        <Grid container spacing={ 3 }>
          { isFetching ?
            <Container fixed>
              <CenterBox height={ 140 }>
                <CircularSpinner size={ 100 } />
              </CenterBox>
            </Container>
            :
            errorMessage ? <ErrorMessage errorMessage={ errorMessage } /> :
              <Grid className={ classes.scoresGrid } container spacing={ 3 }>
                { scoresStats.map((score, index) => <ScorePanel key={ index } min={ score.min } max={ score.max } valueLabel={ 'Avg' } value={ score.avg } panelLabel={ score.scoreName }></ScorePanel>) }
                {
                  isFetchingFunding ?
                    <Container fixed>
                      <CenterBox height={ 140 }>
                        <CircularSpinner size={ 100 } />
                      </CenterBox>
                    </Container>
                    :
                    errorMessageFunding ? <ErrorMessage errorMessage={ errorMessageFunding } /> :
                      <ScorePanel panelLabel={ 'Funding' } min={ getMin() } max={ getMax() } valueLabel={ 'FR ready' } value={ getCurrent() }></ScorePanel>
                }
              </Grid> }

          <Grid item xs={ 6 }>
            { isFetchingHistoric ?
              <Container fixed>
                <CenterBox height={ 140 }>
                  <CircularSpinner size={ 100 } />
                </CenterBox>
              </Container>
              :
              errorMessageHistoric ? <ErrorMessage errorMessage={ errorMessageHistoric } /> :
                historicScoresStats.map((historicScore, index) =>
                  <HistoricChart
                    key={ index }
                    title={ Object.keys(historicScore)[0].replaceAll('_', ' ') }
                    data={ historicScore[Object.keys(historicScore)[0]] }
                    xDataKey='date'
                    lines={ [{ dataKey: 'avg', stroke: '#5c6bc0', strokeWidth: 4 }, { dataKey: 'max', stroke: '#24c361' }, { dataKey: 'min', stroke: '#f37878' }] }
                  ></HistoricChart>,
                ) }
            <HistoricChart
              title={ 'Startups With Total Funding' }
              data={ monthlyFundingData }
              xDataKey='date'
              lines={ [
                { dataKey: 'fundedCompaniesPercentage', stroke: '#5c6bc0', strokeWidth: 4, name: '% with funding' },
                { dataKey: 'frontendReadyFundedCompaniesPercentage', stroke: '#24c361', strokeWidth: 4, name: '% ready with funding' },
              ] }
            ></HistoricChart>
          </Grid>

          <Grid item xs={ 6 }>
            { isFetchingHistograms ?
              <Container fixed>
                <CenterBox height={ 140 }>
                  <CircularSpinner size={ 100 } />
                </CenterBox>
              </Container>
              :
              errorMessageHistograms ?
                <ErrorMessage errorMessage={ errorMessageHistograms } /> :
                scoresStatsHistograms.map((score, index) => <HistogramChart key={ index } title={ `${ Object.keys(score)[0].replaceAll('_', ' ') }` } xDataKey={ 'range' } yDataKey={ 'count' } data={ score[Object.keys(score)[0]] }></HistogramChart>) }
          </Grid>

        </Grid>
      </Container >
    </Fragment >
  )
}

export default ScoresOverview
