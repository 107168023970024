import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  Typography: {
    fontWeight: '700',
  },
}))

const LinkRouter = props => <Link { ...props } component={ RouterLink } />

const breadcrumbNameMap = {
  '/dashboard': 'Dashboard',
  '/database/search': 'Search Database',
  '/database/add-company': 'Add New Company',
  '/database/conflicts': 'Conflicts',
  '/database/logs': 'Logs',
  '/database/logs/notifications': 'Notifications',
  '/database': 'Database',
  '/statistics': 'Statistics',
  '/statistics/overview-internal': 'Overview Internal',
  '/statistics/overview-external': 'Overview External',
  '/statistics/scores-overview': 'Scores Overview',
  '/statistics/kpis': 'KPIs',
  '/statistics/clusters': 'Clusters',
  '/profile': 'Profile',
}

const SimpleBreadcrumbs = props => {
  const classes = useStyles()
  const pathnames = props.location.pathname.split('/').filter(x => x)

  return (
    <div className={ classes.root }>
      <Breadcrumbs aria-label='breadcrumb'>
        <LinkRouter color='inherit' to='/'>
          Home
        </LinkRouter>

        { pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${ pathnames.slice(0, index + 1).join('/') }`

          return last ? null : (
            <LinkRouter color='inherit' to={ to } key={ to }>
              { breadcrumbNameMap[to] }
            </LinkRouter>
          )
        }) }

      </Breadcrumbs>

      <Typography className={ classes.Typography } variant='h4' component='h4' gutterBottom>
        { breadcrumbNameMap[props.location.pathname] }
      </Typography>
    </div>
  )
}

export default withRouter(SimpleBreadcrumbs)
