import {
  FETCH_HISTORIC_SCORES_STATS_START,
  FETCH_HISTORIC_SCORES_STATS_SUCCESS,
  FETCH_HISTORIC_SCORES_STATS_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  historicScoresStats: null,
  isFetching: true,
  errorMessage: '',
}

const historicScoresStatsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_HISTORIC_SCORES_STATS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_HISTORIC_SCORES_STATS_SUCCESS:
      return {
        ...state,
        historicScoresStats: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_HISTORIC_SCORES_STATS_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default historicScoresStatsReducer
