import * as actionTypes from '../actions/actionTypes'
import { grey, red, indigo } from '@material-ui/core/colors'

const initalState = {
  isDarkMode: false,
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: indigo[500],
    },
    type: 'light',
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
}

// eslint-disable-next-line no-unused-vars
const toggleDarkMode = (state, action) => {
  const isDarkMode = !state.isDarkMode

  localStorage.setItem('darkMode', JSON.stringify(isDarkMode))

  const palette = {
    ...state.palette,
    type: state.palette.type === 'light' ? 'dark' : 'light',
    primary: {
      main: state.palette.type === 'light' ? grey[100] : grey[900],
    },
    secondary: {
      main: state.palette.type === 'light' ? indigo[400] : indigo[500],
    },
  }

  return { ...state, isDarkMode, palette }
}

const reducer = (
  state = initalState,
  action,
) => {
  switch (action.type) {
    case actionTypes.TOGGLE_DARKMODE:
      return toggleDarkMode(state, action)
    default:
      return state
  }
}

export default reducer
