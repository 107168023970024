import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as Beta } from './beta.svg'

const BetaIcon = ({ verticalAlign, viewBox = '0 0 148.411 60' }) =>
  <SvgIcon viewBox={ viewBox } style={ { width: 70, verticalAlign } }>
    <Beta />
  </SvgIcon>

export default BetaIcon
