import React, { useEffect, useState, createRef, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumb from '../components/Navigation/BreadCrumbs/BreadCrumbs'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Card, Typography, Grid, Tooltip, Link, Button } from '@material-ui/core'
import CircularSpinner from '../components/UI/Progress/CircularSpinner'
import CenterBox from '../components/UI/CenterBox'
import { fetchConflictCompanies } from '../store/actions/conflictCompaniesActions'
import { updateCompany } from '../store/actions/company'
import { useSnackbar } from 'notistack'
import axios from '../axios'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    paddingBottom: theme.spacing(4),
  },
  card: {
    minHeight: 275,
    marginBottom: '50px',
    padding: '20px',
    margin: 'auto',
  },
  innerCard: {
    minHeight: 150,
    width: 500,
    cursor: 'pointer',
    textAlign: 'center',
    padding: 22,
    paddingBottom: 15,
    fontSize: '1.1rem',
    boxShadow: '1px 5px 20px #888888',
    '&:hover': {
      transitionDuration: '0.25s',
      backgroundColor: theme.isDarkMode ? '#363636' : '#b0b0b0',
    },
  },
  outerCard: {
    padding: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '2.5rem',
  },
}))

const ConflictsPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const dispatch = useDispatch()

  const conflictCompanies = useSelector(state => state.conflictCompanies)
  const { conflict, isFetching, errorMessage } = conflictCompanies

  const [databaseStatus, setDatabaseStatus] = useState('unlocked')

  const gridRef = useRef()
  const containerRefObj = {}

  const handleSkipClick = () => dispatch(fetchConflictCompanies())

  useEffect(() => {
    dispatch(fetchConflictCompanies())

    const getDatabaseStatus = async () => {
      const { data } = await axios.get('/api/get-database-status-frontend')
      setDatabaseStatus(data.databaseStatus)
    }

    getDatabaseStatus()

  }, [])

  return (
    <Container className={ classes.root }>
      <BreadCrumb />

      <div style={ { position: 'relative', top: -55, textAlign: 'right' } }>
        { (!isFetching && !errorMessage) && <Typography display='inline' style={ { marginRight: 20 } }>
          Companies with conflicts: { conflict.totalConflicts }</Typography> }

        <Button variant='contained' color='secondary' onClick={ handleSkipClick }>Skip company</Button>
      </div>

      { isFetching ?
        <Container fixed>
          <CenterBox height='40vh'>
            <CircularSpinner size={ 150 } />
          </CenterBox>
        </Container>
        :
        errorMessage ? <h2>{ errorMessage }</h2> :
          <Card className={ classes.card } elevation={ 3 }>
            <Link href={ conflict.conflictData.website } target='_blank' rel='noopener noreferrer'>
              <Typography variant='h5' align='center'>{ conflict.conflictData.name }</Typography>
            </Link>

            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 } ref={ gridRef }>
                { conflict.conflictData.fieldDifferences.map((field, index) => {
                  containerRefObj[index] = createRef()

                  return <Container key={ index } ref={ containerRefObj[index] }>
                    <Typography variant='h6' align='center'>{ field.path[0] }</Typography>

                    <Card xs={ 6 } className={ classes.outerCard } elevation={ 5 }>
                      <div
                        className={ classes.innerCard }
                        data-existing={ field.lhs }
                        onClick={ e => {
                          dispatch(updateCompany({
                            _id: conflict.conflictData.companyId,
                            name: conflict.conflictData.name,
                            [field.path[0]]: e.target.getAttribute('data-existing'),
                          }, null, 'companies', enqueueSnackbar, true, databaseStatus !== 'locked', conflict.conflictData.fieldDifferences.length - 1))

                          if (databaseStatus === 'locked') {
                            gridRef.current.children.length === 1 && dispatch(fetchConflictCompanies())
                            containerRefObj[index].current.remove()
                          }
                        } }
                        style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }
                      >
                        <div data-existing={ field.lhs } style={ { wordWrap: 'break-word' } }>
                          <b>Existing</b> <br /> { Array.isArray(field.lhs) ? `[ ${ field.lhs.join(', ') } ]` : typeof field.lhs === 'number' && field.path[0] !== 'founded_date' ? field.lhs.toLocaleString() : field.lhs }
                        </div>

                        <Tooltip title='Priority' placement='right' arrow style={ { marginLeft: '98%' } }>
                          <Typography>{ field.existingPriority }</Typography>
                        </Tooltip>
                      </div>

                      <div
                        className={ classes.innerCard }
                        data-incoming={ field.rhs }
                        onClick={ e => {
                          dispatch(updateCompany({
                            _id: conflict.conflictData.companyId,
                            name: conflict.conflictData.name,
                            [field.path[0]]: e.target.getAttribute('data-incoming'),
                          }, null, 'companies', enqueueSnackbar, true, databaseStatus !== 'locked', conflict.conflictData.fieldDifferences.length - 1))

                          if (databaseStatus === 'locked') {
                            gridRef.current.children.length === 1 && dispatch(fetchConflictCompanies())
                            containerRefObj[index].current.remove()
                          }
                        } }
                        style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }
                      >
                        <div data-incoming={ field.rhs } style={ { wordWrap: 'break-word' } }>
                          <b>Incoming</b> <br /> { Array.isArray(field.rhs) ? `[ ${ field.rhs.join(', ') } ]` : typeof field.rhs === 'number' && field.path[0] !== 'founded_date' ? field.rhs.toLocaleString() : field.rhs }
                        </div>

                        <Tooltip title='Priority' placement='right' arrow style={ { marginLeft: '98%' } }>
                          <Typography>{ field.incomingPriority }</Typography>
                        </Tooltip>
                      </div>
                    </Card>
                  </Container>
                },
                ) }
              </Grid>
            </Grid>
          </Card>
      }
    </Container >
  )
}

export default ConflictsPage
