import React from 'react'
import { Grid, TextField, Typography, Tooltip, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '.9em',
    maxWidth: 500,
  },
}))

export default function AdditionalInformationForm ({ data, onInputChange, userPriority }) {
  const classes = useStyles()

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        <Tooltip title='Valuer Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Valuer Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='valuer_score'
          defaultValue={ data.valuer_score && data.valuer_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33, marginBottom: '3px' } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Completeness Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Completeness Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='completeness_score'
          defaultValue={ data.completeness_score && data.completeness_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Success Potential Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Success Potential Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='success_potential_score'
          defaultValue={ data.success_potential_score && data.success_potential_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Growth Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Growth Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='growth_score'
          defaultValue={ data.growth_score && data.growth_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off' inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Maturity Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Maturity Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='maturity_score'
          defaultValue={ data.maturity_score && data.maturity_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Innovation Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Innovation Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='innovation_score'
          defaultValue={ data.innovation_score && data.innovation_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Sustainability Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Sustainability Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='sustainability_score'
          defaultValue={ data.sustainability_score && data.sustainability_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Tooltip title='Description Quality Score is required. It will be automatically generated' placement='top-start' classes={ { tooltip: classes.tooltip } }>
          <Typography
            style={ { display: 'inline', verticalAlign: 'bottom' } }
          >
            <b>Description Quality Score <Box component='span' style={ { color: 'red' } }>*</Box> : </b>
          </Typography>
        </Tooltip>

        <TextField
          name='description_quality_score'
          defaultValue={ data.description_quality_score && data.description_quality_score.toString() }
          disabled={ userPriority < 3 }
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
          inputProps={ { maxLength: 4, style: { padding: '0 0 1px 0' } } }
          style={ { width: 33 } }
        />
      </Grid>
    </Grid>
  )
}
