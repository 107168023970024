import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'

export default function ContactDetailsForm ({ data, onInputChange, allowEdit }) {
  if (!allowEdit) {
    return <ReadOnlyFrom data={ data } />
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 }>
        <TextField
          onChange={ onInputChange }
          label='Contact Email'
          name='contact_email'
          defaultValue={ data.contact_email }
          fullWidth
          margin='dense'
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <TextField
          onChange={ onInputChange }
          label='Phone Number'
          name='contact_phone_number'
          defaultValue={ data.contact_phone_number }
          fullWidth
          margin='dense'
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <TextField
          onChange={ onInputChange }
          label='Facebook'
          name='facebook'
          defaultValue={ data.facebook }
          fullWidth
          margin='dense'
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <TextField
          onChange={ onInputChange }
          label='Twitter'
          name='twitter'
          defaultValue={ data.twitter }
          fullWidth
          margin='dense'
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <TextField
          onChange={ onInputChange }
          label='LinkedIn'
          name='linkedin'
          defaultValue={ data.linkedin }
          fullWidth
          margin='dense'
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>
    </Grid>
  )
}

function ReadOnlyFrom ({ data }) {
  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Contact email: </b> { data.contact_email }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Phone number: </b> { data.contact_phone_number }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Facebook: </b> { data.facebook }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Twitter: </b> { data.twitter }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Linkedin: </b> { data.linkedin }
        </Typography>
      </Grid>
    </Grid>
  )
}
