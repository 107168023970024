import React, { useRef, memo } from 'react'
import { useSelector } from 'react-redux'
import Plotly from 'plotly.js-gl3d-dist-min'
import createPlotlyComponent from 'react-plotly.js/factory'
const Plot = createPlotlyComponent(Plotly)
import { SectorColors } from '../../../Constants/SectorColors'
import IconsSVG from '../../../utils/Icons/IconsSVG'
import Numeral from 'numeral'
import { isEqual } from 'lodash'

const ScatterPlot = memo(({ clusters, filters, onMarkerHover, onMarkerUnhover, onMarkerClicked }) => {
  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const displayingAxes = useRef(true)
  let autoRotate = useRef(false)
  let theGraphDiv = useRef(null)
  let myRequestAnimationFrame = useRef(null)

  const cleanClustersData = clusters.filter(c => c.projection)

  const median = numbers => {
    const sorted = numbers.sort((a, b) => a - b)
    const middle = Math.floor(sorted.length / 2)

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2
    }

    return sorted[middle]
  }

  const appendPlotArrayData = () => {
    let plotDataArray = []
    let filteredSectors = []

    if (filters.sectors.length > 0) {
      let originalClusterData = cleanClustersData

      filters.sectors.forEach(sector => {
        let sectorData = originalClusterData.filter(company => company.sectors.includes(sector))

        originalClusterData = originalClusterData.filter(company => !sectorData.includes(company))

        filteredSectors.push({ sector, sectorData, inputColor: true })
      })

    } else {
      const sectorsArr = cleanClustersData.map(cluster => cluster.sectors)
      const unqiueSectors = [...new Set(sectorsArr.flat(1))]

      unqiueSectors.forEach(sector => {
        let sectorData = cleanClustersData.filter(company => company.sectors[0] === sector)

        filteredSectors.push({ sector, sectorData, inputColor: false })
      })
    }
    filteredSectors.forEach(data => {
      if (data.sectorData.length > 0) {
        plotDataArray.push({
          x: data.sectorData.map(company => company.projection[0]),
          y: data.sectorData.map(company => company.projection[1]),
          z: data.sectorData.map(company => company.projection[2]),
          mode: 'markers',
          marker: {
            size: 5,
            color: data.inputColor ? filters.colors.find(({ option }) => option === data.sector).color : SectorColors[data.sector],
            opacity: 1,
            line: {
              color: '#000000',
              width: 0.2,
            },
          },
          text: data.sectorData.map(company => company.name),
          hoverinfo: 'text',
          type: 'scatter3d',
          scene: 'scene1',
          name: data.sector,
        })
      }
    })

    return plotDataArray
  }

  const startRotation = graphDiv => {
    const rtz2xyz = rtz => {
      return {
        x: rtz.r * Math.cos(rtz.t),
        y: rtz.r * Math.sin(rtz.t),
        z: rtz.z,
      }
    }

    const xyz2rtz = xyz => {
      return {
        r: Math.sqrt(xyz.x * xyz.x + xyz.y * xyz.y),
        t: Math.atan2(xyz.y, xyz.x),
        z: xyz.z,
      }
    }

    const rotate = (id, angle) => {
      let eye0 = graphDiv.layout[id].camera.eye
      let rtz = xyz2rtz(eye0)
      rtz.t += angle

      let eye1 = rtz2xyz(rtz)
      Plotly.relayout(graphDiv, id + '.camera.eye', eye1)
    }

    const run = () => {
      rotate('scene', -Math.PI / (180 * 2))
      myRequestAnimationFrame.current = requestAnimationFrame(run)
    }

    run()
  }

  const handleCamera3dResetLastSave = (gd, ev) => {
    const button = ev.currentTarget
    const attr = button.getAttribute('data-attr')
    const resetLastSave = attr === 'resetLastSave'

    const fullLayout = gd._fullLayout
    const sceneIds = fullLayout._subplots.gl3d || []
    const aobj = {}

    for (let i = 0; i < sceneIds.length; i++) {
      const sceneId = sceneIds[i]
      const camera = sceneId + '.camera'
      const aspectratio = sceneId + '.aspectratio'
      const aspectmode = sceneId + '.aspectmode'
      const scene = fullLayout[sceneId]._scene
      let didUpdate = false

      if (resetLastSave) {
        aobj[camera + '.up'] = scene.viewInitial.up
        aobj[camera + '.eye'] = scene.viewInitial.eye
        aobj[camera + '.center'] = scene.viewInitial.center
        didUpdate = true
      }

      if (didUpdate) {
        aobj[aspectratio + '.x'] = scene.viewInitial.aspectratio.x
        aobj[aspectratio + '.y'] = scene.viewInitial.aspectratio.y
        aobj[aspectratio + '.z'] = scene.viewInitial.aspectratio.z
        aobj[aspectmode] = scene.viewInitial.aspectmode
      }
    }

    Plotly.relayout(gd, aobj)
  }

  const handleDrag3d = (gd, ev) => {
    const button = ev.currentTarget
    const attr = button.getAttribute('data-attr')
    const val = button.getAttribute('data-val') || false
    const sceneIds = gd._fullLayout._subplots.gl3d || []
    let layoutUpdate = {}

    const parts = attr.split('.')

    for (let i = 0; i < sceneIds.length; i++) {
      layoutUpdate[sceneIds[i] + '.' + parts[1]] = val
    }

    const val2d = (val === 'pan') ? val : false
    layoutUpdate.dragmode = val2d

    Plotly.relayout(gd, layoutUpdate)

    if (val !== 'auto') {
      rotationStop()
    } else {
      autoRotate.current = true
      if (myRequestAnimationFrame.current === null) startRotation(gd)
    }
  }

  const rotationStop = () => {
    cancelAnimationFrame(myRequestAnimationFrame.current)
    myRequestAnimationFrame.current = null
    autoRotate.current = false
  }

  const handleAxesButtonClick = (gd, ev) => {
    const button = ev.currentTarget
    const attr = button.getAttribute('data-attr')
    const val = !displayingAxes.current
    const sceneIds = gd._fullLayout._subplots.gl3d || []
    const parts = attr.split(',')
    let layoutUpdate = {}

    for (let i = 0; i < sceneIds.length; i++) {
      layoutUpdate[sceneIds[i] + '.' + parts[0]] = val
      layoutUpdate[sceneIds[i] + '.' + parts[1]] = val
      layoutUpdate[sceneIds[i] + '.' + parts[2]] = val
    }

    displayingAxes.current = val
    Plotly.relayout(gd, layoutUpdate)
  }

  return (
    <Plot
      debug={ true }
      divId='plotlyDiv'
      data={ appendPlotArrayData() }
      config={ {
        displaylogo: false,
        displayModeBar: true,
        modeBarButtonsToAdd: [
          {
            name: 'Reset camera',
            icon: Plotly.Icons.home,
            direction: 'up',
            attr: 'resetLastSave',
            click: (gd, event) => handleCamera3dResetLastSave(gd, event),
          },
          {
            name: 'Auto rotate',
            attr: 'scene.dragmode',
            val: 'auto',
            icon: Plotly.Icons.lasso,
            direction: 'up',
            click: handleDrag3d,
          },
          {
            name: 'Show Axes',
            attr: ['xaxis.visible', 'yaxis.visible', 'zaxis.visible'],
            icon: IconsSVG.Axis,
            direction: 'up',
            click: handleAxesButtonClick,
          },
          {
            name: 'Orbital rotation',
            attr: 'scene.dragmode',
            val: 'orbit',
            icon: Plotly.Icons['3d_rotate'],
            click: handleDrag3d,
          },
          {
            name: 'Turnable rotation',
            attr: 'scene.dragmode',
            val: 'turntable',
            icon: Plotly.Icons['z-axis'],
            click: handleDrag3d,
          },
          {
            name: 'Pan',
            _cat: 'pan',
            attr: 'scene.dragmode',
            val: 'pan',
            icon: Plotly.Icons.pan,
            click: handleDrag3d,
          },
        ],
      } }
      layout={ {
        modebar: {
          remove: ['resetCameraLastSave3d', 'resetCameraDefault3d', 'zoom3d', 'pan3d', 'orbitRotation', 'tableRotation'],
        },
        showlegend: true,
        legend: {
          font: {
            color: isDarkMode ? '#fff' : 'rgba(0, 0, 0, 0.67)',
          },
        },
        paper_bgcolor: isDarkMode ? '#424242' : '#ebebeb',
        height: 900,
        width: 1185,
        title: `3D view of clusters (${ Numeral(cleanClustersData.length).format('0,0') } data points)`,
        titlefont: { color: isDarkMode ? '#fff' : 'rgba(0, 0, 0, 0.67)' },
        scene1: {
          dragmode: 'orbit',
          domain: {
            x: [0, 0],
            y: [0, 0],
          },
          camera: {
            center: {
              x: median(cleanClustersData.map(c => c.projection[0])),
              y: median(cleanClustersData.map(c => c.projection[1])),
              z: median(cleanClustersData.map(c => c.projection[2])),
            },
            eye: { x: 1.2, y: 1.2, z: 1.2 },
          },
          xaxis: {
            visible: displayingAxes.current,
            color: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            gridcolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            zerolinecolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
          },
          yaxis: {
            visible: displayingAxes.current,
            color: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            gridcolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            zerolinecolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
          },
          zaxis: {
            visible: displayingAxes.current,
            color: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            gridcolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
            zerolinecolor: isDarkMode ? '#a8a8a8' : 'rgba(0, 0, 0, 0.3)',
          },
        },
      } }
      onInitialized={ (figure, gd) => {
        theGraphDiv.current = gd
        if (autoRotate.current) {
          startRotation(gd)
        }
      } }
      onPurge={ () => {
        cancelAnimationFrame(myRequestAnimationFrame.current)
      } }
      onHover={ e => {
        cancelAnimationFrame(myRequestAnimationFrame.current)
        onMarkerHover(e)
      } }
      onUnhover={ () => {
        onMarkerUnhover()
        if (autoRotate.current) {
          startRotation(theGraphDiv.current)
        }
      } }
      onClick={ e => onMarkerClicked(e) }
    />
  )
}, (prevProps, nextProps) => {
  if (isEqual(prevProps, nextProps)) {
    return false
  }
  return true
})

export default ScatterPlot
