import React from 'react'
import { Grid, Tooltip, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#4d4d4d',
  },
  tooltip: {
    fontSize: '1em',
    backgroundColor: '#4d4d4d',
  },
  panelLabel: {
    display: 'flex',
    marginBottom: 20,
    textAlign: 'center',
    justifyContent: 'center',

  },
  valueLabel: {
    display: 'flex',
    textAlign: 'center',
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',

  },
}))

const ScorePanel = ({ min, max, valueLabel, value, panelLabel }) => {
  const classes = useStyles()

  return (
    <Grid item style={ { margin: '0px auto', display: 'flex' } }>
      <Tooltip
        title={ <p>Min: <b>{ min }%</b> <br /> Max: <b>{ max }%</b></p> }
        placement='top'
        arrow
        classes={ { arrow: classes.arrow, tooltip: classes.tooltip } }
      >
        <Paper
          style={ {
            width: 151,
            height: 95,
            padding: 15,
          } }
        >
          <span className={ classes.panelLabel }>{ panelLabel }</span>
          <span className={ classes.valueLabel }>{ valueLabel }: <b style={ { fontSize: 18 } }> { value }%</b></span>
        </Paper>
      </Tooltip>
    </Grid>
  )
}

export default ScorePanel
