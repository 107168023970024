import {
  FETCH_UNIQUE_ENDPOINTS_START,
  FETCH_UNIQUE_ENDPOINTS_SUCCESS,
  FETCH_UNIQUE_ENDPOINTS_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  uniqueEndpoints: [],
  uniqueEndpointsFetching: true,
  uniqueEndpointsErrorMessage: '',
}

const uniqueEndpointsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_UNIQUE_ENDPOINTS_START:
      return {
        ...state,
        uniqueEndpointsFetching: true,
        addEeventNoticationErrorMessage: '',
      }
    case FETCH_UNIQUE_ENDPOINTS_SUCCESS:
      return {
        ...state,
        uniqueEndpoints: action.payload,
        uniqueEndpointsFetching: false,
        uniqueEndpointsErrorMessage: '',
      }
    case FETCH_UNIQUE_ENDPOINTS_FAIL:
      return {
        ...state,
        uniqueEndpointsFetching: false,
        uniqueEndpointsErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default uniqueEndpointsReducer
