/* eslint-disable no-alert */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Grid, TextField, Divider, makeStyles, Modal, Backdrop, Fade, Typography } from '@material-ui/core'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import { updateCompany } from '../../../store/actions/company'
import { useSnackbar } from 'notistack'
import BetaIcon from '../../../utils/Icons/BetaIcon'
import TooltipLabel from '../../UI/TooltipLabel'

const useStyles = makeStyles(theme => ({
  paper2: {
    height: 600,
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FundingRoundsForm = ({ data, onInputChange, history, collectionToUse }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)

  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const [buttonText, setButtonText] = useState('Add round')

  const handleClose = () => {
    delete data._funding_round_type
    delete data._funding_round_investors
    delete data._funding_round_date
    delete data._funding_round_amount
    delete data._funding_round_index

    setOpen(false)
  }

  const handleCloseModal = () => {
    delete data._funding_round_type
    delete data._funding_round_investors
    delete data._funding_round_date
    delete data._funding_round_amount
    delete data._funding_round_index

    setOpen(false)
  }

  const handleAddOrEditRound = () => {
    if (data._funding_round_amount === '') {
      delete data._funding_round_amount
    }

    if (data._funding_round_amount < 1) {
      enqueueSnackbar('Amount Dollars (USD) cannot be negative or 0. No data is allowed.', { variant: 'warning' })
      return
    }

    const confirmation = confirm(`Are you sure you want to ${ buttonText.split(' ')[0].toLowerCase() } this round?`)

    if (buttonText.split(' ')[0].toLowerCase() === 'edit') {

      const updateObject = {
        round_type: data._funding_round_type,
        investors: data._funding_round_investors,
        date: data._funding_round_date,
        amount: data._funding_round_amount,
      }

      data.funding_rounds[data._funding_round_index] = updateObject

      delete data._funding_round_type
      delete data._funding_round_investors
      delete data._funding_round_date
      delete data._funding_round_amount
      delete data._funding_round_index
    }

    if (confirmation) {
      dispatch(updateCompany(data, undefined, collectionToUse, enqueueSnackbar))
    }
  }

  return (
    <div>
      { history.location.pathname !== '/database/add-company' &&
        <Box mt={ 4 } display='flex' justifyContent='flex-end'>
          <Box position='relative' top={ -35 } left={ 110 }>
            <BetaIcon viewBox='0 -8 178.411 60' />
          </Box>

          <Button
            variant='contained'
            color='secondary'
            size='medium'
            endIcon={ <PlusOneIcon /> }
            onClick={ () => {
              setButtonText('Add round')
              setOpen(true)
            } }
          >
            Add round
          </Button>
        </Box> }

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={ classes.modal }
        open={ open }
        onClose={ handleClose }
        closeAfterTransition
        BackdropComponent={ Backdrop }
        BackdropProps={ {
          timeout: 100,
        } }
        style={ { color: isDarkMode && isDarkMode ? 'white' : 'black' } }
      >
        <Fade in={ open }>
          <div className={ classes.paper2 }>
            <p id='transition-modal-description' style={ { fontSize: '1.1rem', minHeight: 485 } }>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Investment type'
                    name={ '_funding_round_type' }
                    defaultValue={ data._funding_round_type && data._funding_round_type }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Investors'
                    name={ '_funding_round_investors' }
                    defaultValue={ data._funding_round_investors && data._funding_round_investors }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Date'
                    name={ '_funding_round_date' }
                    defaultValue={ data._funding_round_date && new Date(data._funding_round_date).toISOString().split('T')[0] }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                      step: 0,
                    } }
                    type='date'
                    focused
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <Box display={ 'flex' } alignItems={ 'end' }>
                    <TextField
                      onChange={ onInputChange }
                      label={ <TooltipLabel tooltipText={ 'Cannot be negative or 0. Empty is allowed.' } labelText={ 'Amount Dollars (USD)' } /> }
                      name={ '_funding_round_amount' }
                      defaultValue={ data._funding_round_amount && data._funding_round_amount }
                      fullWidth
                      margin='dense'
                      inputProps={ {
                        autoComplete: 'new-password',
                        min: 1,
                      } }
                      type='number'
                    />
                    <Typography gutterBottom>$</Typography>
                  </Box>
                </Grid>
              </Grid>
            </p>

            <Box style={ { display: 'flex', justifyContent: 'center' } }>
              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleCloseModal }
                style={ { marginRight: 30 } }
              >
                Close
              </Button>

              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleAddOrEditRound }
              >
                { buttonText }
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      { data.funding_rounds && data.funding_rounds.length ? Array(data.funding_rounds.length).fill(data.funding_rounds.sort((a, b) => new Date(a.date) - new Date(b.date))).map((fundingRound, index) =>
        <div key={ index }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                onChange={ onInputChange }
                label='Investment type'
                name={ `${ index + 1 }_funding_round_type` }
                defaultValue={ fundingRound[index].round_type ? fundingRound[index].round_type : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                onChange={ onInputChange }
                label='Investors'
                name={ `${ index + 1 }_funding_round_investors` }
                defaultValue={ fundingRound[index].investors ? fundingRound[index].investors : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                onChange={ onInputChange }
                label='Date'
                name={ `${ index + 1 }_funding_round_date` }
                defaultValue={ fundingRound[index].date ? new Date(fundingRound[index].date).toISOString().split('T')[0] : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <Box display={ 'flex' } alignItems={ 'end' }>
                <TextField
                  onChange={ onInputChange }
                  label='Amount Dollars (USD)'
                  name={ `${ index + 1 }_funding_round_amount` }
                  defaultValue={ fundingRound[index].amount ? fundingRound[index].amount : null }
                  fullWidth
                  margin='dense'
                  inputProps={ {
                    readOnly: true,
                  } }
                  type='number'
                />
                <Typography gutterBottom>$</Typography>
              </Box>
            </Grid>
          </Grid>

          <div style={ { display: 'flex', flexDirection: 'row-reverse' } }>
            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                onClick={ () => {
                  setButtonText('Edit round')

                  data._funding_round_type = data.funding_rounds[index].round_type
                  data._funding_round_investors = data.funding_rounds[index].investors
                  data._funding_round_date = data.funding_rounds[index].date
                  data._funding_round_amount = data.funding_rounds[index].amount
                  data._funding_round_index = index

                  setOpen(true)
                } }
              >
                Edit round
              </Button>
            </Box>

            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                style={ { marginRight: 30 } }
                onClick={ () => {
                  const confirmation = confirm('Are you sure you want to delete this round?')

                  if (confirmation) {
                    dispatch(updateCompany({ ...data, funding_rounds: data.funding_rounds.filter(fundingRound => fundingRound !== data.funding_rounds[index]) }, undefined, collectionToUse, enqueueSnackbar))
                  } else {
                    enqueueSnackbar('Round not deleted', { variant: 'info' })
                  }
                } }
              >
                Delete round
              </Button>
            </Box>
          </div>

          { data.funding_rounds.length > 1 && <Divider style={ { marginTop: 30, marginBottom: 30, height: 3 } } /> }
        </div>,
      ) : history.location.pathname === '/database/add-company' ?
        <div>
          { Array(1).fill(1).map((fundingRound, index) =>
            <div key={ index }>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Investment type'
                    name={ `${ index + 1 }_funding_round_type` }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Investors'
                    name={ `${ index + 1 }_funding_round_investors` }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Date'
                    name={ `${ index + 1 }_funding_round_date` }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                    type='date'
                    focused
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 6 }>
                  <Box display={ 'flex' } alignItems={ 'end' }>
                    <TextField
                      onChange={ onInputChange }
                      label='Amount Dollars (USD)'
                      name={ `${ index + 1 }_funding_round_amount` }
                      fullWidth
                      margin='dense'
                      inputProps={ {
                        autoComplete: 'new-password',
                      } }
                      type='number'
                    />
                    <Typography gutterBottom>$</Typography>
                  </Box>
                </Grid>
              </Grid>
            </div>,
          ) }
        </div> : <div>No funding rounds</div> }
    </div>
  )
}

export default FundingRoundsForm
