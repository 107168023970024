import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

import CardContent from '@material-ui/core/CardContent'

import LinearProgress from '@material-ui/core/LinearProgress'
import { Box, Typography, Chip } from '@material-ui/core'
import CircularSpinner from '../UI/Progress/CircularSpinner'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  cardContent: {
    height: '100%',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardInfo: {
    padding: 10,
  },
  Icon: {
    fontSize: 25,
    color: 'rgba(55,77,103,.2)',
  },
  ChipText: {
    fontWeight: 'bold',
  },

  title: {
    margin: 0,
  },
  cardTop: {
    paddingBottom: 10,
  },
}))

export default function SimpleCard (props) {
  const classes = useStyles()
  const cardColor = props.widgetColor
  const [completed, setCompleted] = useState(0)

  useEffect(() => {
    if (!props.data) {
      const progress = () => {
        setCompleted(oldCompleted => {
          if (oldCompleted === 100) return 0

          const diff = Math.random() * 5

          return Math.min(oldCompleted + diff, 100)
        })
      }

      const timer = setInterval(progress, 500)

      return () => { clearInterval(timer) }
    }
  }, [props.data])

  return (
    <Card className={ classes.card }>
      <CardContent className={ classes.cardContent }>
        <Box flexDirection='row' display='flex' className={ classes.cardInfo }>
          <Box width='100%'>
            <Box className={ classes.cardTop } display='flex' width='100%' justifyContent='space-between' alignItems='center'>
              <Typography className={ classes.title } variant='caption' gutterBottom>
                { props.title }
              </Typography>
              <Chip color={ cardColor } className={ classes.chipStyling } label={ props.chip } size='small' />
            </Box>
            <Box flexDirection='row' display='flex' justifyContent='space-between'>
              { props.data !== null ? <Typography variant='h5'>{ Number(props.data).toLocaleString() }</Typography> : <CircularSpinner size={ 30 } /> }
            </Box>
          </Box>
        </Box>
        { props.data !== null ? (
          <LinearProgress color={ cardColor } variant='determinate' value={ 100 } />
        ) : (
          <LinearProgress color={ cardColor } variant='determinate' value={ completed } />
        ) }
      </CardContent>
    </Card>
  )
}
