import {
  FETCH_CLUSTER_DATA_START,
  FETCH_CLUSTER_DATA_SUCCESS,
  FETCH_CLUSTER_DATA_FAIL,
} from './actionTypes'

import axios from '../../axios'

export const fetchClusterDataStart = () => ({
  type: FETCH_CLUSTER_DATA_START,
})

export const fetchClusterDataSuccess = clusterData => ({
  type: FETCH_CLUSTER_DATA_SUCCESS,
  payload: clusterData,
})

export const fetchClusterDataFail = errorMessage => ({
  type: FETCH_CLUSTER_DATA_FAIL,
  payload: errorMessage,
})

export const fetchClusterData = (sectors, limit, collectionToUse = 'companies', projection = true) => {
  return async dispatch => {
    try {
      dispatch(fetchClusterDataStart())

      const { data } = await axios.get('/api/cluster-data', { params: { collectionToUse, projection, sectors, limit } })

      dispatch(fetchClusterDataSuccess(data))
    } catch (error) {
      dispatch(fetchClusterDataFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
