import React, { Fragment } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Box, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  formControl: {
    width: '40%',
  },
}))

export default function ChartWidget (props) {
  const currentMonth = props.month
  const classes = useStyles()
  const cardHeaderMonth = (
    <Box
      display='flex'
      width='100%'
      justifyContent='space-between'
      alignItems='top'
    >
      <Box>
        <Typography variant='subtitle2' display='inline' gutterBottom>
          { props.title }:
        </Typography>

        <Typography variant='h5' gutterBottom>
          { props.totalCount }
        </Typography>
      </Box>
      <FormControl
        variant='outlined'
        margin='dense'
        className={ classes.formControl }
      >
        <Select
          defaultValue={ currentMonth }
          onChange={ props.onMonthChanged }
          displayEmpty
          className={ classes.selectEmpty }
          name={ props.selectName }
        >
          <MenuItem value={ 0 }>January</MenuItem>
          <MenuItem value={ 1 }>February</MenuItem>
          <MenuItem value={ 2 }>March</MenuItem>
          <MenuItem value={ 3 }>April</MenuItem>
          <MenuItem value={ 4 }>May</MenuItem>
          <MenuItem value={ 5 }>June</MenuItem>
          <MenuItem value={ 6 }>July</MenuItem>
          <MenuItem value={ 7 }>August</MenuItem>
          <MenuItem value={ 8 }>September</MenuItem>
          <MenuItem value={ 9 }>October</MenuItem>
          <MenuItem value={ 10 }>November</MenuItem>
          <MenuItem value={ 11 }>December</MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
  const cardHeader = (
    <Fragment>
      { props.title ? <Typography variant='h5'>{ props.title }</Typography> : null }
      { props.subTitle ? (
        <Typography variant='subtitle2' gutterBottom>
          { props.subTitle }
        </Typography>
      ) : null }
    </Fragment>
  )

  return (
    <Card>
      <CardContent>
        { typeof (props.month) === 'number' ? cardHeaderMonth : cardHeader }
        { props.children }
      </CardContent>
    </Card>
  )
}
