import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Box, TextField, Button } from '@material-ui/core'
import { fetchEventNotifications } from '../../store/actions/eventNotification'
import useEventListener from '../../utils/eventListenerHook'

const LogsSearchBar = () => {
  const dispatch = useDispatch()

  const [endpoint, setEndpoint] = useState('')

  const handleEndpointChange = value => setEndpoint(value.target.value)

  const handleAddNotificationBtnClick = () => { dispatch(fetchEventNotifications(endpoint)) }

  const keydownEventHandler = event => {
    if (event.keyCode === 13) handleAddNotificationBtnClick()
  }

  useEventListener('keydown', keydownEventHandler)

  return (
    <Grid spacing={ 1 }>
      <Box display='flex' alignItems='center' pb={ 2 }>
        <Grid xs={ 10 } md={ 10 } lg={ 10 } >
          <Box mb={ 1 } display='flex' justifyContent='flex-start'>
            <TextField
              id='search-endpoint'
              label='Search endpoint'
              style={ { width: '100%' } }
              value={ endpoint }
              onChange={ handleEndpointChange }
            />
          </Box>
        </Grid>
        <Grid xs={ 2 } md={ 2 } lg={ 2 }>
          <Box display='flex' justifyContent='flex-end'>
            <Button color='secondary' variant='contained' size='small' onClick={ () => handleAddNotificationBtnClick(endpoint) } > Filter endpoints</Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}

export default LogsSearchBar
