import {
  FETCH_COLLECTION_NAMES_START,
  FETCH_COLLECTION_NAMES_SUCCESS,
  FETCH_COLLECTION_NAMES_FAIL,
  SET_COLLECTION_TO_USE,
} from './actionTypes.js'

import axios from '../../axios'

export const fetchCollectionNamesStart = () => ({
  type: FETCH_COLLECTION_NAMES_START,
})

export const fetchCollectionNamesSuccess = collectionNames => ({
  type: FETCH_COLLECTION_NAMES_SUCCESS,
  payload: collectionNames,
})

export const fetchCollectionNamesFail = errorMessage => ({
  type: FETCH_COLLECTION_NAMES_FAIL,
  payload: errorMessage,
})

export const fetchCollectionNames = () => {
  return async dispatch => {
    try {
      dispatch(fetchCollectionNamesStart())

      const { data } = await axios.get('/api/get-collection-names')

      dispatch(fetchCollectionNamesSuccess(data))
    } catch (error) {
      dispatch(fetchCollectionNamesFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const setCollectionToUse = collectionToUse => ({
  type: SET_COLLECTION_TO_USE,
  payload: collectionToUse,
})
