import React from 'react'
import { Divider, Drawer, Hidden, List } from '@material-ui/core/'
import { ListItemText, ListItem, ListItemIcon, Paper, Icon } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import CenterBox from '../../UI/CenterBox'
import { drawerWidth } from '../../../hoc/Layout/Layout'
import { Link } from 'react-router-dom'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'
import { FaDatabase, FaChartBar, FaDesktop, FaCompressAlt, FaBook } from 'react-icons/fa'
import { rules } from '../../Auth0/PriorityRules'
import { useTheme } from '@material-ui/core'
import headLogoBlack from './assets/Valuer-logo-black.png'
import headLogoWhite from './assets/Valuer-logo-white.png'
import BetaIcon from '../../../utils/Icons/BetaIcon'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    backgroundColor: theme.palette.type === 'light' ? 'white' : '#424242',
    borderRadius: 0,
    boxShadow: 'none !important',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

export default function Sidebar (props) {
  const theme = useTheme()
  const { user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority
  const allowedPaths = rules[userPriority].allowed_paths
  const classes = useStyles()

  const [openDatabase, setOpenDatabase] = React.useState(true)
  const [openStatistics, setOpenStatistics] = React.useState(true)
  const [openLogs, setOpenLogs] = React.useState(true)

  const handleClickDatabase = () => setOpenDatabase(!openDatabase)
  const handleClickStatics = () => setOpenStatistics(!openStatistics)
  const handleClickLogs = () => setOpenLogs(!openLogs)

  const drawer = (
    <Paper className={ classes.root }>
      <CenterBox className={ classes.toolbar }>
        <Link to='/dashboard' >
          <img height='34px' src={ theme.palette.type === 'dark' ? headLogoWhite : headLogoBlack } alt='Valuer Logo' />
        </Link>
      </CenterBox>

      <List>
        <ListItem button color='primary' component={ Link } to='/dashboard'>
          <ListItemIcon color='primary'>
            <Icon color='primary'>
              <FaDesktop />
            </Icon>
          </ListItemIcon>

          <ListItemText primary='Dashboard' />
        </ListItem>

        <Divider />

        { allowedPaths.includes('/statistics') &&
          <>
            <ListItem button onClick={ handleClickStatics }>
              <ListItemIcon>
                <Icon color='primary'>
                  <FaChartBar />
                </Icon>
              </ListItemIcon>

              <ListItemText primary='Statistics' />

              { openStatistics ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>

            <Collapse in={ openStatistics } timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                <ListItem button component={ Link } to='/statistics/overview-internal' className={ classes.nested }>
                  <ListItemIcon>
                    <br />
                  </ListItemIcon>

                  <ListItemText primary='Overview Internal' />
                </ListItem>

                <ListItem button className={ classes.nested } component={ Link } to='/statistics/overview-external'>
                  <ListItemIcon>
                    <br />
                  </ListItemIcon>

                  <ListItemText primary='Overview External' />
                </ListItem>

                <ListItem button className={ classes.nested } component={ Link } to='/statistics/scores-overview'>
                  <ListItemIcon>
                    <br />
                  </ListItemIcon>

                  <ListItemText primary='Scores Overview' />
                </ListItem>

                <ListItem button className={ classes.nested } component={ Link } to='/statistics/kpis'>
                  <ListItemIcon>
                    <br />
                  </ListItemIcon>

                  <ListItemText primary='KPIs' />
                </ListItem>

                <ListItem button className={ classes.nested } component={ Link } to='/statistics/clusters'>
                  <ListItemIcon>
                    <br />
                  </ListItemIcon>

                  <ListItemText primary='Clusters' />
                </ListItem>
              </List>
            </Collapse>
          </>
        }

        <Divider />

        { allowedPaths.includes('/database') &&
          <>
            <ListItem button onClick={ handleClickDatabase }>
              <ListItemIcon>
                <Icon color='primary'>
                  <FaDatabase />
                </Icon>
              </ListItemIcon>

              <ListItemText primary='Database' />

              { openDatabase ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>

            <Collapse in={ openDatabase } timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                { allowedPaths.includes('/database/search') &&
                  <ListItem button component={ Link } to='/database/search' className={ classes.nested }>
                    <ListItemIcon>
                      <br />
                    </ListItemIcon>

                    <ListItemText primary='Search Database' />
                  </ListItem>
                }

                { allowedPaths.includes('/database/add-company') &&
                  <ListItem button className={ classes.nested } component={ Link } to='/database/add-company'>
                    <ListItemIcon>
                      <br />
                    </ListItemIcon>

                    <ListItemText primary='Add New Company' />
                  </ListItem>
                }
              </List>
            </Collapse>
          </>
        }

        { allowedPaths.includes('/database/conflicts') &&
          <>
            <Divider />

            <List component='div' disablePadding>
              <ListItem button component={ Link } to='/database/conflicts' className={ classes.nested }>
                <ListItemIcon>
                  <Icon color='primary'>
                    <FaCompressAlt />
                  </Icon>
                </ListItemIcon>

                <ListItemText primary='Conflicts' />
              </ListItem>
            </List>
          </>
        }

        { allowedPaths.includes('/database/logs') &&
          <>
            <Divider />

            <ListItem button onClick={ handleClickLogs }>
              <ListItemIcon>
                <Icon color='primary'>
                  <FaBook />
                </Icon>
              </ListItemIcon>

              <ListItemText primary='Logs' />

              { openLogs ? <ExpandLess /> : <ExpandMore /> }
            </ListItem>
            <Collapse in={ openLogs } timeout='auto' unmountOnExit>
              <List component='div' disablePadding>

                <ListItem button component={ Link } to='/database/logs' className={ classes.nested }>
                  <ListItemIcon>
                    <Icon color='primary'>
                    </Icon>
                  </ListItemIcon>
                  <ListItemText primary='Overview' />
                </ListItem>

                <ListItem button component={ Link } to='/database/logs/notifications' className={ classes.nested }>
                  <ListItemIcon>
                    <Icon color='primary'>
                    </Icon>
                  </ListItemIcon>

                  <ListItemText primary='Notifications' style={ { marginRight: 100 } } /> <BetaIcon viewBox='0 -6 178.411 60' verticalAlign='bottom' />
                </ListItem>

              </List>
            </Collapse>
          </>
        }
      </List>
    </Paper>
  )

  return (
    <nav className={ classes.drawer } aria-label='mailbox folders'>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */ }
      <Hidden smUp implementation='css'>
        <Drawer
          variant='temporary'
          open={ props.mobileOpen }
          onClose={ props.handleDrawerToggle }
          classes={ {
            paper: classes.drawerPaper,
          } }
          ModalProps={ {
            keepMounted: true, // Better open performance on mobile.
          } }
        >
          { drawer }
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation='css'>
        <Drawer
          classes={ {
            paper: classes.drawerPaper,
          } }
          variant='permanent'
          open
        >
          { drawer }
        </Drawer>
      </Hidden>
    </nav>
  )
}
