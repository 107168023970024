import React, { useState } from 'react'
import { IconButton, Dialog, Box, Typography } from '@material-ui/core/'
import CloseIcon from '@material-ui/icons/Close'
import Fade from '@material-ui/core/Fade'
import AlertDialog from './Dialogs/AlertDialog'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Fade ref={ ref } { ...props } />
})

const Modal = props => {
  const [openAlert, setOpenAlert] = useState(false)

  const handleClose = () => {
    if (props.allowAlert) {
      setOpenAlert(true)
    } else {
      props.setOpenModal(false)
    }
  }

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={ props.openModal }
        onClose={ handleClose }
        TransitionComponent={ Transition }
        fullWidth
      >
        <Box padding={ 3 }>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>{ props.title }</Typography>

            <Box display='flex' justifyContent='flex-end'>
              <IconButton edge='start' color='inherit' size='medium' onClick={ handleClose } aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          { props.children }
        </Box>
      </Dialog>

      <AlertDialog
        open={ openAlert }
        setOpen={ setOpenAlert }
        title='There are unsaved changes.'
        description='Are you sure you want to discard changes and close the tab?'
        btnTitle='Yes'
        onSubmit={ () => props.setOpenModal(false) }
      />
    </div>
  )
}

export default Modal
