import {
  FETCH_EVENT_NOTIFICATIONS_START,
  FETCH_EVENT_NOTIFICATIONS_SUCCESS,
  FETCH_EVENT_NOTIFICATIONS_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  eventNotificationsData: [],
  eventNotificationsFetching: true,
  eventNoticationsErrorMessage: '',
}

const eventNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENT_NOTIFICATIONS_START:
      return {
        ...state,
        eventNotificationsFetching: true,
        eventNoticationsErrorMessage: '',
      }
    case FETCH_EVENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        eventNotificationsData: action.payload,
        eventNotificationsFetching: false,
        eventNoticationsErrorMessage: '',
      }
    case FETCH_EVENT_NOTIFICATIONS_FAIL:
      return {
        ...state,
        eventNotificationsFetching: false,
        eventNoticationsErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default eventNotificationReducer
