import React from 'react'
import { Grid, TextField, Typography, Tooltip, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MultiSelectAutocomplete from '../../UI/Forms/MultiSelectAutocomplete'
import InvestmentStage from '../../../Constants/InvestmentStage'
import SectorsList from '../../../Constants/Sectors'
import SDGs from '../../../Constants/SDGs'
import CountriesList from '../../../Constants/Countries'
import YearPicker from '../../UI/Forms/YearPicker'
import SubSectorsAutocomplete from '../Forms/Inputs/SubSectorsAutocomplete'
import InfoIcon from '@material-ui/icons/Info'
import { useSnackbar } from 'notistack'
import BetaIcon from '../../../utils/Icons/BetaIcon'
import TooltipLabel from '../../UI/TooltipLabel'

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
}))

const BasicOrganisationForm = ({ data, onInputChange, allowEdit }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const onAutocompleteChange = (name, value) => {
    if (name === 'alias') {
      if (!value.includes(data.name)) {
        enqueueSnackbar('WARNING: Company name is always included in the Aliases', { variant: 'warning' })

        return onInputChange({
          target: {
            name: name,
            value: [data.name, ...value],
          },
        })
      }

      const invalidValues = value.filter(val => Number(val) || val === ' ')

      if (invalidValues.length) {
        enqueueSnackbar('Alias must only contain non-empty strings (no numbers or empty spaces)', { variant: 'warning' })
      }
    }

    onInputChange({
      target: {
        name: name,
        value: value,
      },
    })
  }

  if (!allowEdit) {
    return <ReadOnlyFrom data={ data } />
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <TextField
          label={
            <Box>
              <Tooltip title='Company Name is required' placement='top-start' classes={ { tooltip: classes.tooltip } }>
                <Box component='span'>
                  Company Name <Box component='span' style={ { color: 'red', fontSize: 20 } }>*</Box>
                </Box>
              </Tooltip>
            </Box>
          }
          name='name'
          defaultValue={ data.name }
          fullWidth
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Box display='flex'>
          <img
            crossOrigin='use-credentials'
            src={ data.logo_url ?? '' }
            width={ 100 }
            style={ { display: 'inline', marginRight: 20 } }
          />

          <TextField
            label={ <TooltipLabel labelText={ 'Logo Source' } tooltipText={ 'Must be public URL. Cannot be .svg or .ico format.' } /> }
            name='logo_source_url'
            defaultValue={ data.logo_source_url }
            margin='dense'
            fullWidth
            onChange={ onInputChange }
            autoComplete='off'
          />
        </Box>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <TextField
          label={
            <Box>
              <Tooltip title='Website is required' placement='top-start' classes={ { tooltip: classes.tooltip } }>
                <Box component='span'>
                  Website <Box component='span' style={ { color: 'red', fontSize: 20 } }>*</Box>
                </Box>
              </Tooltip>
            </Box>
          }
          name='website'
          defaultValue={ data.website }
          fullWidth
          margin='dense'
          onChange={ onInputChange }
          autoComplete='off'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <YearPicker year={ data.founded_date } name='founded_date' margin='dense' onChange={ onInputChange } />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <FormControl margin='dense' fullWidth>
          <InputLabel>Company Size</InputLabel>

          <Select name='number_of_employees' value={ data.number_of_employees ? data.number_of_employees : '' } onChange={ onInputChange }>
            <MenuItem value=''>Unknown</MenuItem>
            <MenuItem value='0 to 10'>0 to 10</MenuItem>
            <MenuItem value='11 to 50'>11 to 50</MenuItem>
            <MenuItem value='51 to 100'>51 to 100</MenuItem>
            <MenuItem value='101 to 250'>101 to 250</MenuItem>
            <MenuItem value='251 to 500'>251 to 500</MenuItem>
            <MenuItem value='501 to 1000'>501 to 1000</MenuItem>
            <MenuItem value='1001 to 5000'>1001 to 5000</MenuItem>
            <MenuItem value='5001 to 10000'>5001 to 10000</MenuItem>
            <MenuItem value='10001+'>10001+</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <MultiSelectAutocomplete
          multiple={ false }
          name='hq_country'
          options={ CountriesList }
          defaultValue={ data.hq_country }
          onChange={ value => onAutocompleteChange('hq_country', value) }
          label='HQ Country'
          margin='dense'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <TextField
          label='HQ City'
          name='hq_city'
          defaultValue={ data.hq_city }
          fullWidth
          margin='dense'
          onChange={ onInputChange }
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <TextField
          disabled
          name='hq_area'
          options={ ['Asia', 'Africa', 'Europe', 'North America', 'South America', 'Australia/Oceania', 'Antarctica'] }
          defaultValue={ data.hq_area }
          fullWidth
          onChange={ value => onAutocompleteChange('hq_area', value) }
          label='HQ Continent'
          margin='dense'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <MultiSelectAutocomplete
          multiple
          freeSolo
          clearOnBlur
          name='alias'
          options={ [] }
          defaultValue={ data.alias }
          onChange={ value => onAutocompleteChange('alias', value) }
          label='Aliases'
          margin='dense'
          alias
        />
      </Grid>

      <Grid item xs={ 12 } md={ 4 }>
        <MultiSelectAutocomplete
          label='Company Stage'
          multiple={ false }
          onChange={ value =>
            onInputChange({
              target: {
                name: 'investment_stage',
                value: value,
              },
            })
          }
          options={ InvestmentStage }
          value={ data.investment_stage }
          margin='dense'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <MultiSelectAutocomplete
          multiple
          name='sectors'
          options={ SectorsList }
          defaultValue={ data.sectors }
          onChange={ value => onAutocompleteChange('sectors', value) }
          label='Industries'
          margin='dense'
        />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <SubSectorsAutocomplete onChange={ value => onAutocompleteChange('subsectors', value) } defaultValue={ data.subsectors } />
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Box display='flex'>
          <MultiSelectAutocomplete
            multiple
            name='relevant_sdgs'
            options={ SDGs }
            defaultValue={ data.relevant_sdgs }
            onChange={ value => onAutocompleteChange('relevant_sdgs', value) }
            label='SGDs'
            margin='dense'
            style={ { width: '95%', marginRight: 20 } }
          />

          <Box style={ { marginTop: 30 } }>
            <BetaIcon viewBox='0 -8 178.411 60' verticalAlign='bottom' />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={ 12 }>
        <TextField
          label={
            <div style={ { display: 'flex', flexDirection: 'row' } }>
              <TooltipLabel tooltipText={ 'Write a sentece with using atleast 5 words.' } labelText={ 'Description' }></TooltipLabel>
              <Tooltip title='Description is required' placement='top-start' classes={ { tooltip: classes.tooltip } }>
                <Box component='span' style={ { color: 'red', fontSize: 20, marginLeft: '5px' } }>*</Box>
              </Tooltip>
            </div>

          }
          name='description'
          defaultValue={ data.description }
          multiline
          fullWidth
          margin='dense'
          onChange={ onInputChange }
          inputProps={ {
            autoComplete: 'new-password',
          } }
        />

        <Box
          color='#5c6bc0'
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            { data.description ? (data.description.trim().split(' ')[0] === '' ? data.description.trim().split(' ').length - 1 : data.description.trim().split(' ').length) : 0 } words long
          </Typography>
        </Box>

      </Grid>
    </Grid >
  )
}

function ReadOnlyFrom ({ data }) {
  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Company: </b> { data.name }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Website: </b> { data.website }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Founded Year: </b> { data.founded_date }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>HQ Country: </b> { data.hq_country }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>HQ City: </b> { data.hq_city }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>HQ Continent: </b> { data.hq_area }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Number of Employees: </b> { data.number_of_employees }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Industries: </b> { data.sectors }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
        <Typography>
          <b>Sub-Industries: </b> { data.subsectors }
        </Typography>
      </Grid>

      <Grid item xs={ 12 }>
        <Typography>
          <b>Description: </b> { data.description }
        </Typography>
      </Grid>
    </Grid>
  )
}

export default BasicOrganisationForm
