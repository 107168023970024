import {
  FETCH_EVENT_NOTIFICATIONS_START,
  FETCH_EVENT_NOTIFICATIONS_SUCCESS,
  FETCH_EVENT_NOTIFICATIONS_FAIL,
  ADD_RECIPIENT_TO_NOTIFICATION_START,
  ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS,
  ADD_RECIPIENT_TO_NOTIFICATION_FAIL,
  REMOVE_RECIPIENT_FROM_NOTIFICATION_START,
  REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS,
  REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL,
  REGISTER_EVENT_NOTIFICATION_START,
  REGISTER_EVENT_NOTIFICATION_SUCCESS,
  REGISTER_EVENT_NOTIFICATION_FAIL,
  REMOVE_EVENT_NOTIFICATION_START,
  REMOVE_EVENT_NOTIFICATION_SUCCESS,
  REMOVE_EVENT_NOTIFICATION_FAIL,
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  SUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

import axios from '../../axios'

export const removeEventNotificationStart = () => ({
  type: REMOVE_EVENT_NOTIFICATION_START,
})

export const removeEventNotificationSuccess = eventNotification => ({
  type: REMOVE_EVENT_NOTIFICATION_SUCCESS,
  payload: eventNotification,
})

export const removeEventNotificationFail = errorMessage => ({
  type: REMOVE_EVENT_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const removeEventNotification = notificationID => {
  return async dispatch => {
    try {
      dispatch(removeEventNotificationStart())
      const { data } = await axios.delete(`/api/event-notification/${ notificationID }`)
      dispatch(removeEventNotificationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(removeEventNotificationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const registerEventNotificationStart = () => ({
  type: REGISTER_EVENT_NOTIFICATION_START,
})

export const registerEventNotificationSuccess = eventNotification => ({
  type: REGISTER_EVENT_NOTIFICATION_SUCCESS,
  payload: eventNotification,
})

export const registerEventNotificationFail = errorMessage => ({
  type: REGISTER_EVENT_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const registerEventNotification = endpoint => {
  return async dispatch => {
    try {
      dispatch(registerEventNotificationStart())
      const { data } = await axios.post('/api/event-notification', { endpoint })
      dispatch(registerEventNotificationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(registerEventNotificationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const fetchEventNotificiationsStart = () => ({
  type: FETCH_EVENT_NOTIFICATIONS_START,
})

export const fetchEventNotificiationsSuccess = eventNotifications => ({
  type: FETCH_EVENT_NOTIFICATIONS_SUCCESS,
  payload: eventNotifications,
})

export const fetchEventNotificiationsFail = errorMessage => ({
  type: FETCH_EVENT_NOTIFICATIONS_FAIL,
  payload: errorMessage,
})

export const fetchEventNotifications = fragment => {
  return async dispatch => {
    try {
      dispatch(fetchEventNotificiationsStart())
      const { data } = await axios.get('/api/event-notification', { params: { fragment } })
      dispatch(fetchEventNotificiationsSuccess(data))
    } catch (error) {
      dispatch(fetchEventNotificiationsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const addRecipientToNotificationStart = () => ({
  type: ADD_RECIPIENT_TO_NOTIFICATION_START,
})

export const addRecipientToNotificationFail = errorMessage => ({
  type: ADD_RECIPIENT_TO_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const addRecipientToNotificationSuccess = eventNotification => ({
  type: ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS,
  payload: eventNotification,
})

export const addRecipientToNotification = (notificationID, recipient, recipientGroup) => {
  return async dispatch => {
    try {
      dispatch(addRecipientToNotificationStart())
      const { data } = await axios.patch(`api/event-notification/add-recipient/${ notificationID }`, { recipient, recipientGroup })
      dispatch(addRecipientToNotificationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(addRecipientToNotificationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const removeRecipientFromNotificationStart = () => ({
  type: REMOVE_RECIPIENT_FROM_NOTIFICATION_START,
})

export const removeRecipientFromNotificationFail = errorMessage => ({
  type: REMOVE_RECIPIENT_FROM_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const removeRecipientFromNotificationSuccess = eventNotification => ({
  type: REMOVE_RECIPIENT_FROM_NOTIFICATION_SUCCESS,
  payload: eventNotification,
})

export const removeRecipientFromNotification = (notificationID, recipient, recipientGroup) => {
  return async dispatch => {
    try {
      dispatch(removeRecipientFromNotificationStart())
      const { data } = await axios.patch(`api/event-notification/remove-recipient/${ notificationID }`, { recipient, recipientGroup })
      dispatch(removeRecipientFromNotificationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(removeRecipientFromNotificationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const subscribeAllEventNotificiationStart = () => ({
  type: SUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
})

export const subscribeAllEventNotificiationFail = errorMessage => ({
  type: SUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const subscribeAllEventNotificiationSuccess = data => ({
  type: SUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  payload: data,
})

export const subscribeAllEventNotificiation = (recipient, recipientGroup) => {
  return async dispatch => {
    try {
      dispatch(subscribeAllEventNotificiationStart())
      const { data } = await axios.post('api/event-notification/subscribe-all', { recipient, recipientGroup })
      dispatch(subscribeAllEventNotificiationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(subscribeAllEventNotificiationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const unsubscribeAllEventNotificiationStart = () => ({
  type: UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_START,
})

export const unsubscribeAllEventNotificiationFail = errorMessage => ({
  type: UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_FAIL,
  payload: errorMessage,
})

export const unsubscribeAllEventNotificiationSuccess = data => ({
  type: UNSUBSCRIBE_ALL_EVENT_NOTIFICATION_SUCCESS,
  payload: data,
})

export const unsubscribeAllEventNotificiation = (recipient, recipientGroup) => {
  return async dispatch => {
    try {
      dispatch(unsubscribeAllEventNotificiationStart())
      const { data } = await axios.post('api/event-notification/unsubscribe-all', { recipient, recipientGroup })
      dispatch(unsubscribeAllEventNotificiationSuccess(data))
      dispatch(fetchEventNotifications())
    } catch (error) {
      dispatch(unsubscribeAllEventNotificiationFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
