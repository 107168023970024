const Sectors = [
  'Accommodation & Food',
  'Administrative Services',
  'Agriculture & Farming',
  'Biotechnology',
  'Business Consultancy',
  'Consumer Goods',
  'Data & Analytics',
  'Education',
  'Energy',
  'Financial Services',
  'Hardware',
  'Health Care',
  'Information Technology',
  'Manufacturing',
  'Media & Entertainment',
  'Military & Defence',
  'Natural Resources',
  'Privacy & Security',
  'Real Estate',
  'Retail & Marketing',
  'Science & Engineering',
  'Software',
  'Sustainability',
  'Transportation',
]

export default Sectors
