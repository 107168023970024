import React, { Fragment, useRef } from 'react'
import { connect } from 'react-redux'

import { Container } from '@material-ui/core/'
import CircularSpinner from '../../UI/Progress/CircularSpinner'
import CenterBox from '../../UI/CenterBox'
import CompanyForm from '../Forms/CompanyForm'
import ErrorMessage from '../../Messages/ErrorMessage'

const EditCompany = ({ loading, error, company, importTable, setAlert }) => {

  const currentTabIndex = useRef(0)

  const handleSetCurrentTabIndex = tabID => { currentTabIndex.current = tabID }

  if (error) {
    return <ErrorMessage errorMessage={ error } />
  }

  if (loading) {
    return (
      <Container fixed>
        <CenterBox height='60vh'>
          <CircularSpinner size={ 150 } />
        </CenterBox>
      </Container>
    )
  }

  return (
    <Fragment>
      <CompanyForm updateForm importTable={ importTable } company={ company } setAlert={ data => setAlert(data) } currentTab={ currentTabIndex.current } setCurrentTab={ data => handleSetCurrentTabIndex(data) } />
    </Fragment>
  )
}

const mapStateToProps = state => ({
  company: state.company.data,
  loading: state.company.loading,
  error: state.company.error,
})

export default connect(mapStateToProps, null)(EditCompany)
