import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  box: {
    width: '190px',
    maxHeight: '850px',
    position: 'absolute',
    zIndex: 10,
    margin: '15px',
    background: 'grey',
    borderRadius: '5px',
    overflow: 'hidden',

  },
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  item: {
    padding: '5px',
    textAlign: 'center',
  },
  titleSection: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  detailsSection: {
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    cursor: 'ns-resize',
  },
  websiteSection: {
    overflow: 'hidden',
    fontSize: '10px',
    cursor: 'pointer',
  },
  title: {
    fontWeight: 'bold',
  },
}))

const SelectedCompanyBox = ({ companyData, visible }) => {
  const classes = useStyles()

  return (
    <div className={ visible ? classes.visible : classes.hidden }>
      <Grid className={ classes.box }>
        <Grid className={ classes.titleSection } sm={ 12 } md={ 12 } lg={ 12 }>
          <div className={ classes.item } >
            { companyData.name ? companyData.name : 'No selected company' }
          </div>
        </Grid>
        <Grid className={ classes.websiteSection } sm={ 12 } md={ 12 } lg={ 12 }>
          <div className={ classes.item }>
            { companyData.website ?
              <div>
                { companyData.website ? companyData.website : 'Unknown website' }
              </div>
              :
              'No website' }
          </div>
        </Grid>
        <Grid className={ classes.detailsSection } sm={ 12 } md={ 12 } lg={ 12 }>
          <div className={ classes.item } >
            { companyData.description ? companyData.description : 'No description' }
          </div>
        </Grid>
      </Grid>
    </div >
  )
}

export default SelectedCompanyBox
