/* eslint-disable no-alert */
import React, { useState } from 'react'
import { Box, Button, Grid, TextField, Divider, Fade, Modal, Backdrop, makeStyles, FormControl, MenuItem, Select } from '@material-ui/core'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import { useSnackbar } from 'notistack'
import { fetchCompany, updateCompany } from '../../../store/actions/company'
import { useDispatch, useSelector } from 'react-redux'
import TooltipLabel from '../../UI/TooltipLabel'

const useStyles = makeStyles(theme => ({
  paper2: {
    height: 600,
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const CustomersForm = ({ data, onInputChange, history, collectionToUse }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const [buttonText, setButtonText] = useState('Add customer')

  const [protocolValue, setProtocolValue] = useState('http://')

  const handleProtocolChange = e => {
    setProtocolValue(e.target.value)
  }

  const handleClose = () => {
    delete data._customer_name
    delete data._customer_website
    delete data._customer_logo_source_url
    delete data.protocol
    setProtocolValue('http://')

    setOpen(false)
  }

  const handleCloseModal = () => {
    delete data._customer_name
    delete data._customer_website
    delete data._customer_logo_source_url
    delete data.protocol
    setProtocolValue('http://')

    setOpen(false)
  }

  const handleAddTeamCustomer = () => {
    if (!protocolValue) {
      return enqueueSnackbar('Protocol (http/https) is required', { variant: 'error' })
    } else if (protocolValue && data._customer_website) {
      data._customer_website = protocolValue + (data._customer_website.includes('//') ? data._customer_website.split('//')[1] : data._customer_website)
    }

    const confirmation = confirm(`Are you sure you want to ${ buttonText.split(' ')[0].toLowerCase() } this customer?`)

    if (confirmation) {
      const callDispatch = async () => {
        await dispatch(updateCompany(data, undefined, collectionToUse, enqueueSnackbar))
      }

      callDispatch().then(async () => {
        await dispatch(fetchCompany(data._id, undefined, collectionToUse, true))
      })
    }
  }

  return (
    <div>
      { history.location.pathname !== '/database/add-company' &&
        <Box display='flex' justifyContent='flex-end'>
          <Button
            variant='contained'
            color='secondary'
            size='medium'
            endIcon={ <PlusOneIcon /> }
            onClick={ () => {
              setButtonText('Add customer')
              setDisabled(false)
              setOpen(true)
            } }
          >
            Add customer
          </Button>
        </Box> }

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={ classes.modal }
        open={ open }
        onClose={ handleClose }
        closeAfterTransition
        BackdropComponent={ Backdrop }
        BackdropProps={ {
          timeout: 100,
        } }
        style={ { color: isDarkMode && isDarkMode ? 'white' : 'black' } }
      >
        <Fade in={ open }>
          <div className={ classes.paper2 }>
            <p id='transition-modal-description' style={ { fontSize: '1.1rem', minHeight: 485 } }>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 }>
                  <TextField
                    disabled={ disabled }
                    onChange={ onInputChange }
                    label='Customer Name'
                    name={ '_customer_name' }
                    defaultValue={ data._customer_name && data._customer_name }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } style={ { display: 'flex' } }>
                  <FormControl style={ { width: 90, marginTop: 21 } }>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      name='protocol'
                      value={ protocolValue }
                      label='Age'
                      onChange={ handleProtocolChange }
                    >
                      <MenuItem value='http://' >http://</MenuItem>
                      <MenuItem value='https://' >https://</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    onChange={ onInputChange }
                    label='Customer Website'
                    name={ '_customer_website' }
                    defaultValue={ data._customer_website && (data._customer_website.startsWith('https://') ? data._customer_website.substr(8) : data._customer_website.substr(7)) }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } style={ { display: 'flex' } }>
                  <TextField
                    onChange={ onInputChange }
                    label={ <TooltipLabel labelText={ 'Customer Logo Source' } tooltipText={ 'Must be public URL. Cannot be .svg or .ico format.' } /> }
                    name={ '_customer_logo_source_url' }
                    defaultValue={ data._customer_logo_source_url && data._customer_logo_source_url }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>
              </Grid>
            </p>

            <Box style={ { display: 'flex', justifyContent: 'center' } }>
              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleCloseModal }
                style={ { marginRight: 30 } }
              >
                Close
              </Button>

              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleAddTeamCustomer }
              >
                { buttonText }
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      { data.customersFull ? Array(data.customersFull.length).fill(data.customersFull).map((customer, index) =>
        <div key={ index }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                onChange={ onInputChange }
                label='Customer Name'
                name={ `${ index + 1 }_customer_name` }
                defaultValue={ data.customersFull && index < data.customersFull.length ? customer[index].name : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  autoComplete: 'new-password',
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                onChange={ onInputChange }
                label='Customer Website'
                name={ `${ index + 1 }_customer_website` }
                defaultValue={ data.customersFull && index < data.customersFull.length ? customer[index].website : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  autoComplete: 'new-password',
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 6 } lg={ 4 }>
              <Box display='flex' justifyContent='space-between'>
                <img
                  src={ data.customersFull && index < data.customersFull.length ? customer[index].logo_url : null }
                  width={ 100 }
                  style={ { display: 'inline' } }
                />
              </Box>
            </Grid>
          </Grid>

          <div style={ { display: 'flex', flexDirection: 'row-reverse' } }>
            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                onClick={ () => {
                  setButtonText('Edit customer')
                  setDisabled(true)

                  data._customer_name = data.customersFull[index].name
                  data._customer_website = data.customersFull[index].website
                  data._customer_logo_source_url = data.customersFull[index].logo_source_url
                  setProtocolValue(data._customer_website.startsWith('https://') ? 'https://' : 'http://')

                  setOpen(true)
                } }
              >
                Edit customer
              </Button>
            </Box>

            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                style={ { marginRight: 30 } }
                onClick={ () => {
                  const confirmation = confirm('Are you sure you want to delete this team customer?')

                  if (confirmation) {
                    const callDispatch = async () => {
                      await dispatch(updateCompany({ ...data, customers: data.customers.filter(customer => customer !== data.customers[index]) }, undefined, collectionToUse, enqueueSnackbar))
                    }

                    callDispatch().then(async () => {
                      await dispatch(fetchCompany(data._id, undefined, collectionToUse, true))
                    })
                  } else {
                    enqueueSnackbar('Customer not deleted', { variant: 'info' })
                  }
                } }
              >
                Delete customer
              </Button>
            </Box>
          </div>

          { data.customersFull.length > 1 && <Divider style={ { marginTop: 30, marginBottom: 30, height: 3 } } /> }
        </div>,
      ) : <div>No customer at the moment</div> }
    </div>
  )
}

export default CustomersForm
