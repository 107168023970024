import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import PrivateRoute from './components/Auth0/PrivateRoute'
import Layout from './hoc/Layout/Layout'

// Pages
import Dashboard from './pages/Dashboard'
import SearchDatabase from './pages/SearchDatabase'
import RouteNotFound from './pages/RouteNotFound'
import AddCompany from './pages/AddCompany'
import OverviewInternal from './pages/Statistics/OverviewInternal'
import OverviewExternal from './pages/Statistics/OverviewExternal'
import ScoresOverview from './pages/Statistics/ScoresOverview/ScoresOverview'
import KPIs from './pages/Statistics/KPIs'
import ClustersPage from './pages/Statistics/Clusters/ClustersPage'
import ConflictsPage from './pages/ConflictsPage'
import LogsPage from './pages/Logs/LogsPage'
import LogsNotificationPage from './pages/Logs/LogsNotificationPage'
import ProfilePage from './pages/ProfilePage'

const ProtectedRoutes = props =>
  <Switch>
    <Route exact path='/'>
      <Redirect to='/dashboard' />
    </Route>

    <Layout toggleDarkMode={ props.toggleDarkMode }>
      <Switch>
        <PrivateRoute path='/dashboard' exact component={ Dashboard } />
        <PrivateRoute path='/database/search' exact component={ SearchDatabase } />
        <PrivateRoute path='/database/add-company' exact component={ AddCompany } />
        <PrivateRoute path='/database/conflicts' exact component={ ConflictsPage } />
        <PrivateRoute path='/database/logs' exact component={ LogsPage } />
        <PrivateRoute path='/database/logs/notifications' exact component={ LogsNotificationPage } />
        <PrivateRoute path='/statistics/overview-internal' render={ props => <OverviewInternal { ...props } /> } />
        <PrivateRoute path='/statistics/overview-external' render={ props => <OverviewExternal { ...props } /> } />
        <PrivateRoute path='/statistics/scores-overview' render={ props => <ScoresOverview { ...props } /> } />
        <PrivateRoute path='/statistics/kpis' render={ props => <KPIs { ...props } /> } />
        <PrivateRoute path='/statistics/clusters' exact component={ ClustersPage } />
        <PrivateRoute path='/profile' exact component={ ProfilePage } />

        <Route exact path='/database'>
          <Redirect to='/database/search' />
        </Route>

        <PrivateRoute path='/' component={ RouteNotFound } />
      </Switch>
    </Layout>
  </Switch>

export default ProtectedRoutes
