import {
  FETCH_DESCRIPTIONS_COUNTS_START,
  FETCH_DESCRIPTIONS_COUNTS_SUCCESS,
  FETCH_DESCRIPTIONS_COUNTS_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  descriptionsCountsData: [],
  isFetching: true,
  errorMessage: '',
}

const descriptionsCountsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DESCRIPTIONS_COUNTS_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_DESCRIPTIONS_COUNTS_SUCCESS:
      return {
        ...state,
        descriptionsCountsData: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_DESCRIPTIONS_COUNTS_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default descriptionsCountsReducer
