import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import CustomersForm from './CustomersForm'

export default function AdditionalInformationForm ({ data, onInputChange, allowEdit, history, collectionToUse }) {
  const businessModelRef = useRef(null)
  const sustainabilityModelRef = useRef(null)
  const valuePropositionRef = useRef(null)
  const productPortfolioRef = useRef(null)
  const marketOpportunitiesRef = useRef(null)
  const achievementsRef = useRef(null)

  const htmlToDraftBlocks = html => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState)

    return editorState
  }

  const [businessModelState, setBusinessModelEditorState] = useState(data.business_model ? htmlToDraftBlocks(data.business_model) : EditorState.createEmpty())
  const [sustainabilityModelState, setSustainabilityModelEditorState] = useState(data.sustainability_model ? htmlToDraftBlocks(data.sustainability_model) : EditorState.createEmpty())
  const [valuePropositionState, setValuePropositionEditorState] = useState(data.value_proposition ? htmlToDraftBlocks(data.value_proposition) : EditorState.createEmpty())
  const [productPortfolioState, setProductPortfolioEditorState] = useState(data.product_portfolio ? htmlToDraftBlocks(data.product_portfolio) : EditorState.createEmpty())
  const [marketOpportunitiesState, setMarketOpportunitiesEditorState] = useState(data.market_opportunities ? htmlToDraftBlocks(data.market_opportunities) : EditorState.createEmpty())
  const [achievementsState, setAchievementsEditorState] = useState(data.achievements ? htmlToDraftBlocks(data.achievements) : EditorState.createEmpty())

  useEffect(() => {
    businessModelRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(businessModelState.getCurrentContent()))])

  useEffect(() => {
    sustainabilityModelRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(sustainabilityModelState.getCurrentContent()))])

  useEffect(() => {
    valuePropositionRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(valuePropositionState.getCurrentContent()))])

  useEffect(() => {
    productPortfolioRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(productPortfolioState.getCurrentContent()))])

  useEffect(() => {
    marketOpportunitiesRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(marketOpportunitiesState.getCurrentContent()))])

  useEffect(() => {
    achievementsRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(achievementsState.getCurrentContent()))])

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 }>
        <input
          name='business_model'
          value={ draftToHtml(convertToRaw(businessModelState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ businessModelRef }
          hidden
        />

        <Typography variant='h6'>Business Model Characteristics</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(businessModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(businessModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(businessModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ businessModelState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setBusinessModelEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <input
          name='value_proposition'
          value={ draftToHtml(convertToRaw(valuePropositionState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ valuePropositionRef }
          hidden
        />

        <Typography variant='h6'>Value Proposition</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(valuePropositionState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(valuePropositionState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(valuePropositionState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ valuePropositionState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setValuePropositionEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <input
          name='product_portfolio'
          value={ draftToHtml(convertToRaw(productPortfolioState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ productPortfolioRef }
          hidden
        />

        <Typography variant='h6'>Products & Technology</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(productPortfolioState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(productPortfolioState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(productPortfolioState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ productPortfolioState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setProductPortfolioEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <input
          name='sustainability_model'
          value={ draftToHtml(convertToRaw(sustainabilityModelState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ sustainabilityModelRef }
          hidden
        />

        <Typography variant='h6'>Sustainability</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(sustainabilityModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(sustainabilityModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(sustainabilityModelState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ sustainabilityModelState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setSustainabilityModelEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <input
          name='market_opportunities'
          value={ draftToHtml(convertToRaw(marketOpportunitiesState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ marketOpportunitiesRef }
          hidden
        />

        <Typography variant='h6'>Market Opportunities</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(marketOpportunitiesState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(marketOpportunitiesState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(marketOpportunitiesState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ marketOpportunitiesState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setMarketOpportunitiesEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <input
          name='achievements'
          value={ draftToHtml(convertToRaw(achievementsState.getCurrentContent())) }
          onChange={ e => onInputChange(e) }
          ref={ achievementsRef }
          hidden
        />

        <Typography variant='h6'>Achievements</Typography>

        <Box
          color='#5c6bc0'
          marginBottom={ 1 }
        >
          <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

          <Typography
            style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
          >
            {
              convertToRaw(achievementsState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                convertToRaw(achievementsState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                convertToRaw(achievementsState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
            } words long
          </Typography>
        </Box>

        <Editor
          editorState={ achievementsState }
          toolbarClassName='toolbarClassName'
          wrapperClassName='wrapperClassName'
          editorClassName='editorClassName'
          onEditorStateChange={ setAchievementsEditorState }
          editorStyle={ { borderBottom: '1px solid' } }
          toolbar={ {
            options: ['inline', 'blockType', 'list', 'link', 'history', 'fontSize', 'fontFamily'],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            blockType: {
              inDropdown: false,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
              className: 'blockType',
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          } }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <Typography variant='h6' style={ { marginTop: 30 } }>Customers and Partners</Typography>

        <CustomersForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ data } history={ history } collectionToUse={ collectionToUse } />
      </Grid>
    </Grid>
  )
}
