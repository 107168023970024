import {
  FETCH_FOUNDER_START,
  FETCH_FOUNDER_SUCCESS,
  FETCH_FOUNDER_FAIL,
  FOUNDER_RESET,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  founder: null,
  isFetching: false,
  errorMessage: '',
}

const founderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FOUNDER_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_FOUNDER_SUCCESS:
      return {
        ...state,
        founder: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_FOUNDER_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    case FOUNDER_RESET:
      return {
        ...state,
        founder: null,
        isFetching: false,
        errorMessage: '',
      }
    default:
      return state
  }
}

export default founderReducer
