import React, { Fragment, useEffect, useState } from 'react'
import BreadCrumb from '../../components/Navigation/BreadCrumbs/BreadCrumbs'
import DashboardWidget from '../../components/Widgets/DashboardWidget'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Container } from '@material-ui/core'
import axios from '../../axios'
import { useSnackbar } from 'notistack'
import { fetchCompaniesByCountry } from '../../store/actions/companiesByCountry'
import { useDispatch } from 'react-redux'
import AtlasChart from './ScoresOverview/AtlasChart'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}))

const OverviewExternal = () => {
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const [widgetData, setWidgetData] = useState({
    startups: null,
    dataPoints: null,
    newStartups: null,
    updatedStartups: null,
  })

  useEffect(() => {
    dispatch(fetchCompaniesByCountry())
  }, [dispatch])

  // fetch widget data
  useEffect(() => {
    axios
      .get('/api/dashboard/stats')
      .then(response => {
        setWidgetData(response.data)
      })
      .catch(() => {
        enqueueSnackbar('Unable to fetch Data Points', { variant: 'error' })
      })
  }, [enqueueSnackbar])

  const mongoChartsConfig = JSON.parse(window._env_.MONGO_CHARTS_CONFIG_EXTERNAL)

  return (
    <Fragment>
      <BreadCrumb />

      <Container className={ classes.root }>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
            <DashboardWidget title='Startups' chip='Total' widgetColor='secondary' data={ widgetData.startups } />
          </Grid>

          <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 6 }>
            <DashboardWidget title='Data Points' chip='Total' widgetColor='secondary' data={ widgetData.dataPoints } />
          </Grid>

          {
            mongoChartsConfig && mongoChartsConfig.map((chart, index) =>

              <Grid item key={ index } xs={ 12 } sm={ 12 } md={ 12 } lg={ 12 }>
                <AtlasChart
                  filterField={ chart.filterField }
                  filterLabel={ chart.filterLabel }
                  chartId={ chart.chartId }
                >
                </AtlasChart>
              </Grid>)
          }
        </Grid>
      </Container>
    </Fragment>
  )
}

export default OverviewExternal
