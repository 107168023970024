import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  data: [],
  error: null,
  loading: false,
}

// eslint-disable-next-line no-unused-vars
const fetchCompanyListStart = (state, action) => {
  return updateObject(state, { data: [], loading: true, error: null })
}

const fetchCompanyListSuccess = (state, action) => {
  return updateObject(state, { data: action.data, loading: false })
}

const fetchCompanyListFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false })
}

const deleteCompanyListSuccess = (state, action) => {
  const filteredData = state.data.filter(startup => {
    return startup._id !== action.id
  })
  return updateObject(state, { data: filteredData })
}

const updateCompanyListSuccess = (state, action) => {
  const updatedList = state.data.map(startup => {
    if (startup._id === action.updatedCompany._id) {
      return action.updatedCompany
    }
    return startup
  })
  return updateObject(state, { data: updatedList })
}

const selectCompany = (state, action) => {
  const updatedList = state.data.map(startup => {
    if (startup._id === action.id) {
      startup.checked = action.checked
    }
    return startup
  })
  return updateObject(state, { data: updatedList })
}

const selectAllCompanies = (state, action) => {
  const updatedList = state.data.map(startup => {
    startup.checked = action.checked
    return startup
  })
  return updateObject(state, { data: updatedList })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECT_ALL_COMPANIES:
      return selectAllCompanies(state, action)
    case actionTypes.SELECT_COMPANY:
      return selectCompany(state, action)
    case actionTypes.FETCH_COMPANY_LIST_START:
      return fetchCompanyListStart(state, action)
    case actionTypes.FETCH_COMPANY_LIST_SUCCESS:
      return fetchCompanyListSuccess(state, action)
    case actionTypes.FETCH_COMPANY_LIST_FAIL:
      return fetchCompanyListFail(state, action)
    case actionTypes.DELETE_COMPANY_LIST_SUCCESS:
      return deleteCompanyListSuccess(state, action)
    case actionTypes.UPDATE_COMPANY_LIST_SUCCESS:
      return updateCompanyListSuccess(state, action)
    default:
      return state
  }
}

export default reducer
