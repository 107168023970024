import {
  FETCH_COMPANIES_CREATED_UPDATED_START,
  FETCH_COMPANIES_CREATED_UPDATED_SUCCESS,
  FETCH_COMPANIES_CREATED_UPDATED_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchCompaniesCreatedUpdatedStart = () => ({
  type: FETCH_COMPANIES_CREATED_UPDATED_START,
})

const fetchCompaniesCreatedUpdatedSuccess = companiesCreatedUpdated => ({
  type: FETCH_COMPANIES_CREATED_UPDATED_SUCCESS,
  payload: companiesCreatedUpdated,
})

const fetchCompaniesCreatedUpdatedFail = errorMessage => ({
  type: FETCH_COMPANIES_CREATED_UPDATED_FAIL,
  payload: errorMessage,
})

export const fetchCompaniesCreatedUpdated = (startDate, endDate) => {
  return async dispatch => {
    try {
      dispatch(fetchCompaniesCreatedUpdatedStart())

      const { data } = await axios.get('/api/statistics/companies-created-updated', {
        params: { startDate, endDate },
      })

      dispatch(fetchCompaniesCreatedUpdatedSuccess(data))
    } catch (error) {
      dispatch(fetchCompaniesCreatedUpdatedFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
