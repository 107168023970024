import React from 'react'
import { Grid, TextField, Typography, Box } from '@material-ui/core'
import FundingRoundsForm from './FundingRoundsForm'

export default function FundingForm ({ data, onInputChange, allowEdit, history, collectionToUse }) {
  if (!allowEdit) {
    return <ReadOnlyFrom data={ data } />
  }

  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 4 }>
        <Box display='flex' alignItems='end'>
          <TextField
            onChange={ onInputChange }
            label='Last Funding Amount Dollars (USD)'
            placeholder='number'
            fullWidth
            margin='normal'
            type='number'
            name='last_funding_amount_dollars'
            defaultValue={ data.last_funding_amount_dollars }
          />
          <Typography gutterBottom>$</Typography>
        </Box>
      </Grid>

      <Grid item xs={ 12 } md={ 4 }>
        <Box display={ 'flex' } alignItems={ 'end' }>
          <TextField
            onChange={ onInputChange }
            label='Total Funding Amount Dollars (USD)'
            type='number'
            fullWidth
            margin='normal'
            name='total_funding_amount_dollars'
            defaultValue={ data.total_funding_amount_dollars }
          />
          <Typography gutterBottom>$</Typography>
        </Box>
      </Grid>

      <Grid item xs={ 12 } md={ 4 }>
        <TextField
          onChange={ onInputChange }
          label='Number of Investors'
          fullWidth
          margin='normal'
          type='number'
          name='number_of_investors'
          defaultValue={ data.number_of_investors }
        />
      </Grid>

      <Grid item xs={ 12 }>
        <FundingRoundsForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ data } history={ history } collectionToUse={ collectionToUse } />
      </Grid>
    </Grid>
  )
}

function ReadOnlyFrom ({ data }) {
  return (
    <Grid container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Last funding amount dollars: </b> { data.last_funding_amount_dollars }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Total funding amount dollars: </b> { data.total_funding_amount_dollars }
        </Typography>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          <b>Number of investors: </b> { data.number_of_investors }
        </Typography>
      </Grid>
    </Grid>
  )
}
