import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'
import BreadCrumb from '../components/Navigation/BreadCrumbs/BreadCrumbs'
import { useAuth0 } from '../hoc/Auth0/react-auth0-spa'

const ProfilePage = () => {
  const { user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority

  return (
    <Fragment>
      <BreadCrumb />

      <Typography variant='h5'>Your priority: { userPriority }</Typography>
    </Fragment>
  )
}

export default ProfilePage
