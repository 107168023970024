import React from 'react'
import { Box } from '@material-ui/core'
import ChartWidget from '../../../components/Widgets/ChartWidget'
import BarChart from '../../../components/UI/Charts/BarChart'

const HistogramChart = ({ title, data, yDataKey, xDataKey }) => {
  return (
    <Box style={ { marginBottom: 40 } }>
      <ChartWidget title={ title }>
        <BarChart
          height={ 270 }
          width='100%'
          data={ data }
          yDataKey={ yDataKey }
          xDataKey={ xDataKey }
        />
      </ChartWidget>
    </Box>
  )
}

export default HistogramChart
