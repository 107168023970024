import React, { Fragment, useEffect } from 'react'
import BreadCrumb from '../components/Navigation/BreadCrumbs/BreadCrumbs'
import DashboardWidget from '../components/Widgets/DashboardWidget'
import StartupTable from '../components/Startup/Tables/StartupTable'
import { makeStyles, Typography, Box, Grid, IconButton, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'
import * as companyListActions from '../store/actions/companyList'
import * as dashboardStatsActions from '../store/actions/dashboardStats'

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const currentMonth = new Date().getMonth()

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
}))

const Dashboard = ({ fetchLastUpdatedCompanies, fetchDashboardStats, dashboardStats }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  useEffect(() => {
    fetchDashboardStats(enqueueSnackbar)
  }, [fetchDashboardStats, enqueueSnackbar])

  useEffect(() => {
    fetchLastUpdatedCompanies()
  }, [fetchLastUpdatedCompanies])

  return (
    <Fragment>
      <BreadCrumb />

      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 3 }>
          <DashboardWidget title='Startups' chip='Total' widgetColor='primary' data={ dashboardStats.startups } />
        </Grid>

        <Grid item xs={ 12 } sm={ 6 } md={ 6 } lg={ 3 }>
          <DashboardWidget title='Data Points' chip='Total' widgetColor='secondary' data={ dashboardStats.dataPoints } />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
          <DashboardWidget title='New Startups' chip={ monthNames[currentMonth] } widgetColor='primary' data={ dashboardStats.newStartups } />
        </Grid>

        <Grid item xs={ 12 } sm={ 12 } md={ 6 } lg={ 3 }>
          <DashboardWidget title='Updated Startups' chip={ monthNames[currentMonth] } widgetColor='secondary' data={ dashboardStats.updatedStartups } />
        </Grid>

        <Grid item xs={ 12 }>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h6'>Latest updated Startups</Typography>

            <Tooltip title='Go to search database' placement='left' arrow classes={ { tooltip: classes.tooltip } }>
              <IconButton aria-label='search' component={ Link } to='/database/search'>
                <SearchIcon color='primary' />
              </IconButton>
            </Tooltip>
          </Box>

          <StartupTable />
        </Grid>
      </Grid>
    </Fragment>
  )
}
const mapStateToProps = state => ({
  dashboardStats: state.dashboardStats.stats,
})

const mapDispatchToProps = {
  fetchLastUpdatedCompanies: companyListActions.fetchLastUpdatedCompanies,
  fetchDashboardStats: dashboardStatsActions.fetchDashboardStats,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
