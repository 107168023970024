import {
  FETCH_ALIAS_CONFLICT_START,
  FETCH_ALIAS_CONFLICT_SUCCESS,
  FETCH_ALIAS_CONFLICT_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  conflicts: [],
  isFetching: true,
  errorMessage: '',
}

const aliasConflictReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ALIAS_CONFLICT_START:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      }
    case FETCH_ALIAS_CONFLICT_SUCCESS:
      return {
        ...state,
        conflicts: action.payload,
        isFetching: false,
        errorMessage: '',
      }
    case FETCH_ALIAS_CONFLICT_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}

export default aliasConflictReducer
