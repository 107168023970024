import {
  FETCH_SOURCES_START,
  FETCH_SOURCES_SUCCESS,
  FETCH_SOURCES_FAIL,
} from './actionTypes.js'

import axios from '../../axios'

export const fetchSourcesStart = () => ({
  type: FETCH_SOURCES_START,
})

export const fetchSourcesSuccess = scoresStats => ({
  type: FETCH_SOURCES_SUCCESS,
  payload: scoresStats,
})

export const fetchSourcesFail = errorMessage => ({
  type: FETCH_SOURCES_FAIL,
  payload: errorMessage,
})

export const fetchSources = () => {
  return async dispatch => {
    try {
      dispatch(fetchSourcesStart())

      const { data } = await axios.get('/api/company/sources')

      dispatch(fetchSourcesSuccess(data))
    } catch (error) {
      dispatch(fetchSourcesFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
