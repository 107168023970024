import {
  REGISTER_EVENT_NOTIFICATION_START,
  REGISTER_EVENT_NOTIFICATION_SUCCESS,
  REGISTER_EVENT_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  newEventNotification: [],
  newEventNotificationFetching: true,
  newEeventNoticationErrorMessage: '',
}

const registerEventNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_EVENT_NOTIFICATION_START:
      return {
        ...state,
        newEventNotificationFetching: true,
        newEventNoticationErrorMessage: '',
      }
    case REGISTER_EVENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        newEventNotification: action.payload,
        newEventNotificationFetching: false,
        newEventNoticationErrorMessage: '',
      }
    case REGISTER_EVENT_NOTIFICATION_FAIL:
      return {
        ...state,
        newEventNotificationFetching: false,
        newEventNoticationErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default registerEventNotificationReducer
