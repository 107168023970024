import React, { useEffect, useRef, useState } from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Sectors from '../../../Constants/Sectors'
import countryList from '../../../Constants/Countries'
import _ from 'lodash'

function AtlasChart ({ filterField, filterLabel, chartId, height = '500px', width }) {

  const sdk = new ChartsEmbedSDK({
    baseUrl: window._env_.MONGO_CHARTS_BASE_URL,
    getUserToken: () => sessionStorage.getItem('token'),
  })
  const chartDiv = useRef(null)
  const [rendered, setRendered] = useState(false)
  const [filter, setFilter] = useState()
  const [chart] = useState(sdk.createChart(
    {
      chartId: chartId,
      height: height,
      width: width,
      // background: 'transparent',
      theme: JSON.parse(localStorage.getItem('darkMode')) ? 'dark' : 'light',
    },
  ))

  useEffect(() => {
    // eslint-disable-next-line no-console
    chart.render(chartDiv.current).then(() => setRendered(true)).catch(err => console.log('Error during Charts rendering.', err))
  }, [chart])

  useEffect(() => {
    if (rendered && filter) {
      chart.setFilter(filter).catch(err => {
        // eslint-disable-next-line no-console
        console.log('Error while filtering.', err)
        setFilter(null)
      })
    }
  }, [chart, filter, rendered])

  const handleOnChangeDebounce = _.debounce(function (newValue) {
    if (newValue) setFilter({ [filterField]: newValue })
  }, 1000)

  const buildFilterInput = () => {
    switch (filterField) {
      case 'hq_country':
        return (
          <Autocomplete
            options={ countryList }
            renderInput={ params => <TextField { ...params } label={ filterLabel || filterField } variant='outlined' fullWidth /> }
            onChange={ (event, newValue) => { if (newValue) setFilter({ [filterField]: newValue }) } }
          />

        )
      case 'sectors':
        return (
          <Autocomplete
            options={ Sectors }
            renderInput={ params => <TextField { ...params } label={ filterLabel || filterField } variant='outlined' fullWidth /> }
            onChange={ (event, newValue) => { if (newValue) setFilter({ [filterField]: newValue }) } }
          />
        )
      default:
        return (
          <TextField
            style={ { width: '100%' } }
            variant='outlined'
            label={ filterLabel || filterField }
            onChange={ event => handleOnChangeDebounce(event.target.value) }
          ></TextField>
        )
    }
  }

  return (
    <>
      {
        rendered && filterField && buildFilterInput()
      }
      <div ref={ chartDiv } />
    </>
  )
}

export default AtlasChart
