import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumbs from '../../components/Navigation/BreadCrumbs/BreadCrumbs'
import CenterBox from '../../components/UI/CenterBox'
import CircularSpinner from '../../components/UI/Progress/CircularSpinner'
import { Tooltip, IconButton, Box, Container, Table, TableHead, TableBody, TableCell, TableRow, Paper, Collapse, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchEventNotifications, subscribeAllEventNotificiation, unsubscribeAllEventNotificiation } from '../../store/actions/eventNotification'
import SearchIcon from '@material-ui/icons/Search'
import { useAuth0 } from '../../hoc/Auth0/react-auth0-spa'
import RecipientList from './RecipientList'
import LogsSearchBar from './LogsSearchBar'

const useStyles = makeStyles(theme => ({
  chip: {
    backgroundColor: '#5c6bc0',
    color: 'white',
    margin: theme.spacing(0.3),
  },
  tooltip: {
    fontSize: '1.1em',
    maxWidth: 600,
  },
  circle: {
    border: '2px solid red',
    backgroundColor: '#FFFFFF',
    height: '100px',
    borderRadius: '50%',
    width: '10px',
  },
  removeIconButton: {
    height: '25px',
  },
  endpoint: {
    display: 'block',
    maxWidth: '400px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}))

const LogsNotificationPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { user } = useAuth0()

  const eventNotifications = useSelector(state => state.eventNotifications)
  let { eventNotificationsData, eventNotificationsFetching, eventNoticationsErrorMessage } = eventNotifications

  const isUserSignedUpForAll = recipeintGroup => {
    for (const notification of eventNotificationsData) {
      if (!notification[recipeintGroup].some(x => x === user.email)) {
        return false
      }
    }
    return true
  }

  const [collapseOpen, setCollapseOpen] = useState(false)
  const [sucessCheckboxChecked, setSuccesCheckboxChecked] = useState(() => isUserSignedUpForAll('successRecipients'))
  const [failCheckboxChecked, setFailCheckboxChecked] = useState(() => isUserSignedUpForAll('failRecipients'))
  const [idleCheckboxChecked, setIdleCheckboxChecked] = useState(() => isUserSignedUpForAll('idleRecipients'))

  const handleSuccesCheckboxClick = () => {
    if (sucessCheckboxChecked) {
      dispatch(unsubscribeAllEventNotificiation(user.email, 'successRecipients'))
    } else {
      dispatch(subscribeAllEventNotificiation(user.email, 'successRecipients'))
    }

    setSuccesCheckboxChecked(!sucessCheckboxChecked)
  }
  const handleFailCheckboxClick = () => {
    if (failCheckboxChecked) {
      dispatch(unsubscribeAllEventNotificiation(user.email, 'failRecipients'))
    } else {
      dispatch(subscribeAllEventNotificiation(user.email, 'failRecipients'))
    }
    setFailCheckboxChecked(!failCheckboxChecked)
  }

  const handleIdleCheckboxClick = () => {
    if (idleCheckboxChecked) {
      dispatch(unsubscribeAllEventNotificiation(user.email, 'idleRecipients'))
    } else {
      dispatch(subscribeAllEventNotificiation(user.email, 'idleRecipients'))
    }
    setIdleCheckboxChecked(!idleCheckboxChecked)
  }

  useEffect(() => {
    dispatch(fetchEventNotifications())
  }, [])

  return (
    <div>
      <BreadCrumbs />

      <Box display='flex' justifyContent='flex-end'>
        <IconButton onClick={ () => setCollapseOpen(!collapseOpen) }>
          <Tooltip classes={ { tooltip: classes.tooltip } } title='Toggle search' placement='top'>
            <SearchIcon fontSize='large' />
          </Tooltip>
        </IconButton>
      </Box>

      <Collapse in={ collapseOpen }>
        <LogsSearchBar />
      </Collapse>

      { eventNotificationsFetching ?
        <Container fixed>
          <CenterBox height='40vh'>
            <CircularSpinner size={ 150 } />
          </CenterBox>
        </Container>
        :
        eventNoticationsErrorMessage ?
          <Container fixed>
            <CenterBox height='40vh'>
              <div>{ eventNoticationsErrorMessage }</div>
            </CenterBox>
          </Container>
          :
          <Paper elevation={ 1 } >
            <Table size='small' aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell width='25%' align='center'>Endpoint </TableCell>
                  <TableCell width='20%' align='center'><Box display='flex' alignItems='center' justifyContent='center'>Success <Checkbox checked={ sucessCheckboxChecked } onChange={ handleSuccesCheckboxClick } /></Box> </TableCell>
                  <TableCell width='20%' align='center'><Box display='flex' alignItems='center' justifyContent='center'>Idle <Checkbox checked={ idleCheckboxChecked } onChange={ handleIdleCheckboxClick } /></Box>  </TableCell>
                  <TableCell width='20%' align='center'><Box display='flex' alignItems='center' justifyContent='center'>Fail <Checkbox checked={ failCheckboxChecked } onChange={ handleFailCheckboxClick } /></Box> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { eventNotificationsData.map((eventNotification, index) => {
                  return (
                    <TableRow key={ index } maxWidth='25%'>
                      <TableCell align='start'>
                        <Tooltip classes={ { tooltip: classes.tooltip } } title={ eventNotification.endpoint } placement='top'>
                          <div className={ classes.endpoint }>
                            { eventNotification.endpoint }
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='start' maxWidth='25%' >
                        <RecipientList recipients={ eventNotification.successRecipients } currentUser={ user } notificationID={ eventNotification._id } recipientGroup={ 'successRecipients' }></RecipientList>
                      </TableCell>
                      <TableCell align='start' maxWidth='25%'>
                        <RecipientList recipients={ eventNotification.idleRecipients } currentUser={ user } notificationID={ eventNotification._id } recipientGroup={ 'idleRecipients' }></RecipientList>
                      </TableCell>
                      <TableCell align='start' maxWidth='25%'>
                        <RecipientList recipients={ eventNotification.failRecipients } currentUser={ user } notificationID={ eventNotification._id } recipientGroup={ 'failRecipients' }></RecipientList>
                      </TableCell>
                    </TableRow>
                  )
                }) }
              </TableBody>
            </Table>
          </Paper>
      }
    </div >
  )
}

export default LogsNotificationPage
