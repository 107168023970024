import {
  FETCH_COMPANIES_BY_COUNTRY_START,
  FETCH_COMPANIES_BY_COUNTRY_SUCCESS,
  FETCH_COMPANIES_BY_COUNTRY_FAIL,
} from './actionTypes'

import axios from '../../axios'

export const fetchCompaniesByCountryStart = () => ({
  type: FETCH_COMPANIES_BY_COUNTRY_START,
})

export const fetchCompaniesByCountrySuccess = companiesByCountry => ({
  type: FETCH_COMPANIES_BY_COUNTRY_SUCCESS,
  payload: companiesByCountry,
})

export const fetchCompaniesByCountryFail = errorMessage => ({
  type: FETCH_COMPANIES_BY_COUNTRY_FAIL,
  payload: errorMessage,
})

export const fetchCompaniesByCountry = () => {
  return async dispatch => {
    try {
      dispatch(fetchCompaniesByCountryStart())

      const { data } = await axios.get('/api/statistics/companies-by-country')

      dispatch(fetchCompaniesByCountrySuccess(data))
    } catch (error) {
      dispatch(fetchCompaniesByCountryFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
