import React, { createRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Card, Typography, Tooltip } from '@material-ui/core'
import CircularSpinner from '../components/UI/Progress/CircularSpinner'
import CenterBox from '../components/UI/CenterBox'
import { updateCompany } from '../store/actions/company'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    paddingBottom: theme.spacing(4),
  },
  innerCard: {
    minHeight: 150,
    width: 500,
    cursor: 'pointer',
    textAlign: 'center',
    padding: 22,
    paddingBottom: 15,
    fontSize: '1.1rem',
    boxShadow: '1px 5px 20px #888888',
    '&:hover': {
      transitionDuration: '0.25s',
      backgroundColor: theme.isDarkMode ? '#363636' : '#b0b0b0',
    },
  },
  outerCard: {
    padding: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '2.5rem',
  },
}))

const AliasConflict = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  const aliasConflicts = useSelector(state => state.aliasConflicts)
  const { conflicts, isFetching, errorMessage } = aliasConflicts

  const gridRef = createRef()
  const containerRefObj = {}

  return (
    <Container className={ classes.root }>
      { isFetching ?
        <Container fixed>
          <CenterBox height='40vh'>
            <CircularSpinner size={ 150 } />
          </CenterBox>
        </Container>
        :
        errorMessage ? <h2>{ errorMessage }</h2> :
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } ref={ gridRef }>
              { conflicts.map((conflict, index) => {
                containerRefObj[index] = createRef()

                return <Container key={ index } ref={ containerRefObj[index] }>
                  <Typography variant='h6' align='center'>
                    { conflict.path[0] }
                  </Typography>

                  <Card xs={ 6 } className={ classes.outerCard } elevation={ 5 }>
                    <div
                      className={ classes.innerCard }
                      data-existing={ conflict.path[0] === 'name' ? [conflict.lhs, conflict.rhs] : conflict.lhs }
                      onClick={ e => {
                        if (conflict.path[0] === 'name' && gridRef.current.children.length > 2) {
                          return enqueueSnackbar('Name must be updated last. Please update the other fields first', { variant: 'warning' })
                        }

                        dispatch(updateCompany({
                          _id: conflict.companyId,
                          [conflict.path[0]]: e.target.getAttribute('data-existing'),
                        }, null, 'companies', enqueueSnackbar, null, false, null, conflict.path[0] === 'name', false))

                        containerRefObj[index].current.remove()
                      } }
                      style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }
                    >
                      <div data-existing={ conflict.path[0] === 'name' ? [conflict.lhs, conflict.rhs] : conflict.lhs } style={ { wordWrap: 'break-word' } }>
                        <b>Existing</b> <br /> { Array.isArray(conflict.lhs) ? `[ ${ conflict.lhs.join(', ') } ]` : typeof conflict.lhs === 'number' && conflict.path[0] !== 'founded_date' ? conflict.lhs.toLocaleString() : conflict.lhs }
                      </div>

                      <Tooltip title='Priority' placement='right' arrow style={ { marginLeft: '98%' } }>
                        <Typography>{ conflict.companyToBeUpdatedPriority }</Typography>
                      </Tooltip>
                    </div>

                    <div
                      className={ classes.innerCard }
                      data-incoming={ conflict.path[0] === 'name' ? [conflict.rhs, conflict.lhs] : conflict.rhs }
                      onClick={ e => {
                        if (conflict.path[0] === 'name' && gridRef.current.children.length > 2) {
                          return enqueueSnackbar('Name must be updated last. Please update the other fields first', { variant: 'warning' })
                        }

                        dispatch(updateCompany({
                          _id: conflict.companyId,
                          [conflict.path[0]]: e.target.getAttribute('data-incoming'),
                        }, null, 'companies', enqueueSnackbar, null, false, null, conflict.path[0] === 'name', false))

                        containerRefObj[index].current.remove()
                      } }
                      style={ { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' } }
                    >
                      <div data-incoming={ conflict.path[0] === 'name' ? [conflict.rhs, conflict.lhs] : conflict.rhs } style={ { wordWrap: 'break-word' } }>
                        <b>Incoming</b> <br /> { Array.isArray(conflict.rhs) ? `[ ${ conflict.rhs.join(', ') } ]` : typeof conflict.rhs === 'number' && conflict.path[0] !== 'founded_date' ? conflict.rhs.toLocaleString() : conflict.rhs }
                      </div>

                      <Tooltip title='Priority' placement='right' arrow style={ { marginLeft: '98%' } }>
                        <Typography>{ conflict.existingCompanyPriority }</Typography>
                      </Tooltip>
                    </div>
                  </Card>
                </Container>
              },
              ) }
            </Grid>
          </Grid>
      }
    </Container >
  )
}

export default AliasConflict
