import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, InputBase, Collapse, Grid, TextField, IconButton, Container, FormControl, InputLabel, Select, MenuItem, Tooltip, Button } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutline'
import TuneIcon from '@material-ui/icons/Tune'

import BreadCrumb from '../components/Navigation/BreadCrumbs/BreadCrumbs'
import ExportCSV from '../components/Startup/Widget/ExportCSV'
import StartupTable from '../components/Startup/Tables/StartupTable'
import PaginationWidget from '../components/Widgets/Pagination/PaginationWidget'
import SectorsList from '../Constants/Sectors'
import CountriesList from '../Constants/Countries'
import YearPicker from '../components/UI/Forms/YearPicker'
import MultiSelectAutocomplete from '../components/UI/Forms/MultiSelectAutocomplete'
import SubSectorsAutocomplete from '../components/Startup/Forms/Inputs/SubSectorsAutocomplete'

import { connect } from 'react-redux'
import * as companyListActions from '../store/actions/companyList'
import * as paginationActions from '../store/actions/pagination'
import { fetchCollectionNames, setCollectionToUse } from '../store/actions/collectionNames'
import { fetchSources } from '../store/actions/sources'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 0,
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tooltip: {
    fontSize: '.9em',
    maxWidth: 400,
  },
}))

function SearchDatabase ({ totalCount, currentPage, pageSize, queryParams, fetchSearchResults, setQueryParams, resetPagination, collectionNamesData, sources, getCollectionNames, getSources, setCollectionToUse, collectionToUse }) {
  const classes = useStyles()
  const initState = {
    name: '',
    sectors: [],
    subsectors: [],
    sources: [],
    hq_country: [],
    hq_city: '',
    founded_date: '',
    description: '',
    priority: '',
  }

  const [formData, setFormData] = useState(initState)
  const [open, setOpen] = useState(true)
  const [pickedDate, setPickedDate] = useState(0)

  useEffect(() => {
    return () => {
      resetPagination()
    }
  }, [resetPagination])

  useEffect(() => {
    getCollectionNames()
    getSources()
    fetchSearchResults(collectionToUse)
  }, [currentPage, pageSize, queryParams, fetchSearchResults, getCollectionNames, getSources, collectionToUse])

  const handleOpen = () => setOpen(!open)

  function onInputChange (event) {
    const name = event.target.name
    let value = null

    if (event.target.type === 'number') {
      value = +event.target.value
    } else {
      value = event.target.value
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  function onAutocompleteChange (name, value) {
    const event = {
      target: {
        name: name,
        value: value,
      },
    }

    onInputChange(event)
  }

  const searchClickHandeler = formData => setQueryParams(formData)

  function handleKeyPress (event) {
    if (event.key === 'Enter') {
      searchClickHandeler(formData)
    }
  }

  const handleDate = e => {
    setPickedDate(e.target.value)
    setCollectionToUse(collectionNamesData.collectionNames[e.target.value])
  }

  return (
    <Fragment>
      <BreadCrumb />

      <Container className={ classes.root }>
        { collectionNamesData.collectionNames.length > 0 && <Box marginBottom={ 1 } display='flex' justifyContent='flex-end'>
          <FormControl className={ classes.formControl }>
            <InputLabel id='select-label'>Historic</InputLabel>

            <Select
              labelId='select-label'
              id='select'
              value={ pickedDate }
              onChange={ handleDate }
            >
              { collectionNamesData.collectionNames.map((collName, index) => <MenuItem key={ index } value={ index }>{ collName.toLowerCase() === 'companies' ? 'Live' : `${ collName.split('_')[2] }/${ collName.split('_')[1] }` }</MenuItem>) }
            </Select>
          </FormControl>
        </Box> }

        <Box display='flex' justifyContent='flex-end'>
          <InputBase
            type='text'
            name='name'
            onChange={ onInputChange }
            value={ formData.name }
            onKeyPress={ handleKeyPress }
            placeholder='Seach Company...'
            autoComplete='off'
          />

          <Tooltip title='Search' placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
            <Button
              style={ { height: '2.3rem', marginTop: '6px' } }
              variant='contained'
              color='secondary'
              size='medium'
              endIcon={ <SearchIcon color='primary' /> }
              onClick={ () => searchClickHandeler(formData) }
            >
              Search
            </Button>
          </Tooltip>

          <Tooltip title='Clear all search filters' placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
            <IconButton className={ classes.button } aria-label='Clear All' onClick={ () => setFormData(initState) }>
              <DeleteOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip>

          <Tooltip title='Toggle search filters' placement='bottom' arrow classes={ { tooltip: classes.tooltip } }>
            <IconButton aria-label='Advanced Search' onClick={ handleOpen }>
              <TuneIcon color='primary' />
            </IconButton>
          </Tooltip>

          <ExportCSV />
        </Box>

        <Collapse in={ open }>
          <Box paddingRight={ 5 } paddingLeft={ 5 } paddingBottom={ 1 }>
            <Grid className={ classes.formWrapper } onKeyPress={ handleKeyPress } container spacing={ 1 }>
              <Grid item xs={ 12 } md={ 4 }>
                <MultiSelectAutocomplete
                  multiple={ true }
                  options={ SectorsList }
                  value={ formData.sectors }
                  onChange={ sectors => onAutocompleteChange('sectors', sectors) }
                  label='Industries'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={ 12 } md={ 4 }>
                <SubSectorsAutocomplete value={ formData.subsectors } onChange={ value => onAutocompleteChange('subsectors', value) } />
              </Grid>

              <Grid item xs={ 12 } md={ 4 }>
                <MultiSelectAutocomplete
                  multiple={ false }
                  options={ sources.sources }
                  value={ formData.sources }
                  onChange={ sources => onAutocompleteChange('sources', sources) }
                  label='Sources'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <MultiSelectAutocomplete
                  multiple={ true }
                  options={ CountriesList }
                  value={ formData.hq_country }
                  onChange={ countries => onAutocompleteChange('hq_country', countries) }
                  label='HQ Countries'
                  margin='dense'
                />
              </Grid>

              <Grid item xs={ 6 } md={ 3 }>
                <TextField
                  label='City'
                  name='hq_city'
                  value={ formData.hq_city }
                  fullWidth
                  margin='dense'
                  onChange={ onInputChange }
                  autoComplete='off'
                  inputProps={ {
                    autoComplete: 'new-password',
                  } }
                />
              </Grid>

              <Grid item xs={ 6 } md={ 3 }>
                <YearPicker name='founded_date' value={ formData.founded_date } onChange={ onInputChange } margin='dense' />
              </Grid>

              <Grid item xs={ 9 }>
                <TextField
                  label='First keyword, Second keyword, Third keyword'
                  fullWidth
                  name='description'
                  value={ formData.description }
                  onChange={ onInputChange }
                  margin='dense'
                  autoComplete='off'
                />
              </Grid>

              <Grid item xs={ 6 } md={ 3 }>
                <FormControl style={ { marginTop: 5 } } fullWidth>
                  <InputLabel id='select-helper-label'>Priority</InputLabel>

                  <Select
                    labelId='select-helper-label'
                    id='select-helper'
                    name='priority'
                    value={ formData.priority }
                    onChange={ onInputChange }
                  >
                    <MenuItem value=''>Reset Priority</MenuItem>

                    { [0, 1, 2, 3, 4].map((priority, index) => <MenuItem key={ index } value={ priority }>{ priority }</MenuItem>) }
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Collapse>

        <StartupTable />

        { totalCount > pageSize && <PaginationWidget /> }
      </Container>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  companyList: state.companyList.data,
  currentPage: state.pagination.currentPage,
  pageSize: state.pagination.pageSize,
  queryParams: state.pagination.queryParams,
  totalCount: state.pagination.totalCount,
  collectionNamesData: state.collectionNames,
  sources: state.sources,
  collectionToUse: state.collectionToUse.collectionToUse,
})

const mapDispatchToProps = {
  fetchSearchResults: companyListActions.fetchSearchResults,
  setQueryParams: paginationActions.setQueryParams,
  resetPagination: paginationActions.resetPagination,
  getCollectionNames: fetchCollectionNames,
  getSources: fetchSources,
  setCollectionToUse,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchDatabase)
