import axios from 'axios'
import Qs from 'qs'

const instance = axios.create({
  baseURL: window._env_.BACKEND_BASE_URL,
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: 'repeat' })
  },
})

instance.defaults.headers.common['Accept'] = 'application/json'
instance.defaults.headers.common['Content-Type'] = 'application/json'
export default instance
