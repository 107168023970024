import {
  FETCH_SCORES_STATS_START,
  FETCH_SCORES_STATS_SUCCESS,
  FETCH_SCORES_STATS_FAIL,
} from './actionTypes.js'

import axios from '../../axios'

export const fetchScoresStatsStart = () => ({
  type: FETCH_SCORES_STATS_START,
})

export const fetchScoresStatsSuccess = scoresStats => ({
  type: FETCH_SCORES_STATS_SUCCESS,
  payload: scoresStats,
})

export const fetchScoresStatsFail = errorMessage => ({
  type: FETCH_SCORES_STATS_FAIL,
  payload: errorMessage,
})

export const fetchScoresStats = () => {
  return async dispatch => {
    try {
      dispatch(fetchScoresStatsStart())

      const { data } = await axios.get('/api/statistics/scores')

      dispatch(fetchScoresStatsSuccess(data))
    } catch (error) {
      dispatch(fetchScoresStatsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
