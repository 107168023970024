import React from 'react'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
    width: '100rem',
  },
  paper: {
    padding: theme.spacing(1),
  },
  hover: {
    cursor: 'pointer',
  },
}))

export default function HoverPopover (props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  function validateUrl (website) {
    let portValidationRegex = new RegExp('https?')
    let domainValidationRegex = new RegExp('(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)')
    let isValidPort = false
    let isValidDomain = false
    isValidPort = portValidationRegex.test(website)
    isValidDomain = domainValidationRegex.test(website)

    if (isValidPort && isValidDomain) {
      return website
    } else if (!isValidPort && isValidDomain) {
      return `https://${ website }`
    } else if (!isValidPort && !isValidDomain) {
      return null
    }
  }

  return (
    <>
      { props.website ? (
        <Link href={ validateUrl(props.website) } target='_blank' rel='noopener noreferrer'>
          <Typography
            aria-owns={ open ? 'mouse-over-popover' : undefined }
            aria-haspopup='true'
            onMouseEnter={ handlePopoverOpen }
            onMouseLeave={ handlePopoverClose }
            className={ classes.hover }
            variant='inherit'
          >
            { props.name }
          </Typography>
        </Link>
      ) : (
        <Typography
          aria-owns={ open ? 'mouse-over-popover' : undefined }
          aria-haspopup='true'
          onMouseEnter={ handlePopoverOpen }
          onMouseLeave={ handlePopoverClose }
          className={ classes.hover }
          variant='inherit'
        >
          { props.name }
        </Typography>
      ) }

      { props.content !== '' && open && (
        <Popover
          // id={id}
          className={ classes.popover }
          classes={ {
            paper: classes.paper,
          } }
          open={ open }
          anchorEl={ anchorEl }
          anchorOrigin={ {
            vertical: 'bottom',
            horizontal: 'left',
          } }
          transformOrigin={ {
            vertical: 'top',
            horizontal: 'left',
          } }
          onClose={ handlePopoverClose }
          disableRestoreFocus
        >
          { props.content }
        </Popover>
      ) }
    </>
  )
}
