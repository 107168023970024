import * as actionTypes from './actionTypes'
import axios from '../../axios'

export const fetchDashboardStatsFail = error => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_DASHBOARD_STATS_FAIL,
      error: error,
    })
  }
}

export const fetchDashboardStatsStart = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_DASHBOARD_STATS_START,
    })
  }
}

export const fetchDashboardStatsSuccess = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_DASHBOARD_STATS_SUCCESS,
      stats: data,
    })
  }
}

export const fetchDashboardStats = enqueueSnackbar => {
  return dispatch => {
    dispatch(fetchDashboardStatsStart())
    axios
      .get('/api/dashboard/stats')
      .then(response => {
        dispatch(fetchDashboardStatsSuccess(response.data))
      })
      .catch(err => {
        dispatch(fetchDashboardStatsFail(err.message))
        enqueueSnackbar(err.message, { variant: 'error' })
      })
  }
}
