import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, LabelList } from 'recharts'
import CircularSpinner from '../Progress/CircularSpinner'
import { Box } from '@material-ui/core'
import ErrorMessage from '../../Messages/ErrorMessage'
import NoDataMessage from '../../Messages/NoDataMessage'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import Numeral from 'numeral'

Numeral.register('locale', 'funding', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
})

Numeral.locale('funding')

const CustomTooltip = ({ active, payload, label, totalCount }) => active ?
  <TooltipBody payload={ payload } label={ label } totalCount={ totalCount } /> : null

const TooltipBody = React.memo(({ payload, label, totalCount }) => {
  const shorterNumber = labelValue => Numeral(labelValue).format('0.0a')

  const calculatePrecentage = () => {
    const presentage = (payload[0].value / totalCount) * 100
    const roundedValue = Math.round(presentage * 100) / 100

    return roundedValue
  }

  return (
    <Paper elevation={ 3 } style={ { padding: '1rem' } }>
      <p>{ label }</p>
      <p>{ `${ payload[0].name.charAt(0).toUpperCase() + payload[0].name.slice(1) } : ${ payload[0].value }` }</p>
      { payload[0].payload.fundingAmount && <p>Total Funding Amount: { shorterNumber(payload[0].payload.fundingAmount) }</p> }
      { totalCount && <p>Percentage of total : { calculatePrecentage() }%</p> }
    </Paper>
  )
}, (prevLabel, nextLabel) => prevLabel.label === nextLabel.label)

const BarChartStyled = props => {
  const theme = useTheme()
  const style = {
    width: props.width,
    height: props.height,
  }

  if (props.error) {
    return <ErrorMessage errorMessage='Unable to fetch Data Points' />
  }

  if (props.data && props.data.length === 0) return <NoDataMessage />

  return (
    <div style={ style }>
      { props.data ?
        <ResponsiveContainer>
          <BarChart data={ props.data } margin={ { top: 10 } }>
            <defs>
              <linearGradient id='verticalGradient' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor={ theme.palette.secondary.main } stopOpacity={ 0.5 } />
                <stop offset='95%' stopColor={ theme.palette.secondary.main } stopOpacity={ 0.1 } />
              </linearGradient>
            </defs>

            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey={ props.xDataKey } tick={ false } />
            <YAxis dataKey={ props.yDataKey } />

            <Tooltip
              content={ <CustomTooltip totalCount={ props.totalCount } /> }
              cursor={ { fill: 'rgb(117,117,117,0.2)' } }
              contentStyle={ {
                backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
                color: theme.palette.type === 'dark' ? '#fff' : '#000',
              } }
              itemStyle={ {
                color: theme.palette.type === 'dark' ? '#fff' : '#000',
              } }
            />

            <Bar
              dataKey={ props.yDataKey }
              fill='url(#verticalGradient)'
              stroke={ theme.palette.secondary.main }
              strokeOpacity={ 0.5 }
            >
              <LabelList
                dataKey={ props.yDataKey }
                position='top'
                formatter={ v => v > 999 ? Numeral(v).format('0.0a') : v }
                fill={ theme.palette.type === 'dark' ? '#fff' : '#000' }
              />

              <LabelList
                dataKey={ props.xDataKey }
                position='bottom'
                fill={ theme.palette.type === 'dark' ? '#fff' : '#000' }
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        :
        <Box
          height='100%'
          width='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
          fill='url(#verticalGradient)'
        >
          <CircularSpinner />
        </Box>
      }
    </div>
  )
}

export default BarChartStyled
