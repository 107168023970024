const investmentStageList = [
  'Pre-Seed',
  'Seed',
  'Growth',
  'Acquired',
  'IPO',
  'Established',
  'Unspecified',
]

export default investmentStageList
