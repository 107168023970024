import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Typography, Tab, Tabs, Box, Button } from '@material-ui/core/'
import DeleteIcon from '@material-ui/icons/Delete'
import SaveIcon from '@material-ui/icons/Save'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import BackEndInformationForm from '../Forms/BackEndInformationForm'
import FrontEndInformationForm from '../Forms/FrontEndInformationForm'
import EvaluationInformationForm from '../Forms/EvaluationInformationForm'
import BasicOrganisationForm from '../Forms/BasicOrganisationForm'
import TeamStructureForm from '../Forms/TeamStructureForm'
import ContactDetailsForm from '../Forms/ContactDetailsForm'
import FundingForm from '../Forms/FundingForm'
import AlertDialog from '../../UI/Dialogs/AlertDialog'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '../../../hoc/Auth0/react-auth0-spa'
import { rules } from '../../Auth0/PriorityRules'

import { connect } from 'react-redux'
import * as companyActions from '../../../store/actions/company'
import BetaIcon from '../../../utils/Icons/BetaIcon'
import { diff } from 'deep-object-diff'

function a11yProps (index) {
  return {
    id: `Edit-Company-tab-${ index }`,
    'aria-controls': `Edit-Compan-tabpanel-${ index }`,
  }
}

function TabPanel (props) {
  const { children, value, index, ...other } = props
  return (
    <Typography component='div' role='tabpanel' hidden={ value !== index } id={ `simple-tabpanel-${ index }` } aria-labelledby={ `simple-tab-${ index }` } { ...other }>
      { children }
    </Typography>
  )
}

function CompanyForm ({ updateForm, importTable, company, deleteCompany, updateCompany, addCompany, collectionToUse, setAlert, currentTab, setCurrentTab }) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [tab, setTab] = useState(currentTab || 0)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
  const [openResetScoresDialog, setOpenResetScoresDialog] = useState(false)
  const [openReprocessDialog, setOpenReprocessDialog] = useState(false)

  const [hasName, setHasName] = useState(Boolean(company.name))
  const [hasFoundedDate, setHasFoundedDate] = useState(Boolean(company.founded_date))
  const [hasWebsite, setHasWebsite] = useState(Boolean(company.website))
  const [hasDescription, setHasDescription] = useState(Boolean(company.description))
  const [formData, setFormData] = useState(company)

  const initialCompany = useRef(formData)

  useEffect(() => {
    if (setAlert) {
      const emptyEditboxContent = '<p></p>'
      let hasChanged = JSON.stringify(initialCompany.current) !== JSON.stringify(formData)

      if (hasChanged) {
        const differences = diff(initialCompany.current, formData)

        if (differences.achievements === emptyEditboxContent) {
          delete differences.achievements
        }
        if (differences.business_model === emptyEditboxContent) {
          delete differences.business_model
        }
        if (differences.market_opportunities === emptyEditboxContent) {
          delete differences.market_opportunities
        }
        if (differences.product_portfolio === emptyEditboxContent) {
          delete differences.product_portfolio
        }
        if (differences.sustainability_model === emptyEditboxContent) {
          delete differences.sustainability_model
        }
        if (differences.value_proposition === emptyEditboxContent) {
          delete differences.value_proposition
        }
        if (Object.keys(differences).length === 1 && differences.priority) {
          delete differences.priority
          formData.priority = initialCompany.current.priority

        }
        if (Object.keys(differences).length === 0) {
          hasChanged = false
        }
      }

      setAlert(hasChanged)
    }
  }, [formData])

  const { user } = useAuth0()
  const userPriority = user['https://api.valuer.ml/'].role_priority
  const forbiddenActions = rules[userPriority].forbidden_actions
  const allowEdit = !rules[userPriority].forbidden_actions.includes('edit')

  function onInputChange (event) {
    const inputName = event.target.name

    if (inputName !== 'priority') {
      setFormData(prevState => ({
        ...prevState,
        priority: 3,
      }))
    }

    const inputValue = event.target.value
    let value = null

    if (inputValue && inputValue.type === 'number') {
      value = +inputValue
    } else {
      value = inputValue
    }

    setFormData(prevState => ({
      ...prevState,
      [inputName]: value,
    }))

    checkValidity(inputName, inputValue)
  }

  function checkValidity (name, value) {
    if (name === 'name') {
      const valid = value.trim() !== ''
      setHasName(valid)
    }

    if (name === 'founded_date') {
      const valid = value
      setHasFoundedDate(valid)
    }

    if (name === 'website') {
      const valid = value.trim() !== ''
      setHasWebsite(valid)
    }

    if (name === 'description') {
      const valid = value.trim() !== ''
      setHasDescription(valid)
    }
  }

  const onClickResetScoresSubmit = () => {
    if (hasName && hasFoundedDate && hasWebsite && hasDescription) setOpenResetScoresDialog(true)
    if (!hasName) enqueueSnackbar('Company Name is required', { variant: 'warning' })
    if (!hasFoundedDate) enqueueSnackbar('Founded Date is required', { variant: 'warning' })
    if (!hasWebsite) enqueueSnackbar('Website is required', { variant: 'warning' })
    if (!hasDescription) enqueueSnackbar('Description is required', { variant: 'warning' })
  }

  const onClickReprocessSubmit = () => {
    if (hasName && hasFoundedDate && hasWebsite && hasDescription) setOpenReprocessDialog(true)
    if (!hasName) enqueueSnackbar('Company Name is required', { variant: 'warning' })
    if (!hasFoundedDate) enqueueSnackbar('Founded Date is required', { variant: 'warning' })
    if (!hasWebsite) enqueueSnackbar('Website is required', { variant: 'warning' })
    if (!hasDescription) enqueueSnackbar('Description is required', { variant: 'warning' })
  }

  const onClickSubmit = () => {
    if (hasName && hasFoundedDate && hasWebsite && hasDescription) setOpenSubmitDialog(true)
    if (!hasName) enqueueSnackbar('Company Name is required', { variant: 'warning' })
    if (!hasFoundedDate) enqueueSnackbar('Founded Date is required', { variant: 'warning' })
    if (!hasWebsite) enqueueSnackbar('Website is required', { variant: 'warning' })
    if (!hasDescription) enqueueSnackbar('Description is required', { variant: 'warning' })
  }

  const handleTabOnClick = tabID => {
    setCurrentTab(tabID)
    setTab(tabID)
  }

  return (
    <Fragment>
      <Box display='flex' justifyContent='flex-start'>
        <Tabs orientation='horizontal' variant='scrollable' value={ tab } onChange={ (e, tab) => handleTabOnClick(tab) } aria-label='simple tabs example'>
          <Tab label='About' { ...a11yProps(0) } />
          <Tab label='Executive Team' { ...a11yProps(1) } />
          <Tab label='Contact Details' { ...a11yProps(2) } />
          <Tab label='Funding' { ...a11yProps(3) } />
          { updateForm && allowEdit && <Tab label={ <Box>Company profile <span style={ { position: 'relative', top: 6, right: -5 } }><BetaIcon viewBox='0 -6 178.411 60' /></span></Box> } { ...a11yProps(4) } /> }
          { updateForm && allowEdit && <Tab label='Back-End' { ...a11yProps(5) } /> }
          { updateForm && allowEdit && <Tab label='Evaluation' { ...a11yProps(6) } /> }
        </Tabs>
      </Box>

      <Box minHeight='550px' marginTop={ 4 }>
        <TabPanel value={ tab } index={ 0 }>
          { tab === 0 && <BasicOrganisationForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 1 }>
          { tab === 1 && <TeamStructureForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } history={ history } collectionToUse={ collectionToUse } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 2 }>
          { tab === 2 && <ContactDetailsForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 3 }>
          { tab === 3 && <FundingForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } history={ history } collectionToUse={ collectionToUse } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 4 }>
          { tab === 4 && <FrontEndInformationForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } history={ history } collectionToUse={ collectionToUse } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 5 }>
          { tab === 5 && <BackEndInformationForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } userPriority={ userPriority } /> }
        </TabPanel>

        <TabPanel value={ tab } index={ 6 }>
          { tab === 6 && <EvaluationInformationForm onInputChange={ onInputChange } allowEdit={ allowEdit } data={ formData } userPriority={ userPriority } /> }
        </TabPanel>
      </Box>

      { updateForm && !forbiddenActions.includes('edit') ? (
        <Box display='flex' justifyContent='flex-end' marginTop={ 4 } marginBottom={ 2 }>
          { tab === 6 && userPriority >= 3 &&
            <Box marginX={ 2 }>
              <Button variant='contained' color='secondary' size='medium' startIcon={ <RotateLeftIcon /> } onClick={ onClickResetScoresSubmit }>
                Reset scores
              </Button>

              <AlertDialog
                open={ openResetScoresDialog }
                setOpen={ setOpenResetScoresDialog }
                title='Are you sure you want to reset all the scores for this company?'
                description='All the scores will be reset and current scores will be lost'
                btnTitle='Reset scores'
                onSubmit={ () => {
                  updateCompany({
                    ...formData,
                    valuer_score: null,
                    completeness_score: null,
                    success_potential_score: null,
                    growth_score: null,
                    maturity_score: null,
                    innovation_score: null,
                    sustainability_score: null,
                    description_quality_score: null,
                    frontend_ready: null,
                  }, importTable, collectionToUse, enqueueSnackbar)
                  setOpenResetScoresDialog(false)
                } }
              />
            </Box> }

          { tab === 5 && userPriority >= 3 &&
            <Box marginX={ 2 }>
              <Button variant='contained' color='secondary' size='medium' startIcon={ <RotateLeftIcon /> } onClick={ onClickReprocessSubmit }>
                Reprocess
              </Button>

              <AlertDialog
                open={ openReprocessDialog }
                setOpen={ setOpenReprocessDialog }
                title='Are you sure you want to reprocess this company?'
                description='Description processed, description hashed, all the scores, projection and frontend ready will be reset and current values will be lost'
                btnTitle='Reset scores'
                onSubmit={ () => {
                  updateCompany({
                    ...formData,
                    description_processed: 'reset',
                    description_hashed: 'reset',
                    projection: 'reset',
                    logo_url: 'reset',
                  }, importTable, collectionToUse, enqueueSnackbar)
                  setOpenReprocessDialog(false)
                } }
              />
            </Box> }

          <Box marginX={ 2 }>
            <Button variant='contained' color='primary' size='medium' startIcon={ <DeleteIcon /> } onClick={ () => setOpenDeleteDialog(true) }>
              Delete company
            </Button>

            <AlertDialog
              open={ openDeleteDialog }
              setOpen={ setOpenDeleteDialog }
              title='Are you sure you want to delete this company?'
              description='Company will be deleted permanently'
              btnTitle='Delete'
              onSubmit={ () => {
                deleteCompany(company._id, importTable, collectionToUse, enqueueSnackbar)
                setOpenDeleteDialog(false)
              } }
            />
          </Box>

          <Box marginX={ 2 }>
            <Button variant='contained' color='secondary' size='medium' startIcon={ <SaveIcon /> } onClick={ onClickSubmit }>
              Save changes
            </Button>

            <AlertDialog
              open={ openSubmitDialog }
              setOpen={ setOpenSubmitDialog }
              title='Are you sure you want to update this company?'
              description='Company will be updated and old data will be lost'
              btnTitle='Update'
              onSubmit={ () => {
                updateCompany(formData, importTable, collectionToUse, enqueueSnackbar)
                setOpenSubmitDialog(false)
              } }
            />
          </Box>
        </Box>
      ) : (
        <Box display='flex' justifyContent='flex-end' marginTop={ 4 }>
          <Box marginX={ 2 }>
            <Button variant='text' color='primary' size='medium' onClick={ () => history.push('/dashboard') }>
              Cancel
            </Button>
          </Box>

          <Box marginX={ 2 }>
            <Button variant='contained' color='secondary' size='medium' startIcon={ <SaveIcon /> } onClick={ onClickSubmit }>
              Add New Company
            </Button>

            <AlertDialog
              open={ openSubmitDialog }
              setOpen={ setOpenSubmitDialog }
              title='Are you sure you want to Add this company?'
              description='Company will be added to the database'
              btnTitle='Add Company'
              onSubmit={ () => {
                addCompany(formData, history, enqueueSnackbar)
                setOpenSubmitDialog(false)
              } }
            />
          </Box>
        </Box>
      ) }
    </Fragment>
  )
}

const mapStateToProps = state => ({
  collectionToUse: state.collectionToUse.collectionToUse,
})

const mapDispatchToProps = {
  deleteCompany: companyActions.deleteCompany,
  updateCompany: companyActions.updateCompany,
  addCompany: companyActions.addCompany,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm)

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
