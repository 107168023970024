import {
  FETCH_ALIAS_CONFLICT_START,
  FETCH_ALIAS_CONFLICT_SUCCESS,
  FETCH_ALIAS_CONFLICT_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchAliasConflictStart = () => ({
  type: FETCH_ALIAS_CONFLICT_START,
})

const fetchAliasConflictSuccess = aliasConflict => ({
  type: FETCH_ALIAS_CONFLICT_SUCCESS,
  payload: aliasConflict,
})

const fetchAliasConflictFail = errorMessage => ({
  type: FETCH_ALIAS_CONFLICT_FAIL,
  payload: errorMessage,
})

export const fetchAliasConflict = company => {
  return async dispatch => {
    try {
      dispatch(fetchAliasConflictStart())

      const { data } = await axios.get('/api/alias-conflict', { params: { id: company._id, name: company.name, aliases: company.alias } })

      dispatch(fetchAliasConflictSuccess(data))
    } catch (error) {
      dispatch(fetchAliasConflictFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
