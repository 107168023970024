import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import companyReducer from './reducers/company'
import companyListReducer from './reducers/companyList'
import companiesByCountryReducer from './reducers/companiesByCountry'
import dashboardStatsReducer from './reducers/dashboardStats'
import scoresStatsReducer from './reducers/scoresStatsReducer'
import scoresStatsHistogramsReducer from './reducers/scoresStatsHistogramsReducer'
import historicScoresStatsReducer from './reducers/historicScoresStatsReducer'
import paginationReducer from './reducers/pagination'
import themeReducer from './reducers/theme'
import collectionNamesReducer from './reducers/collectionNamesReducer'
import collectionToUseReducer from './reducers/collectionToUseReducer'
import conflictCompaniesReducer from './reducers/conflictCompaniesReducer'
import logReducer from './reducers/logReducer'
import uniqueEndpointsReducer from './reducers/uniqueEndpointsReducer'
import eventNotificationReducer from './reducers/eventNotificationReducer'
import registerEventNotificationReducer from './reducers/registerEventNotificationReducer'
import addRecipientReducer from './reducers/addRecipientReducer'
import removeEventNotificationReducer from './reducers/removeRecipientReducer'
import removeRecipientReducer from './reducers/removeRecipientReducer'
import subscribeAllEventNotificationReducer from './reducers/subscribeAllEventNotificationReducer'
import unsubscribeAllEventNotificationReducer from './reducers/unsubscribeAllEventNotificationReducer'
import customerReducer from './reducers/customerReducer'
import founderReducer from './reducers/founderReducer'
import sourcesReducer from './reducers/sourcesReducer'
import aliasConflictReducer from './reducers/aliasConflictReducer'
import clusterDataReducer from './reducers/clusterDataReducer'
import monthlyEntriesReducer from './reducers/monthlyEntriesReducer'
import monthlyDataPointsReducer from './reducers/monthlyDataPointsReducer'
import companiesCreatedUpdatedReducer from './reducers/companiesCreatedUpdatedReducer'
import descriptionsCountsReducer from './reducers/descriptionsCountsReducer'
import monthlyFundingCountsReducer from './reducers/monthlyFundingCountsReducer'
// import logger from 'redux-logger'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  companyList: companyListReducer,
  company: companyReducer,
  dashboardStats: dashboardStatsReducer,
  scoresStatistics: scoresStatsReducer,
  scoresStatisticsHistograms: scoresStatsHistogramsReducer,
  historicScoresStatistics: historicScoresStatsReducer,
  pagination: paginationReducer,
  theme: themeReducer,
  collectionNames: collectionNamesReducer,
  collectionToUse: collectionToUseReducer,
  conflictCompanies: conflictCompaniesReducer,
  logData: logReducer,
  uniqueEndpoints: uniqueEndpointsReducer,
  eventNotifications: eventNotificationReducer,
  newEventNotification: registerEventNotificationReducer,
  removedEventNotification: removeEventNotificationReducer,
  newRecipient: addRecipientReducer,
  removedRecipient: removeRecipientReducer,
  subscribeAllEventNotifcation: subscribeAllEventNotificationReducer,
  unsubscribeAllEventNotification: unsubscribeAllEventNotificationReducer,
  customer: customerReducer,
  founder: founderReducer,
  companiesByCountry: companiesByCountryReducer,
  sources: sourcesReducer,
  aliasConflicts: aliasConflictReducer,
  clusterData: clusterDataReducer,
  monthlyEntries: monthlyEntriesReducer,
  monthlyDataPoints: monthlyDataPointsReducer,
  companiesCreatedUpdated: companiesCreatedUpdatedReducer,
  descriptionsCounts: descriptionsCountsReducer,
  monthlyFundingCounts: monthlyFundingCountsReducer,
})

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
