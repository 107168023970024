import {
  FETCH_LOGS_START,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAIL,
  FETCH_UNIQUE_ENDPOINTS_START,
  FETCH_UNIQUE_ENDPOINTS_SUCCESS,
  FETCH_UNIQUE_ENDPOINTS_FAIL,
} from './actionTypes'
import { setTotalCount } from './pagination'

import axios from '../../axios'

export const fetchUniqueEndpointsStart = () => ({
  type: FETCH_UNIQUE_ENDPOINTS_START,
})

export const fetchUniqueEndpointsSuccess = uniqueEndpoints => ({
  type: FETCH_UNIQUE_ENDPOINTS_SUCCESS,
  payload: uniqueEndpoints,
})

export const fetchUniqueEndpointsFail = errorMessage => ({
  type: FETCH_UNIQUE_ENDPOINTS_FAIL,
  payload: errorMessage,
})

export const fetchUniqueEndpoints = () => {
  return async dispatch => {
    try {
      dispatch(fetchUniqueEndpointsStart)
      const { data } = await axios.get('/api/logs/unique-endpoints')
      dispatch(fetchUniqueEndpointsSuccess(data))
    } catch (error) {
      dispatch(fetchUniqueEndpointsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}

export const fetchLogsStart = () => ({
  type: FETCH_LOGS_START,
})

export const fetchLogsSuccess = logData => ({
  type: FETCH_LOGS_SUCCESS,
  payload: logData,
})

export const fetchLogsFail = errorMessage => ({
  type: FETCH_LOGS_FAIL,
  payload: errorMessage,
})

export const fetchLogs = (searchedEndpoint, selectedStatus, selectedStatusCode, selectedStack, searchedLogMessage, searchedStackTrace, createdFrom, createdTo) => {
  return async (dispatch, getState) => {
    try {
      const { currentPage, pageSize, queryParams } = getState().pagination

      dispatch(fetchLogsStart())

      const { data } = await axios.get('/api/logs-pagination', {
        params: {
          ...queryParams,
          page: currentPage,
          limit: pageSize,
          searchedEndpoint,
          selectedStatus,
          selectedStatusCode,
          selectedStack,
          searchedLogMessage,
          searchedStackTrace,
          createdFrom,
          createdTo,
        },
      })

      dispatch(fetchLogsSuccess(data))
      dispatch(setTotalCount(data.totalFoundLogs))
    } catch (error) {
      dispatch(fetchLogsFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
