/* eslint-disable no-alert */
/* eslint-disable prefer-named-capture-group */
import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Grid, TextField, Divider, Modal, Fade, makeStyles, Backdrop, Typography } from '@material-ui/core'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import InfoIcon from '@material-ui/icons/Info'
import { useSnackbar } from 'notistack'
import { updateCompany } from '../../../store/actions/company'
import { useDispatch } from 'react-redux'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import htmlToFormattedText from 'html-to-formatted-text'
import BetaIcon from '../../../utils/Icons/BetaIcon'

const useStyles = makeStyles(theme => ({
  paper2: {
    maxHeight: 800,
    width: 800,
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const FoundersForm = ({ data, onInputChange, history, collectionToUse }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const academicBackgroundRef = useRef(null)
  const experienceRef = useRef(null)

  const htmlToDraftBlocks = html => {
    const blocksFromHtml = htmlToDraft(html)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    const editorState = EditorState.createWithContent(contentState)

    return editorState
  }

  const [academicBackgroundState, setAcademicBackgroundEditorState] = useState(EditorState.createEmpty())
  const [experienceState, setExperienceEditorState] = useState(EditorState.createEmpty())
  const [open, setOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const theme = useSelector(state => state.theme)
  const { isDarkMode } = theme

  const [buttonText, setButtonText] = useState('Add member')

  const handleClose = () => {
    delete data._founder_name
    delete data._founder_title
    setAcademicBackgroundEditorState(EditorState.createEmpty())
    setExperienceEditorState(EditorState.createEmpty())

    setOpen(false)
  }

  const handleCloseModal = () => {
    delete data._founder_name
    delete data._founder_title
    setAcademicBackgroundEditorState(EditorState.createEmpty())
    setExperienceEditorState(EditorState.createEmpty())

    setOpen(false)
  }

  const handleAddTeamMember = () => {
    const confirmation = confirm(`Are you sure you want to ${ buttonText.split(' ')[0].toLowerCase() } this member?`)

    if (confirmation) {
      if (!data._founder_experience) {
        data._founder_experience = '<p></p>'
      }

      if (!data._founder_academic_background) {
        data._founder_academic_background = '<p></p>'
      }
      dispatch(updateCompany(data, undefined, collectionToUse, enqueueSnackbar))
    }
  }

  useEffect(() => {
    academicBackgroundRef.current && academicBackgroundRef.current.dispatchEvent(new Event('change', { bubbles: true }))
    experienceRef.current && experienceRef.current.dispatchEvent(new Event('change', { bubbles: true }))
  }, [draftToHtml(convertToRaw(academicBackgroundState.getCurrentContent())), draftToHtml(convertToRaw(experienceState.getCurrentContent()))])

  return (
    <div>
      { history.location.pathname !== '/database/add-company' &&
        <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
          <Box position='relative' top={ -35 } left={ 135 }>
            <BetaIcon viewBox='0 -8 178.411 60' />
          </Box>

          <Button
            variant='contained'
            color='secondary'
            size='medium'
            endIcon={ <PlusOneIcon /> }
            onClick={ () => {
              setButtonText('Add member')
              setDisabled(false)
              setOpen(true)
            } }
          >
            Add team member
          </Button>
        </Box> }

      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={ classes.modal }
        open={ open }
        onClose={ handleClose }
        closeAfterTransition
        BackdropComponent={ Backdrop }
        BackdropProps={ {
          timeout: 100,
        } }
        style={ { color: isDarkMode && isDarkMode ? 'white' : 'black' } }
      >
        <Fade in={ open }>
          <div className={ classes.paper2 }>
            <p id='transition-modal-description' style={ { fontSize: '1.1rem', minHeight: 485 } }>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 4 }>
                  <TextField
                    disabled={ disabled }
                    onChange={ onInputChange }
                    label='Name and Surname'
                    name={ '_founder_name' }
                    defaultValue={ data._founder_name && data._founder_name }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 4 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Title'
                    name={ '_founder_title' }
                    defaultValue={ data._founder_title && data._founder_title }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Box mb={ 4 }>
                  <input
                    name='_founder_experience'
                    value={ draftToHtml(convertToRaw(experienceState.getCurrentContent())) }
                    onChange={ e => onInputChange(e) }
                    ref={ experienceRef }
                    hidden
                  />

                  <Typography variant='h6'>Experience</Typography>

                  <Box
                    color='#5c6bc0'
                    marginBottom={ 1 }
                  >
                    <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                    <Typography
                      style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                    >
                      { convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                        convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                        convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
                      } words long
                    </Typography>
                  </Box>

                  <Editor
                    editorState={ experienceState }
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={ setExperienceEditorState }
                    editorStyle={ { borderBottom: '1px solid' } }
                    toolbar={ {
                      options: ['inline', 'blockType', 'list', 'link', 'history'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        className: 'blockType',
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                    } }
                  />
                </Box>

                <Box mb={ 4 }>
                  <input
                    name='_founder_academic_background'
                    value={ draftToHtml(convertToRaw(academicBackgroundState.getCurrentContent())) }
                    onChange={ e => onInputChange(e) }
                    ref={ academicBackgroundRef }
                    hidden
                  />

                  <Typography variant='h6'>Academic Background</Typography>

                  <Box
                    color='#5c6bc0'
                    marginBottom={ 1 }
                  >
                    <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                    <Typography
                      style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                    >
                      { convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                        convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                        convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
                      } words long
                    </Typography>
                  </Box>

                  <Editor
                    editorState={ academicBackgroundState }
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={ setAcademicBackgroundEditorState }
                    editorStyle={ { borderBottom: '1px solid' } }
                    toolbar={ {
                      options: ['inline', 'blockType', 'list', 'link', 'history'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        className: 'blockType',
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                    } }
                  />
                </Box>
              </Grid>
            </p>

            <Box style={ { display: 'flex', justifyContent: 'center' } }>
              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleCloseModal }
                style={ { marginRight: 30 } }
              >
                Close
              </Button>

              <Button
                variant='contained'
                color='secondary'
                size='medium'
                onClick={ handleAddTeamMember }
              >
                { buttonText }
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      { data.foundersFull ? Array(data.foundersFull.length).fill(data.foundersFull).map((founder, index) =>
        <div key={ index }>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 4 }>
              <TextField
                onChange={ onInputChange }
                label='Name and Surname'
                name={ `${ index + 1 }_founder_name` }
                defaultValue={ data.foundersFull && index < data.foundersFull.length ? founder[index].name : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 } md={ 4 }>
              <TextField
                onChange={ onInputChange }
                label='Title'
                name={ `${ index + 1 }_founder_title` }
                defaultValue={ data.foundersFull && index < data.foundersFull.length ? founder[index].title : null }
                fullWidth
                margin='dense'
                inputProps={ {
                  readOnly: true,
                } }
              />
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='h6'>Experience</Typography>

              <Box
                color='#5c6bc0'
                marginBottom={ 1 }
              >
                <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                <Typography
                  style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                >
                  {
                    founder[index].experience && htmlToFormattedText(founder[index].experience).replace(/(\r\n|\n|\r)/gm, ' ').split(' ').length
                  } words long
                </Typography>
              </Box>

              <Editor
                editorState={ founder[index].experience ? htmlToDraftBlocks(founder[index].experience) : '' }
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                editorStyle={ { borderBottom: '1px solid' } }
                toolbarHidden
                readOnly
              />
            </Grid>

            <Grid item xs={ 12 }>
              <Typography variant='h6'>Academic Background</Typography>

              <Box
                color='#5c6bc0'
                marginBottom={ 1 }
              >
                <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                <Typography
                  style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                >
                  {
                    founder[index].academic_background && htmlToFormattedText(founder[index].academic_background).replace(/(\r\n|\n|\r)/gm, ' ').split(' ').length
                  } words long
                </Typography>
              </Box>

              <Editor
                editorState={ founder[index].academic_background ? htmlToDraftBlocks(founder[index].academic_background) : '' }
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                editorStyle={ { borderBottom: '1px solid' } }
                toolbarHidden
                readOnly
              />
            </Grid>
          </Grid>

          <div style={ { display: 'flex', flexDirection: 'row-reverse' } }>
            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                onClick={ () => {
                  setButtonText('Edit member')
                  setDisabled(true)

                  data._founder_name = data.foundersFull[index].name
                  data._founder_title = data.foundersFull[index].title
                  data._founder_experience = data.foundersFull[index].experience
                  data._founder_academic_background = data.foundersFull[index].academic_background
                  setExperienceEditorState(htmlToDraftBlocks(data.foundersFull[index].experience || ''))
                  setAcademicBackgroundEditorState(htmlToDraftBlocks(data.foundersFull[index].academic_background || ''))

                  setOpen(true)
                } }
              >
                Edit member
              </Button>
            </Box>

            <Box mt={ 4 } mb={ 3 } display='flex' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                style={ { marginRight: 30 } }
                onClick={ () => {
                  const confirmation = confirm('Are you sure you want to ete this team member?')

                  if (confirmation) {
                    dispatch(updateCompany({ ...data, founders: data.founders.filter(founder => founder !== data.founders[index]) }, undefined, collectionToUse, enqueueSnackbar))
                  } else {
                    enqueueSnackbar('Member not deleted', { variant: 'info' })
                  }
                } }
              >
                Delete member
              </Button>
            </Box>
          </div>

          { data.foundersFull.length > 1 && <Divider style={ { marginTop: 30, marginBottom: 30, height: 3 } } /> }
        </div>,
      ) : history.location.pathname === '/database/add-company' ?
        <div>
          { Array(1).fill(1).map((founder, index) =>
            <div key={ index }>
              <Grid container spacing={ 3 }>
                <Grid item xs={ 12 } md={ 4 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Name and Surname'
                    name={ `${ index + 1 }_founder_name` }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 } md={ 4 }>
                  <TextField
                    onChange={ onInputChange }
                    label='Title'
                    name={ `${ index + 1 }_founder_title` }
                    fullWidth
                    margin='dense'
                    inputProps={ {
                      autoComplete: 'new-password',
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 }>
                  <input
                    name='1_founder_experience'
                    value={ draftToHtml(convertToRaw(experienceState.getCurrentContent())) }
                    onChange={ e => onInputChange(e) }
                    ref={ experienceRef }
                    hidden
                  />

                  <Typography variant='h6'>Experience</Typography>

                  <Box
                    color='#5c6bc0'
                    marginBottom={ 1 }
                  >
                    <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                    <Typography
                      style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                    >
                      {
                        convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                          convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                          convertToRaw(experienceState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
                      } words long
                    </Typography>
                  </Box>

                  <Editor
                    editorState={ experienceState }
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={ setExperienceEditorState }
                    editorStyle={ { borderBottom: '1px solid' } }
                    toolbar={ {
                      options: ['inline', 'blockType', 'list', 'link', 'history'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        className: 'blockType',
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                    } }
                  />
                </Grid>

                <Grid item xs={ 12 }>
                  <input
                    name='1_founder_academic_background'
                    value={ draftToHtml(convertToRaw(academicBackgroundState.getCurrentContent())) }
                    onChange={ e => onInputChange(e) }
                    ref={ academicBackgroundRef }
                    hidden
                  />

                  <Typography variant='h6'>Academic Background</Typography>

                  <Box
                    color='#5c6bc0'
                    marginBottom={ 1 }
                  >
                    <InfoIcon style={ { display: 'inline', fontSize: 15, marginRight: 3 } } />

                    <Typography
                      style={ { display: 'inline', verticalAlign: 'top', fontSize: 13, fontStyle: 'italic' } }
                    >
                      {
                        convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ')[0])[0] === '' ?
                          convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0) - 1 :
                          convertToRaw(academicBackgroundState.getCurrentContent()).blocks.map(block => block.text.trim().split(' ').length).reduce((a, b) => a + b, 0)
                      } words long
                    </Typography>
                  </Box>

                  <Editor
                    editorState={ academicBackgroundState }
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName='editorClassName'
                    onEditorStateChange={ setAcademicBackgroundEditorState }
                    editorStyle={ { borderBottom: '1px solid' } }
                    toolbar={ {
                      options: ['inline', 'blockType', 'list', 'link', 'history'],
                      inline: {
                        options: ['bold', 'italic', 'underline'],
                      },
                      blockType: {
                        inDropdown: false,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        className: 'blockType',
                      },
                      list: {
                        options: ['unordered', 'ordered'],
                      },
                    } }
                  />
                </Grid>
              </Grid>
            </div>,
          ) }
        </div> : <div>No team member at the moment</div>
      }
    </div >
  )
}

export default FoundersForm
