/* eslint-disable no-console */
import * as actionTypes from './actionTypes'
import axios from '../../axios'
import * as companyList from './companyList'
import { fetchConflictCompanies } from './conflictCompaniesActions'
import { fetchAliasConflict } from './aliasConflict'

export const setOpenCompanyModal = openModal => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_OPEN_COMPANY_MODAL,
      openModal: openModal,
    })
  }
}

export const setOpenConflictModal = openConflictModal => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_OPEN_CONFLICT_MODAL,
      openConflictModal: openConflictModal,
    })
  }
}

const fetchCompanyFail = error => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_FAIL,
      error: error,
    })
  }
}

const fetchCompanyStart = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_START,
    })
  }
}

const fetchCompanySuccess = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCH_COMPANY_SUCCESS,
      data: data,
    })
  }
}

export const fetchCompany = (id, importTable, collectionToUse) => {
  return dispatch => {
    dispatch(fetchCompanyStart())
    const companyUrl = '/api/company/'
    const tmpCompanyUrl = '/api/tmpcompany/'
    const baseUrl = importTable ? tmpCompanyUrl : companyUrl
    axios
      .get(`${ baseUrl }${ id }`, {
        params: {
          collectionToUse,
        },
      })
      .then(response => {
        dispatch(fetchCompanySuccess(response.data))

        // if (openModal) dispatch(setOpenCompanyModal(true))
      })
      .catch(err => {
        dispatch(fetchCompanyFail(err.message))
      })
  }
}

export const deleteCompany = (id, importTable, collectionToUse, enqueueSnackbar) => {
  return dispatch => {
    dispatch(fetchCompanyStart())
    const companyUrl = '/api/company/'
    const tmpCompanyUrl = '/api/tmpcompany/'
    const baseUrl = importTable ? tmpCompanyUrl : companyUrl
    axios
      .delete(`${ baseUrl }${ id }`, {
        params: {
          collectionToUse,
        },
      })
      .then(response => {
        dispatch(setOpenCompanyModal(false))
        dispatch(companyList.deleteCompanyListSuccess(id))
        enqueueSnackbar(response.data.message ? response.data.message : 'Company successfully deleted', { variant: 'success' })
      })
      .catch(err => {
        dispatch(setOpenCompanyModal(false))
        enqueueSnackbar(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message, { variant: 'error' })
      })
  }
}

export const updateCompany = (company, importTable, collectionToUse, enqueueSnackbar, updateConflictCompany = null, fetchConflicts = false, noOfConflictingFields = null, updateName = null, allowFetchCompany = true) => {
  return dispatch => {
    !fetchConflicts && allowFetchCompany && dispatch(fetchCompanyStart())

    const companyUrl = '/api/company/'
    const tmpCompanyUrl = '/api/tmpcompany/'
    const baseUrl = importTable ? tmpCompanyUrl : companyUrl
    axios
      .put(`${ baseUrl }${ company._id }`, company, {
        params: {
          collectionToUse,
          updateConflictCompany,
          deleteConflictCompany: noOfConflictingFields === 0 ? 'yes' : 'no',
          updateName,
        },
      })
      .then(response => {
        if (!updateName) {
          allowFetchCompany && dispatch(fetchCompany(company._id, undefined, collectionToUse, true))
          !fetchConflicts && dispatch(companyList.updateCompanyListSuccess(company))
          if (fetchConflicts) noOfConflictingFields ? dispatch(fetchConflictCompanies(company.name)) : dispatch(fetchConflictCompanies())
        } else {
          dispatch(setOpenConflictModal(false))
          dispatch(companyList.fetchLastUpdatedCompanies())
        }

        enqueueSnackbar(response.data.message && response.data.message.startsWith('Nothing changed') ? response.data.message : response.data.message && response.data.message.startsWith('Database locked') ? response.data.message : 'Company successfully updated', { variant: 'success' })
      })
      .catch(err => {
        allowFetchCompany && dispatch(fetchCompany(company._id, undefined, collectionToUse, true))
        !fetchConflicts && err.response && err.response.data && err.response.data.message && err.response.data.message.split('. ')[1] === 'No update was made.' && dispatch(fetchAliasConflict(company))
        !fetchConflicts && err.response && err.response.data && err.response.data.message && err.response.data.message.split('. ')[1] === 'No update was made.' && dispatch(setOpenConflictModal(true))
        enqueueSnackbar(err.response && err.response.data && err.response.data.message ? err.response.data.message : err.message, { variant: 'error' })
      })
  }
}

export const addCompany = (company, history, enqueueSnackbar) => {
  return dispatch => {
    dispatch(fetchCompanyStart())

    axios
      .post('/api/company', company)
      .then(res => {
        history.push('/dashboard')
        enqueueSnackbar(res.data.message && res.data.message.startsWith('Database locked') ? res.data.message : 'Company added successfully', { variant: 'success' })
      })
      .catch(err => {
        if (err.response && err.response.data.message && err.response.data.message.split(':')[0] === 'Company already exists') {
          history.push('/database/search')
          dispatch(fetchCompany(err.response.data.message.split(':')[1], null, 'companies'))
          dispatch(setOpenCompanyModal(true))
        }

        enqueueSnackbar(err.response && err.response.data.message ?
          err.response.data.message.split(':')[0] === 'Company already exists' ? 'Company already exists' : err.response.data.message : err.message, { variant: 'error' },
        )
      })
  }
}
