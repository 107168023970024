import React, { Fragment, useRef } from 'react'
import BreadCrumb from '../components/Navigation/BreadCrumbs/BreadCrumbs'
import CompanyForm from '../components/Startup/Forms/CompanyForm'

const AddCompany = () => {
  const currentTabIndex = useRef(0)

  const handleSetCurrentTabIndex = tabID => { currentTabIndex.current = tabID }

  return (
    <Fragment>
      <BreadCrumb />

      <CompanyForm addForm company={ {} } setCurrentTab={ data => handleSetCurrentTabIndex(data) } />
    </Fragment>
  )
}

export default AddCompany
