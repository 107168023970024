import React from 'react'
import { Typography } from '@material-ui/core'
import { FaRegSadTear } from 'react-icons/fa'
import { Box } from '@material-ui/core'

const NoDataMessage = () =>
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    height='22.5rem'
  >
    <Box>
      <FaRegSadTear fontSize='150px' />
    </Box>

    <Typography variant='h5'>No data found</Typography>
  </Box>

export default NoDataMessage
