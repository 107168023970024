import {
  FETCH_MONTHLY_ENTRIES_START,
  FETCH_MONTHLY_ENTRIES_SUCCESS,
  FETCH_MONTHLY_ENTRIES_FAIL,
} from './actionTypes'

import axios from '../../axios'

const fetchMonthlyEntriesStart = () => ({
  type: FETCH_MONTHLY_ENTRIES_START,
})

const fetchMonthlyEntriesSuccess = monthlyEntries => ({
  type: FETCH_MONTHLY_ENTRIES_SUCCESS,
  payload: monthlyEntries,
})

const fetchMonthlyEntriesFail = errorMessage => ({
  type: FETCH_MONTHLY_ENTRIES_FAIL,
  payload: errorMessage,
})

export const fetchMonthlyEntries = () => {
  return async dispatch => {
    try {
      dispatch(fetchMonthlyEntriesStart())

      const { data } = await axios.get('/api/statistics/monthly-entries')

      dispatch(fetchMonthlyEntriesSuccess(data))
    } catch (error) {
      dispatch(fetchMonthlyEntriesFail(error.response && error.response.data.message ? error.response.data.message : error.message))
    }
  }
}
