import * as actionTypes from './actionTypes'

export const setSelectedFilter = filter => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SELECTED_FILTER,
      selectedFilter: filter,
    })
  }
}

export const setFilters = filters => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FILTERS,
      filters: filters,
    })
  }
}

export const setPage = page => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PAGINATION_PAGE,
      page: page,
    })
  }
}

export const setPageSize = pageSize => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PAGINATION_PAGE_SIZE,
      pageSize: pageSize,
    })
  }
}

export const setQueryParams = queryParams => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PAGINATION_QUERY_PARAMS,
      queryParams: queryParams,
    })
  }
}

export const setTotalCount = totalCount => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PAGINATION_TOTAL_COUNT,
      totalCount: totalCount,
    })
  }
}

export const resetPagination = () => {
  return dispatch => {
    dispatch({
      type: actionTypes.RESET_PAGINATION,
    })
  }
}
