import {
  ADD_RECIPIENT_TO_NOTIFICATION_START,
  ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS,
  ADD_RECIPIENT_TO_NOTIFICATION_FAIL,
} from '../actions/actionTypes.js'

const INITIAL_STATE = {
  addEventNotification: [],
  addEventNotificationFetching: true,
  addEeventNoticationErrorMessage: '',
}

const addRecipientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_RECIPIENT_TO_NOTIFICATION_START:
      return {
        ...state,
        addEventNotificationFetching: true,
        addEeventNoticationErrorMessage: '',
      }
    case ADD_RECIPIENT_TO_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addEventNotification: action.payload,
        addEventNotificationFetching: false,
        addEeventNoticationErrorMessage: '',
      }
    case ADD_RECIPIENT_TO_NOTIFICATION_FAIL:
      return {
        ...state,
        addEventNotificationFetching: false,
        addEeventNoticationErrorMessage: action.payload,
      }
    default:
      return state
  }
}

export default addRecipientReducer
