import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../utility'

const initialState = {
  stats: {
    startups: null,
    dataPoints: null,
    newStartups: null,
    updatedStartups: null,
  },
  error: null,
  loading: false,
}

// eslint-disable-next-line no-unused-vars
const fetchDashboardStatsStart = (state, action) => {
  return updateObject(state, { loading: true, error: null })
}

const fetchDashboardStatsSuccess = (state, action) => {
  return updateObject(state, { stats: action.stats })
}

const fetchDashboardStatsFail = (state, action) => {
  return updateObject(state, { error: action.error })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD_STATS_START:
      return fetchDashboardStatsStart(state, action)
    case actionTypes.FETCH_DASHBOARD_STATS_SUCCESS:
      return fetchDashboardStatsSuccess(state, action)
    case actionTypes.FETCH_DASHBOARD_STATS_FAIL:
      return fetchDashboardStatsFail(state, action)
    default:
      return state
  }
}

export default reducer
