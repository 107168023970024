import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Topbar from '../../components/Navigation/Navbar/Topbar'
import Sidebar from '../../components/Navigation/Navbar/Sidebar'
import Container from '@material-ui/core/Container'
import { Paper } from '@material-ui/core'

import { SnackbarProvider } from 'notistack'

export default function Layout (props) {
  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      minHeight: '100vh',
      borderRadius: 0,
    },
    content: {
      flexGrow: 1,
      overflow: 'auto',
      backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#3e3e3e',
    },
    toolbar: theme.mixins.toolbar,
  }))

  const classes = useStyles()

  // Mobile Menu State
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <Paper className={classes.root}>
        <Topbar
          toggleDarkMode={props.toggleDarkMode}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container maxWidth='lg'>{props.children}</Container>
        </main>
      </Paper>
    </SnackbarProvider>
  )
}

export const drawerWidth = 240
